import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/MB_Logo.png";
import './caixamov.css';
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import dateFormat from "dateformat";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
import date from 'date-and-time';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { DateTime } from 'luxon'
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}


global.gLog = [];
global.gOpencaixa = 1;
global.gSubTotal = 0;
global.gAlertaCaixaMov = 0;

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};


function Gerador() {

  const formatData = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(0, 4)
    let result = dia + '/' + mes + '/' + ano

    return result; //return the contents of the cell;  
  }

  const adultCalc = function (values, data, calcParams) {
    //values - array of column values
    //data - all table data
    //calcParams - params passed from the column definition object


    var calc = 0;

    values.forEach(function (value) {
      calc = calc + realParaNumber(value)
    });

    return calc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [repCaixaOpen, setrepCaixaOpen] = useState([]);
  const [FinalizaPag, setFinalizaPag] = useState([]);
  const [AtuLanc, setAtuLanc] = useState([]);
  const [Lanc, setLanc] = useState([]);
  const [LancSelected, setLancSelected] = useState([]);
  const [openCaixa, setopenCaixa] = useState([]);
  const [AtuLancamentos, setAtuLancamentos] = useState('');
  const [Lancamentos, setLancamentos] = useState('');
  const [AtuRelComiss, setAtuRelComiss] = useState('');
  const [RelComiss, setRelComiss] = useState('');
  const [AtuRelComissResumo, setAtuRelComissResumo] = useState('');
  const [RelComissResumo, setRelComissResumo] = useState('');
  const [EditLancamentos, setEditLancamentos] = useState('');
  const [AddLancManual, setAddLancManual] = useState('');
  const [FechaCaixa, setFechaCaixa] = useState('');
  const [ProfisPre, setProfisPre] = useState([]);
  const [Profis, setProfis] = useState([]);

  const [DocNow, setDocNow] = useState([]);
  const isFirstRunAtuLanc = useRef(true);
  const isFirstRunOpenCaixa = useRef(true);
  const isFirstRunAtuLancamentos = useRef(true);
  const isFirstRunAtuRelAtuComiss = useRef(true);
  const isFirstRunAtuRelAtuComissResumo = useRef(true);
  const isFirstRunEditLancamentos = useRef(true);
  const isFirstRunFinalizaPag = useRef(true);
  const isFirstRunAddLancManual = useRef(true);
  const isFirstRunFechaCaixa = useRef(true);

  moment.updateLocale('pt-br', {
    week: {
      dow: 1,
      //doy: 1,
    },
  });

  useEffect(() => {

    let ignoreProf = false;
    async function fetchprofis() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        //url: global.gLinkBase + '/appyou/rest.php?apikey=398738379696650578637067567569346',
        url: 'https://api.espacomarciobagagine.com.br/listaProfisSel.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })


      if (!ignoreProf) {
        const redata = response['data'];
        //cl('redata', redata);

        setProfis(redata);
        //setLocalizer(ll);
        ignoreProf = true;

        //setEventosPRE(redata);

        //let eleLoading = document.getElementById('divLoad');
        //eleLoading.style.display = 'none';
      }
    }
    fetchprofis();

    /*     const resourceMap = [
          { resourceId: 1, resourceTitle: 'Marcio' },
          { resourceId: 2, resourceTitle: 'Jorge Estrela' },
          { resourceId: 3, resourceTitle: 'Kaka' },
          { resourceId: 4, resourceTitle: 'Manicure1' },
          { resourceId: 5, resourceTitle: 'Manicure2' },
          { resourceId: 6, resourceTitle: 'Manicure3' },
          { resourceId: 7, resourceTitle: 'Manicure4' },
          { resourceId: 8, resourceTitle: 'Manicure5' },
          { resourceId: 9, resourceTitle: 'Manicure6' },
          { resourceId: 10, resourceTitle: 'Manicure7' },
          { resourceId: 11, resourceTitle: 'Manicure8' },
          { resourceId: 12, resourceTitle: 'Manicure9' },
          { resourceId: 13, resourceTitle: 'Manicure10' },
          { resourceId: 12, resourceTitle: 'Manicure11' },
        ] */

  }, [ProfisPre])

  useEffect(() => {


    //cl("Profis",Profis)


  }, [Profis])


  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [Lanc])

  useEffect(() => {

    /*     cl("moment0",moment().day(0).format('YYYY-MM-DD'));
        cl("moment1",moment().day(1).format('YYYY-MM-DD'));
        cl("moment2",moment().day(2).format('YYYY-MM-DD'));
        cl("moment3",moment().day(3).format('YYYY-MM-DD'));
        cl("moment4",moment().day(4).format('YYYY-MM-DD'));
        cl("moment5",moment().day(5).format('YYYY-MM-DD'));
        cl("moment6",moment().day(6).format('YYYY-MM-DD'));
        cl("moment7",moment().day(7).format('YYYY-MM-DD')); */


  }, [RelComiss])

  useEffect(() => {



  }, [RelComissResumo])

  useEffect(() => {
    if (isFirstRunAddLancManual.current) {
      isFirstRunAddLancManual.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;
    cl("AddLancManual", AddLancManual)

    const { tipo, desc, valor, idprof, Fpg, dtvenc } = AddLancManual
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('cliente', idprof);
      bodyFormData.set('caixa', global.gCaixaId);
      bodyFormData.set('tipo', tipo);
      bodyFormData.set('idprof', idprof);
      bodyFormData.set('desc', desc);
      bodyFormData.set('valor', valor);
      bodyFormData.set('dtvenc', dtvenc);
      bodyFormData.set(Fpg, valor);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.espacomarciobagagine.com.br/AddLancCxManual.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      //setAtuLancamentos({ idagenda: global.gParams })

      setAtuLancamentos({ '0': '0' });
      setAtuRelComiss({ '0': '0' });
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setLancamentos(redata2);
          //CloseEditContr();
        } else {
          //setLancamentos(redata);
        }


      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddLancManual])

  useEffect(() => {
    if (isFirstRunFechaCaixa.current) {
      isFirstRunFechaCaixa.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;
    cl("FechaCaixa", FechaCaixa)

    const { sangria, vfinal } = FechaCaixa
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('caixa', global.gCaixaId);
      bodyFormData.set('tipo', 'F');
      bodyFormData.set('desc', 'Fechamento de Caixa');
      bodyFormData.set('valor', sangria);
      bodyFormData.set('valorfinal', vfinal);
      bodyFormData.set('cash', sangria);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.espacomarciobagagine.com.br/FechaCaixa.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      //setAtuLancamentos({ idagenda: global.gParams })
      setAtuLancamentos({ '0': '0' });
      setAtuRelComiss({ '0': '0' });
      window.open("/caixa", "_self");
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setLancamentos(redata2);
          //CloseEditContr();
        } else {
          //setLancamentos(redata);
        }


      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [FechaCaixa])



  useEffect(() => {

    //cl("LancSelected", LancSelected)
    if (LancSelected) {

      global.gLancSelected = LancSelected.toString();
    } else {

      global.gLancSelected = 'nenhum';
    }


  }, [LancSelected])

  useEffect(() => {



  }, [DocNow])

  useEffect(() => {
    if (isFirstRunAtuLancamentos.current) {
      isFirstRunAtuLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    //const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('caixa', 4);
      bodyFormData.set('caixa', global.gCaixaId);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaCaixaMov.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setLancamentos(redata2);
        } else {
          setLancamentos(redata);

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata()
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuLancamentos])

  useEffect(() => {
    if (isFirstRunAtuRelAtuComiss.current) {
      isFirstRunAtuRelAtuComiss.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    //const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('dtinit', moment().day(1).format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('dtfim', moment().day(7).format('YYYY-MM-DD 23:59:59'));
      bodyFormData.set('marcio', 0);
      bodyFormData.set('pdeb', 1.79);
      bodyFormData.set('pcred', 2.55);
      bodyFormData.set('pcredp', 4.9);
      bodyFormData.set('antecip', 3.6);
      bodyFormData.set('caixa', global.gCaixaId);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaRelComiss.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setRelComiss(redata2);
        } else {
          setRelComiss(redata);

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('imgloadingRelComiss');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata()
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuRelComiss])

  useEffect(() => {
    if (isFirstRunAtuRelAtuComissResumo.current) {
      isFirstRunAtuRelAtuComissResumo.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    //const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('dtinit', moment().day(1).format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('dtfim', moment().day(7).format('YYYY-MM-DD 23:59:59'));
      bodyFormData.set('marcio', 0);      
      bodyFormData.set('pdeb', 1.79);
      bodyFormData.set('pcred', 2.55);
      bodyFormData.set('pcredp', 4.9);
      bodyFormData.set('antecip', 3.6);
      bodyFormData.set('caixa', global.gCaixaId);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaRelComissResumo.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setRelComissResumo(redata2);
          //cl("redata2", redata2)
        } else {
          setRelComissResumo(redata);
         // cl("redata", redata)


        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('imgloadingRelComiss');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata()
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuRelComissResumo])

  useEffect(() => {
    if (isFirstRunFinalizaPag.current) {
      isFirstRunFinalizaPag.current = false;
      //cl("FirstRunDados false")
      return;
    }

  }, [FinalizaPag])


  useEffect(() => {
    if (isFirstRunEditLancamentos.current) {
      isFirstRunEditLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;


    return () => { ignore = true };
  }, [EditLancamentos])

  useEffect(() => {

    global.gSubTotal = 0;
    global.gCash = 0;
    global.gDebito = 0;
    global.gCCv = 0;
    global.gCCp = 0;
    global.gPix = 0;
    global.gCC = 0;

    //cl("Lancamentos", Lancamentos);
    if (Lancamentos.length > 0) {


      var i;

      for (i = 0; i < Lancamentos.length; i++) {
        if (Lancamentos[i].tipo === 'E') {

          if (Lancamentos[i].valor) { global.gSubTotal = global.gSubTotal + (realParaNumber(Lancamentos[i].valor)); }
          if (Lancamentos[i].cash) { global.gCash = global.gCash + ((realParaNumber(Lancamentos[i].cash)-realParaNumber(Lancamentos[i].troco))); }
          if (Lancamentos[i].debito) { global.gDebito = global.gDebito + (realParaNumber(Lancamentos[i].debito)); }
          if (Lancamentos[i].creditoavista) { global.gCCv = global.gCCv + (realParaNumber(Lancamentos[i].creditoavista)); }
          if (Lancamentos[i].creditoparc) { global.gCCp = global.gCCp + (realParaNumber(Lancamentos[i].creditoparc)); }
          if (Lancamentos[i].pix) { global.gPix = global.gPix + (realParaNumber(Lancamentos[i].pix)); }
          if (Lancamentos[i].cc) { global.gCC = global.gCC + (realParaNumber(Lancamentos[i].cc)); }

        }

        if (Lancamentos[i].tipo === 'S') {

          if (Lancamentos[i].cash) { global.gCash = global.gCash - (realParaNumber(Lancamentos[i].cash)); }

        }

        if (Lancamentos[i].tipo === 'V') {

          if (Lancamentos[i].cash) { global.gCash = global.gCash - (realParaNumber(Lancamentos[i].cash)); }

        }

        if (Lancamentos[i].tipo === 'F') {

          if (Lancamentos[i].cash) { global.gCash = global.gCash - (realParaNumber(Lancamentos[i].cash)); }

        }

        if (Lancamentos[i].tipo === 'A') {

          if (Lancamentos[i].cash) { global.gCash = global.gCash + (realParaNumber(Lancamentos[i].cash)); }

        }

      }
      let einputTotal = document.getElementById('inputTotal');
      if (einputTotal) { einputTotal.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
      let einputCash = document.getElementById('inputCash');
      if (einputCash) { einputCash.value = global.gCash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
      let einputDebito = document.getElementById('inputDebito');
      if (einputDebito) { einputDebito.value = global.gDebito.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
      let einputCCv = document.getElementById('inputCCv');
      if (einputCCv) { einputCCv.value = global.gCCv.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
      let einputCCp = document.getElementById('inputCCp');
      if (einputCCp) { einputCCp.value = global.gCCp.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
      let einputPix = document.getElementById('inputPix');
      if (einputPix) { einputPix.value = global.gPix.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
      let einputCC = document.getElementById('inputCC');
      if (einputCC) { einputCC.value = global.gCC.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

      let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
      if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = 'none'; }

    }

    /* 
        let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
        if (eimgloadingReactTabulatorCxMov) {
          eimgloadingReactTabulatorCxMov.style.display = 'none';
        }
    
        let edivReactTabulatorCxMov = document.getElementById('divReactTabulatorCxMov');
        if (edivReactTabulatorCxMov) {
          edivReactTabulatorCxMov.style.display = '';
        }
    
        btnFPGresetAll();
        global.gSubTotal = 0;
        var i
        if (Lancamentos) {
    
          for (i = 0; i < Lancamentos.length; i++) {
    
            global.gSubTotal = global.gSubTotal + (realParaNumber(Lancamentos[i].valor) * (realParaNumber(Lancamentos[i].qtd)));
    
          }
          let edivFormadePG = document.getElementById('divFormadePG');
          if (edivFormadePG) { edivFormadePG.style.display = ''; }
    
          let einputVConta = document.getElementById('inputVConta');
          let eibtnPgConta = document.getElementById('btnPgConta');
          if (Lancamentos.length > 1) {
            einputVConta.disabled = true;
            eibtnPgConta.disabled = true;
          } else {
            einputVConta.disabled = false;
            eibtnPgConta.disabled = false;
    
          }
    
    
    
        } else {
          let edivFormadePG = document.getElementById('divFormadePG');
          if (edivFormadePG) { edivFormadePG.style.display = 'none'; }
    
        }
        let einputTotal = document.getElementById('inputTotal');
        if (einputTotal) { einputTotal.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */
    //cl("global.gSubTotal", global.gSubTotal)
    //cl("Lancamentos mudou", Lancamentos)

  }, [Lancamentos])

  useEffect(() => {

    if (isFirstRunOpenCaixa.current) {
      isFirstRunOpenCaixa.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    if (global.gOpencaixa === 0) {

      //cl("openCaixa", openCaixa);
      //let iopen = dateFormat(Date.parse(openCaixa.open), "yyyy-mm-dd HH:MM:ss");
      let iopen = moment(openCaixa.open).format("YYYY-MM-DD");
      let itroco = realParaNumber(openCaixa.troco);
      //cl("iopen", iopen);
      //cl('itroco', itroco);

      async function fetchdata() {
        var bodyFormData = new FormData();
        bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
        bodyFormData.set('open', iopen);
        bodyFormData.set('troco', itroco);
        bodyFormData.set('user', global.gIdUser);

        const urlfull = 'https://api.espacomarciobagagine.com.br/OpenCaixa.php';
        //cl("urlfull",urlfull);
        const response = await axios({
          method: 'post',
          url: urlfull,
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        //cl("response",response);
        if (response['data'] === 'caixa aberto ou já existe') {
          modalBase("Atenção", "O Caixa esta aberto ou Já existe caixa fechado para esta data !");
          //window.alert('O Caixa esta aberto ou Já existe caixa fechado para esta data !')
          return
        }
        //setrepCaixaOpen(response);

        setAtu({ '0': '0' });
        global.gOpencaixa = 1;
        if (!ignore) {

          let eleLoading = document.getElementById('divLoad');
          if (eleLoading) { eleLoading.style.display = 'none'; };
        }
      }


      fetchdata();
    }
    return () => { ignore = true };

  }, [openCaixa])


  useEffect(() => {

    if (repCaixaOpen['data']) {
      let epanelCxOpen = document.getElementById('panelCxOpen');
      if (epanelCxOpen) { epanelCxOpen.style.display = ''; }
      let epanelCxClose = document.getElementById('panelCxClose');
      if (epanelCxClose) { epanelCxClose.style.display = 'none'; }
      let etituloCxOpen = document.getElementById('tituloCxOpen');
      let epanelMovresumo = document.getElementById('panelMovresumo');
      if (epanelMovresumo) { epanelMovresumo.style.display = ''; }
      let epanelMovaction = document.getElementById('panelMovaction');
      if (epanelMovaction) { epanelMovaction.style.display = ''; }
      let resp = repCaixaOpen['data'][0];
      if (etituloCxOpen) { etituloCxOpen.innerHTML = '>>> Caixa Aberto - ' + moment(resp.open).format("DD/MM/YYYY") + ' - ' + moment(resp.open).format("dddd, DD [de] MMMM YYYY") }
      //setAtuLanc({ '0': '0' });
      global.gCaixaId = resp['id'];
      if (global.gAlertaCaixaMov === 0) {


        let current = moment().startOf('day');
        //cl("dif",moment.duration(moment(resp.open).diff(current)).asDays())
        if ((moment.duration(moment(resp.open).diff(current)).asDays()) < 0) {
          let f1 = "O Caixa Aberto (" + moment(resp.open).format("DD/MM/YYYY") + ") não esta na data de hoje ! (" + moment(current).format("DD/MM/YYYY") + ")";
          modalBase("Atenção", f1, "Revise e feche o caixa logo!")
          global.gAlertaCaixaMov = 1;
        }
      }


      //global.gOpencaixa = 1;

      //cl("ok repCaixaOpen data", repCaixaOpen);

      setAtuLancamentos({ 'caixa': global.gCaixaId })
      setAtuRelComiss({ 'caixa': global.gCaixaId })
      setAtuRelComissResumo({ 'caixa': global.gCaixaId })


    } else {
      //  cl("no repCaixaOpen data");
      let epanelCxClose = document.getElementById('panelCxClose');
      if (epanelCxClose) { epanelCxClose.style.display = ''; }
      let epanelCxOpen = document.getElementById('panelCxOpen');
      if (epanelCxOpen) { epanelCxOpen.style.display = 'none'; }
      let epanelMovresumo = document.getElementById('panelMovresumo');
      if (epanelMovresumo) { epanelMovresumo.style.display = 'none'; }
      let epanelMovaction = document.getElementById('panelMovaction');
      if (epanelMovaction) { epanelMovaction.style.display = 'none'; }
      global.gOpencaixa = 0;
      //window.open("/caixa", "_self");

    }


  }, [repCaixaOpen])


  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaCaixa.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCaixaOpen(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrep(redata2);
          //cl("redata2",redata2);
        } else {
          setrep(redata);
          //cl("redata",redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];



        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    /*     if (isFirstRunAtuLanc.current) {
          isFirstRunAtuLanc.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');

      const urlfull = 'https://api.espacomarciobagagine.com.br/listaFecha.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCaixaOpen(response);
      setLanc(response['data']);
      setAtu({ '0': '0' });
      if (!ignore) {
        /*         const redata = [{}];
                const redata2 = response['data'];
                if (redata2) {
                  setrep(redata2);
                } else {
                  setrep(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }


    fetchdata();

    return () => { ignore = true };
  }, [AtuLanc])

  const ref = null;
  function paramLookup() {
    //do some processing and return the param object
    return { param1: "green" };
  }

  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 48, visible: true, headerSort: false, responsive: 0, headerSortStartingDir: "asc" },
    /* { title: 'Caixa', field: 'open', hozAlign: 'center', width: 120, visible: true, responsive: 0, headerSort: false }, */
    { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 250, responsive: 0, headerSort: false },
    { title: 'Op', field: 'tipo', hozAlign: "center", width: 10, responsive: 0, headerSort: false, },
    { title: 'Total R$', field: 'valor', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Dinheiro', field: 'cash', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Troco', field: 'troco', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Débito', field: 'debito', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Credito', field: 'creditoavista', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'CredParc.', field: 'creditoparc', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'PIX', field: 'pix', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'CC', field: 'cc', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    /*  { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }, */
  ];
  const columnsRel = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 48, visible: true, headerSort: false, responsive: 0, headerSortStartingDir: "asc" },
    { title: 'Agenda', field: 'agenda', align: 'center', width: 90, responsive: 0, formatter: formatData },
    /* { title: 'Caixa', field: 'open', hozAlign: 'center', width: 120, visible: true, responsive: 0, headerSort: false }, */
    { title: 'Descrição', field: 'cliente', hozAlign: "left", width: 250, responsive: 0, headerSort: false },
    /* { title: 'Profissional', field: 'prof', hozAlign: "left", width: 125, responsive: 0, headerSort: false }, */
    { title: 'Serviço', field: 'serv', hozAlign: "left", width: 150, responsive: 0, headerSort: false },
    { title: 'Total R$', field: 'valor', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Insumos R$', field: 'insumos', hozAlign: "right", width: 80, responsive: 0, headerSort: false },
    { title: 'Getnet %', field: 'getnet', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Antecip %', field: 'antecip', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    { title: '%', field: 'porc', hozAlign: "right", width: 40, responsive: 0, headerSort: false },
    { title: 'Receber', field: 'receber', hozAlign: "right", width: 90, responsive: 0, headerSort: false, bottomCalc: adultCalc },
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    /*  { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }, */
  ];
  const columnsRelResumo = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    //    { title: 'Id', field: 'id', hozAlign: 'center', width: 10, visible: true, headerSort: false, responsive: 0, headerSortStartingDir: "asc" },
    //    { title: 'Agenda', field: 'agenda' ,align: 'center', width: 90, responsive:0, formatter:formatData},
    /* { title: 'Caixa', field: 'open', hozAlign: 'center', width: 120, visible: true, responsive: 0, headerSort: false }, */
    //    { title: 'Descrição', field: 'cliente', hozAlign: "left", width: 250, responsive: 0, headerSort: false },
    { title: 'Profissional', field: 'prof', hozAlign: "left", width: 125, responsive: 0, headerSort: false },
    //    { title: 'Serviço', field: 'serv', hozAlign: "left", width: 150, responsive: 0, headerSort: false },
    //    { title: 'Total R$', field: 'valor', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    //    { title: 'Insumos R$', field: 'insumos', hozAlign: "right", width: 80, responsive: 0, headerSort: false },
    //    { title: 'Getnet %', field: 'getnet', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //    { title: 'Antecip %', field: 'antecip', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //    { title: '%', field: 'porc', hozAlign: "right", width: 40, responsive: 0, headerSort: false },
    { title: 'à Receber R$', field: 'receber', hozAlign: "right", width: 90, responsive: 0, headerSort: false },
    //{ title: 'Classificação', field: 'star', hozAlign: "right", width: 90, responsive: 0, headerSort: false, formatter:'star', formatterParams:{stars:5} },
    { title: 'Vendas', field: 'progress', hozAlign: "left", width: 90, responsive: 0, headerSort: false, formatter:'progress', formatterParams:{color:["rgb(255,0,0)", "#38b544f5","#00fd00" ]} },
    { title: 'Rentabilidade', field: 'rentprogress', hozAlign: "left", width: 90, responsive: 0, headerSort: false, formatter:'progress', formatterParams:{color:["rgb(255,0,0)", "#38b544f5","#00fd00" ]} }

    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    /*  { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }, */
  ];





  const options = {
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  const optionsRel = {
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    groupBy: "prof",
    groupHeader: function (value, count, data, group) {
      //value - the value all members of this group share
      //count - the number of rows in this group
      //data - an array of all the row data objects in this group
      //group - the group component for the group

      if (count <= 1) {

        return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>";
      } else {

        return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " items)</span>";
      }
    },
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:"prof",
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  const optionsRelResumo = {
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    /*     groupBy:"prof",
        groupHeader:function(value, count, data, group){
          //value - the value all members of this group share
          //count - the number of rows in this group
          //data - an array of all the row data objects in this group
          //group - the group component for the group
      
          if (count <= 1){
    
            return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>";
          } else {
            
            return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " items)</span>";
          }
      }, */
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:"prof",
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };


  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {


    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = '';

    let einputCodBarras = document.getElementById('inputCodBarras');
    einputCodBarras.value = '';
    einputCodBarras.focus();

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';


    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = '';

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = '';

    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = '';


    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }


  function editCadastro(params) {

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = 'none';

    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Editar Produto ..."

    var arrFound = rep.filter(function (item, num) {
      return item.id === params;
    });

    global.gLog = arrFound[0];
    //cl("global.gLog", global.gLog);
    let dtfinal = '';
    /*    if(arrFound[0].dtnasc){
          
          let dt = arrFound[0].dtnasc.split("/");
          dtfinal = ''+dt[2]+'-'+dt[1]+'-'+dt[0]+'';
        } else {
          dtfinal = '';
        } */

    //cl(dtfinal);

    let eleCodBarras = document.getElementById('inputCodBarras');
    eleCodBarras.value = arrFound[0].ean;

    let elenome = document.getElementById('inputNome');
    elenome.value = arrFound[0].descricao;
    //elenome.disabled = true;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = arrFound[0].valor;

    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = arrFound[0].unidade;

    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = arrFound[0].valor_custo;

    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = arrFound[0].estoque_min;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = arrFound[0].grupo;

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = parseFloat(arrFound[0].porc.replace(',', '.'));


    //validaFone();
    CalcSimule();


  }

  function editLanc(id, valor, qtd, descricao, idagenda) {


    // cl(id, valor, qtd);


    confirmAltLanc(id, valor, qtd, descricao, idagenda);

    let einputMQtd = document.getElementById('inputMQtd');
    einputMQtd.value = qtd;
    let einputMValor = document.getElementById('inputMValor');
    einputMValor.value = valor;
    /*       setEditLancamentos({
            id: 126,
            valor: "400,00",
            qtd: 1
          })
    
          setAtuLancamentos({ idagenda: global.gidAgenda }) */

  }

  function btnFinalizarPG(params) {

    let einputVCash = document.getElementById('inputVCash');
    let rVCash = realParaNumber(einputVCash.value);
    let einputVTroco = document.getElementById('inputVTroco');
    let rVTroco = realParaNumber(einputVTroco.value);
    let einputVCD = document.getElementById('inputVCD');
    let rVCD = realParaNumber(einputVCD.value);
    let einputVCC = document.getElementById('inputVCC');
    let rVCC = realParaNumber(einputVCC.value);
    let einputVCCx = document.getElementById('inputVCCx');
    let rVCCx = realParaNumber(einputVCCx.value);
    let einputVPix = document.getElementById('inputVPix');
    let rVPIX = realParaNumber(einputVPix.value);
    let einputVConta = document.getElementById('inputVConta');
    let rConta = realParaNumber(einputVConta.value);


    let somaFPG = rConta;
    somaFPG = somaFPG + rVCash;
    somaFPG = somaFPG + rVCD;
    somaFPG = somaFPG + rVCC;
    somaFPG = somaFPG + rVCCx;
    somaFPG = somaFPG + rVPIX;

    //cl("somaFPG",somaFPG);
    //cl("global.gSubTotal",somaFPG);

    let descricao = 'Pagamento - Agenda (' + LancSelected.toString() + ')';
    if ((global.gSubTotal === somaFPG) || (global.gSubTotal < somaFPG)) {



      confirmFinalizarPG(descricao, global.gSubTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta);
    } else {
      //window.alert("A Soma das formas de Pagamento não confere, revise ou preencha!")
      modalBase("Atenção", "A Soma das formas de Pagamento não confere, revise ou preencha!");
    }





  }

  function confirmFinalizarPG(desc, rVTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta) {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirmação para Finalizar Pagamento</h4>
            </div>
            <div className="modal-body">
              <p>Após finalizar o Pagamento não será mais possível alterar!</p>
            </div>
            <div className="modal-body">
              <p>Tem Certeza ?</p>
            </div>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

                setFinalizaPag({
                  cliente: '',
                  caixa: global.gCaixaId,
                  idagenda: LancSelected.toString(),
                  tipo: 'C',
                  desc: desc,
                  valor: rVTotal,
                  cash: rVCash,
                  troco: rVTroco,
                  debito: rVCD,
                  creditoavista: rVCC,
                  creditoparc: rVCCx,
                  pix: rVPIX,
                  cc: rConta
                })

                onClose();
              }}
            >
              Sim, finalizar!
            </button>
          </div>
        );
      }
    });
  }

  function confirmAltLanc(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{descricao}</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Qtd.</label>
                <div className="input-group-prepend">
                  <input type="number" className="form-control" autocomplete="off" id="inputMQtd" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor R$</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValor" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');


                setEditLancamentos({
                  id: id,
                  valor: realParaNumber(einputMValor.value),
                  qtd: einputMQtd.value,
                  idagenda: idagenda
                })

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }

  function btnconfirmFechaCx(params) {

    confirmFechaCx();
    let einputCxTValor = document.getElementById('inputCxTValor');
    einputCxTValor.value = global.gCash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  }

  function calcProxCx(vtotal, sangria) {

    let sang = realParaNumber(document.getElementById(sangria).value);
    let result = global.gCash - sang;
    if (result >= 0) {

      document.getElementById(vtotal).value = result.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    } else {
      window.alert("O Valor da Sangria não pode ser maior que o Saldo Atual !");
      document.getElementById(sangria).value = global.gCash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      result = 0;
      document.getElementById(vtotal).value = result.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    cl(vtotal, sangria);

  }

  function confirmFechaCx(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;


    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Fechamento de Caixa - Sangria</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="lEmail3">Saldo Atual</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" disabled autocomplete="off" id="inputCxTValor" />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lEmail3">Sangria</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" onChange={() => calcProxCx('inputCxProx', 'inputCxSangria')} autocomplete="off" id="inputCxSangria" />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lEmail3">Próximo Caixa</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" disabled autocomplete="off" id="inputCxProx" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputCxSangria = document.getElementById('inputCxSangria');
                let einputCxProx = document.getElementById('inputCxProx');

                if (!einputCxSangria.value) {
                  einputCxSangria.focus();
                  return
                }
                if (!einputCxProx.value) {
                  einputCxSangria.focus();
                  return
                }

                // return


                setFechaCaixa({
                  sangria: realParaNumber(einputCxSangria.value),
                  vfinal: realParaNumber(einputCxProx.value),
                })

                onClose();
              }}
            >
              Fechar Caixa
            </button>
          </div>
        );
      }
    });
  }

  function selectChange(params) {

    let einputMIdProf = document.getElementById('inputMIdProf');
    let einputMDtVenc = document.getElementById('inputMDtVenc');
    let eSelectTipo = document.getElementById('SelectTipo');
    let eSelectFpg = document.getElementById('SelectFpg');
    cl("Profis",Profis)

    if (eSelectTipo.value === "V") {
      einputMIdProf.disabled = false;
      einputMDtVenc.disabled = false;
      einputMIdProf.focus();
      eSelectFpg.value = "cash";
      eSelectFpg.disabled = true;
      let k;
      let eSelectServ = document.getElementById('inputMIdProf');
      removeOptions(document.getElementById('inputMIdProf'));
      //cl(searchServicos)
      if(Profis.length > 1){
        let opt = document.createElement('option');
        opt.value = 0;
        opt.innerHTML = 'Selecione...';
        eSelectServ.appendChild(opt);
  
      }
      for (k = 0; k < Profis.length; k++) {
        let opt = document.createElement('option');
        opt.value = Profis[k].id;
        opt.innerHTML = Profis[k].name;
        eSelectServ.appendChild(opt);
      }
    } else {

      einputMIdProf.disabled = true;
      einputMDtVenc.disabled = true;
      eSelectFpg.disabled = false;
    }

  }

  function selectProf(params) {
    let einputMDesc = document.getElementById('inputMDesc');
    let einputMIdProf = document.getElementById('inputMIdProf');

    einputMDesc.value = '(' + einputMIdProf.children[einputMIdProf.selectedIndex].textContent + ') VALE : ' + einputMDesc.textContent;

  }

  function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  function confirmMovCx(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Lançamento manual/avulso no Caixa</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Tipo Movimentação</label>
                <div className="input-group-prepend">
                  <select className="form-control select2 select2-hidden-accessible" onChange={() => selectChange()} id="SelectTipo" tabIndex={-1} aria-hidden="true">
                    <option >selecione</option>
                    <option value="E">Entrada</option>
                    <option value="S">Saída</option>
                    <option value="V">Vale Profissional</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">ID Prof.</label>
                <div className="input-group-prepend">
                  <select className="form-control select2 select2-hidden-accessible" onChange={() => selectProf()} disabled id="inputMIdProf" tabIndex={-1} aria-hidden="true">
                    <option >selecione</option>
                    <option value="6">César</option>
                    <option value="8">Gal</option>
                    <option value="2">Jorge</option>
                    <option value="9">Josy</option>
                    <option value="5">Júlio</option>
                    <option value="3">Kaká</option>
                    <option value="17">Lucas</option>
                    <option value="4">Rey</option>
                    <option value="18">Stefanny</option>
                    <option value="10">Val</option>
                    <option value="1">Márcio Bagagine</option>
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Vencimento</label>
                <div className="input-group-prepend ">
                  <input type="date" className="form-control" disabled autocomplete="off" id="inputMDtVenc" />
                </div>
              </div>

            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="lEmail3">Descrição</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMDesc" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor R$</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValor" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Forma de Pagamento</label>
                <div className="input-group-prepend">
                  <select className="form-control select2 select2-hidden-accessible" id="SelectFpg" tabIndex={-1} aria-hidden="true">
                    <option >selecione</option>
                    <option value="cash">Dinheiro</option>
                    <option value="debito">Débito</option>
                    <option value="creditoavista">Credito à Vista</option>
                    <option value="creditoparc">Crédito Parc.</option>
                    <option value="pix">PIX</option>
                    <option value="cc">CC</option>
                  </select>
                </div>
              </div>

            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let eSelectTipo = document.getElementById('SelectTipo');
                let eSelectFpg = document.getElementById('SelectFpg');
                let einputMValor = document.getElementById('inputMValor');
                let einputMDesc = document.getElementById('inputMDesc');
                let einputMIdProf = document.getElementById('inputMIdProf');
                let einputMDtVenc = document.getElementById('inputMDtVenc');

                if (eSelectTipo.value === 'selecione') {
                  eSelectTipo.focus();
                  return
                }
                if ((eSelectTipo.value === 'V') && (!einputMIdProf.value)) {
                  einputMIdProf.focus();
                  return
                }
                if ((eSelectTipo.value === 'V') && (!einputMDtVenc.value)) {
                  einputMDtVenc.focus();
                  return
                }

                if (!einputMDesc.value) {
                  einputMDesc.focus();
                  return
                }

                if (!einputMValor.value) {
                  einputMValor.focus();
                  return
                }

                if (eSelectFpg.value === 'selecione') {
                  eSelectFpg.focus();
                  return
                }

                //return


                setAddLancManual({
                  tipo: eSelectTipo.value,
                  desc: einputMDesc.value,
                  valor: realParaNumber(einputMValor.value),
                  idprof: einputMIdProf.value,
                  Fpg: eSelectFpg.value,
                  dtvenc: einputMDtVenc.value
                })

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }


  function realParaNumber(texto) {
    //cl("realParaNumber",texto)
    if (texto === '') {
      return 0
    }
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    //cl("valor",valor);

    return valor;
  }

  function ajustaInputMoney(params) {

    let eleVUnit = document.getElementById('inputValorTroco');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  }

  function CalcSimule(params) {

    /*     var iNf_valor = document.getElementById("inputNf_valor");
        let V_Nf_valor = realParaNumber(iNf_valor.value);
        if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    var iVlr_pago = document.getElementById("inputValor");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    /* 
        var iDif = document.getElementById("inputDif");
        let V_dif = realParaNumber(iDif.value);
        if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     var iPcc = document.getElementById("inputPcc");
        let V_Pcc = realParaNumber(iPcc.value);
        if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
          V_dif = V_pago - V_Nf_valor;
          if (V_dif > 0) {
            iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */
    /* 
        if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
          V_Pcc = V_Nf_valor * 0.045;
          if (V_Pcc > 0) {
            iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */




  }

  function validaOpenCaixa(params) {

    let einputDtCx = document.getElementById('inputDtCx');
    if (!einputDtCx.value) {
      window.alert('Selecione a Data de Abertura !!!')
      einputDtCx.focus();
      return
    };





    modalCaixaOpen(document.getElementById('inputDtCx').value, document.getElementById('inputValorTroco').value);

  }

  function modalBase(h1, p, p2, focus, inputquery) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{h1}</h4>
            </div>
            <div className="modal-body">
              <p>{p}</p>
            </div>
            <div className="modal-body">
              <p>{p2}</p>
            </div>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button id="btnModal"
              className="btn bg-gradient-info float-right"
              onClick={() => {
                if (focus) {
                  document.getElementById(focus).focus();
                  // cl("focus")
                }
                if (inputquery) {
                  let inputM = document.querySelectorAll('input');
                  //cl("inputmodal",inputM)
                  inputM[inputquery].focus();
                }

                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div >
        );
      }
    });
  }

  function modalCaixaOpen(data, troco) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Abertura de Caixa</h4>
            </div>
            <div className="modal-body">
              <p>Tem certeza que deseja realizar a Abertura para o</p>
              <p>Dia {moment(data).format("DD/MM/YYYY")} com Troco de {troco} ?</p>
            </div>
            <button className="btn bg-gradient-danger btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

                setopenCaixa({
                  open: data,
                  troco: troco,
                  user: global.gIdUser
                })
                onClose();

              }}
            >
              Sim, abrir agora!
            </button>
          </div>
        );
      }
    });
  }

  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }


  function CaixaFechado(params) {
    return (
      <div id="panelCxClose" name="panelCxClose" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-danger cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Caixa Fechado</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label htmlFor="lNome">Acesse Caixa / Receber para abrir um novo caixa</label>
                      </div>
                    </div>

                    {/* <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button> */}
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function CaixaAberto(params) {
    return (
      <div id="panelCxOpen" name="panelCxOpen" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-success cardAddBgg" id="cardSelCaixa" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Caixa Aberto</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelCaixaBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label id="labelDualList" htmlFor="lNome">Movimentações realizadas no Caixa</label>

                        <img id="imgloadingReactTabulatorCxMov" style={{ display: '' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulatorCxMov" >
                          <ReactTabulator
                            name="rcardSelCaixaBody"
                            id="rcardSelCaixaBody"
                            // ref={(ref)}
                            columns={columns}
                            data={Lancamentos}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={options}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function pageRelComiss(params) {
    return (
      <div id="panelRelComiss" name="panelRelComiss" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-primary cardAddBgg collapsed-card" id="cardSelCaixa" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloRelComiss">Relatório de Conferência da Semana Atual ({moment().day(1).format('DD/MM')} à {moment().day(7).format('DD/MM/YYYY')})</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse" >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelCaixaBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label id="labelReactTabulatorCxMov" htmlFor="lNome">Resumo nesta Semana</label>
                        <div id="divReactTabulatorCxMov" >
                          <ReactTabulator
                            name="rcardSelCaixaBody"
                            id="rcardSelCaixaBody"
                            // ref={(ref)}
                            columns={columnsRelResumo}
                            data={RelComissResumo}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={optionsRelResumo}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          /><div>
                            <br />&nbsp;<br />
                          </div>

                        </div>
                        <label id="labelReactTabulatorCxMov" htmlFor="lNome">Apontamentos Localizados nesta Semana</label>
                        <img id="imgloadingRelComiss" style={{ display: '' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulatorCxMov" >
                          <ReactTabulator
                            name="rcardSelCaixaBody"
                            id="rcardSelCaixaBody"
                            // ref={(ref)}
                            columns={columnsRel}
                            data={RelComiss}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={optionsRel}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function Movresumo(params) {
    return (
      <div id="panelMovresumo" name="panelMovresumo" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-info cardAddBgg collapsed-card" id="cardMovAction" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Resumo</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelCaixaBody">
                  {/* Conversations are loaded here */}
                  <form id="formMovActions">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div id="divSaldos" >
                          <div className="form-row">
                            <div className="form-group col-md-10" id="idSaldoCaixa">

                            </div>
                            <div className="form-group col-md-2" id="idSaldoCaixa">
                              <label id="labelCash" htmlFor="lCep">Saldo no Caixa</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputCash" />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-2">
                              <label id="labelTotal" htmlFor="lCep">Total de Entradas</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputTotal" />
                              </div>
                            </div>
                            <div className="form-group col-md-2">
                              <label id="labelDebito" htmlFor="lCep">Débito</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputDebito" />
                              </div>
                            </div>
                            <div className="form-group col-md-2">
                              <label id="labelCCv" htmlFor="lCep">Crédito à Vista</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputCCv" />
                              </div>
                            </div>
                            <div className="form-group col-md-2">
                              <label id="labelCCp" htmlFor="lCep">Crédito Parcelado</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputCCp" />
                              </div>
                            </div>
                            <div className="form-group col-md-2">
                              <label id="labelPix" htmlFor="lCep">PIX</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputPix" />
                              </div>
                            </div>
                            <div className="form-group col-md-2">
                              <label id="labelCC" htmlFor="lCep">CC</label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" disabled id="inputCC" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function Movaction(params) {
    return (
      <div id="panelMovaction" name="panelMovaction" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-danger cardAddBgg collapsed-card" id="cardMovAction" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Operações</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelCaixaBody">
                  {/* Conversations are loaded here */}
                  <form id="formMovActions">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div id="divSaldos" >
                          <div className="form-row">
                            <div className="form-group col-md-10" id="idSaldoCaixa">

                            </div>
                            <div className="row col-md-12" id="alterarEbotoes">
                              <div className="row col-md-12">
                                <div className="col-sm">
                                  <div className="form-group ">
                                    <button type="button" className="btn btn-success  " id="btnMovCxFecharCx" onClick={() => btnconfirmFechaCx()}>Fechar Caixa</button>
                                    &nbsp;
                                    <button type="button" className="btn btn-danger float-right" id="btnMovCxSaiMan" onClick={() => confirmMovCx()}>Entrada/Saida Manual</button>
                                    &nbsp;
                                  </div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function CaixaFechamento(params) {
    return (
      <div id="panelCxOpen" name="panelCxOpen" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-info cardAddBgg" id="cardSelFech" style={{ display: '' }}>
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Fechamento Selecionado : {global.gLancSelected}</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelFechBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-7">
                        <img id="imgloadingReactTabulatorCxMov" style={{ display: '' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulatorCxMov" >
                          <label htmlFor="lNome">Itens Localizados</label>
                          <ReactTabulator
                            name="rcardSelFechBody"
                            id="rcardSelFechBody"
                            // ref={(ref)}
                            columns={columns}
                            data={Lancamentos}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={options}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                          <label id="labelTotal" htmlFor="lCep">Total</label>
                          <div className="input-group-prepend">
                            <input type="text" className="form-control" disabled id="inputTotal" />
                          </div>
                        </div>
                      </div>
                      <div id="divFormadePG" style={{ display: 'none' }} className="form-group col-md-4">
                        <div className="row">
                          <label id="labelTCalc" name="labelTCalc">Formas de Pagamento</label>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Dinheiro</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCash" className="btn btn-info " onClick={() => btnFPG('inputVCash')} ><i className="fa fa-money"></i> </button>
                              <input type="text" onBlur={() => calcTroco()} onKeyDown={e => keyDetect(e.key)} className="form-control" id="inputVCash" />
                            </div>
                          </div>
                          <div className="form-group col-md-4 ">
                            <label htmlFor="lEmail3">Troco</label>
                            <div className="input-group-prepend">
                              <input type="text" className="form-control" id="inputVTroco" disabled />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Débito</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCD" className="btn btn-info " onClick={() => btnFPG('inputVCD')} ><i className="fa fa-credit-card-alt "></i> </button>
                              <input type="text" className="form-control" id="inputVCD" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Crédito à vista</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCC" className="btn btn-info " onClick={() => btnFPG('inputVCC')} ><i className="fa fa-credit-card"></i> </button>
                              <input type="text" className="form-control" id="inputVCC" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Crédito Parcelado</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCCx" className="btn btn-info " onClick={() => btnFPG('inputVCCx')} ><i className="fa fa-credit-card"></i> </button>
                              <input type="text" className="form-control" id="inputVCCx" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">PIX</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgPix" className="btn btn-info " onClick={() => btnFPG('inputVPix')} ><i className="fa fa-qrcode"></i> </button>
                              <input type="text" className="form-control" id="inputVPix" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row" title="Credito Corrente (Ficha Interna) - Somente um fechamento por vez">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3" >CC</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgConta" className="btn btn-info " onClick={() => btnFPG('inputVConta')} ><i className="fa fa-creative-commons "></i> </button>
                              <input type="text" className="form-control" id="inputVConta" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <button type="button" className="btn btn-info float-right" id="btnGravar" onClick={e => btnFinalizarPG()}>Finalizar Pagamento</button>
                        <button type="button" className="btn btn-info " id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function testCaixa(params) {




  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      //global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    btnFPGresetAll();
    dualChangeLancReset();
    AtuRefresh();
    let ecardSelCaixa = document.getElementById('cardSelCaixa');
    ecardSelCaixa.classList.remove("collapsed-card");

    let ecardSelCaixaBody = document.getElementById('cardSelCaixaBody');
    if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = ''; }

    let ecardSelFech = document.getElementById('cardSelFech');
    if (ecardSelFech) { ecardSelFech.style.display = 'none'; }

  }

  function AtuRefresh(params) {

    setAtuLanc({ '0': '0' });

  }

  function btnFPGresetAll(params) {
    let einputVCash = document.getElementById('inputVCash');
    if (einputVCash) { einputVCash.value = ''; }
    let einputVTroco = document.getElementById('inputVTroco');
    if (einputVTroco) { einputVTroco.value = ''; }
    let einputVCD = document.getElementById('inputVCD');
    if (einputVCD) { einputVCD.value = ''; }
    let einputVCC = document.getElementById('inputVCC');
    if (einputVCC) { einputVCC.value = ''; }
    let einputVCCx = document.getElementById('inputVCCx');
    if (einputVCCx) { einputVCCx.value = ''; }
    let einputVPix = document.getElementById('inputVPix');
    if (einputVPix) { einputVPix.value = ''; }
    let einputVConta = document.getElementById('inputVConta');
    if (einputVConta) { einputVConta.value = ''; }

    /*     let einputVCash = document.getElementById('inputVCash');
        if (einputVCash) { einputVCash.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCD = document.getElementById('inputVCD');
        if (einputVCD) { einputVCD.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCC = document.getElementById('inputVCC');
        if (einputVCC) { einputVCC.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCCx = document.getElementById('inputVCCx');
        if (einputVCCx) { einputVCCx.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVPix = document.getElementById('inputVPix');
        if (einputVPix) { einputVPix.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVConta = document.getElementById('inputVConta');
        if (einputVConta) { einputVConta.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */
  }

  function btnFPG(params) {
    btnFPGresetAll();
    let eparams = document.getElementById(params);
    if (eparams) {
      eparams.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      eparams.focus();
    }

  }

  function calcTroco(params) {
    let einputVCash = document.getElementById('inputVCash');
    let einputVTroco = document.getElementById('inputVTroco');
    let cash = realParaNumber(einputVCash.value);
    let troco = 0;
    //cl(LancSelected);
    troco = cash - global.gSubTotal
    if (troco > 0) {
      let einputVCashFormat = cash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      einputVCash.value = einputVCashFormat;
      //cl(einputVCashFormat);
      einputVTroco.value = troco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    } else {
      let trocozero = 0;
      let einputVCashFormat = einputVCash.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      einputVCash.value = einputVCash.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      //cl(einputVCashFormat);
      einputVTroco.value = trocozero.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    //cl("troco",troco);

  }

  function dualChangeLanc(params) {
    let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
    if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = ''; }
    global.gParams = params;
    setLancSelected(params)
    //cl("params", params)
    setAtuLancamentos({ idagenda: global.gParams })
    setAtuRelComiss({ idagenda: global.gParams })
    setAtuRelComissResumo({ idagenda: global.gParams })
    let qtd = 0;
    var i;
    var j;

    for (i = 0; i < params.length; i++) {
      // cl("params_i", params[i])
      let idfilter = params[i];
      var arrFound = Lanc.filter(function (item, num) {
        return item.value === idfilter;
      });
      let idcliente = arrFound[0]['cliente'];
      var arrFoundCli = Lanc.filter(function (item, num) {
        return item.cliente === idcliente;
      });

      // cl("arrFound", arrFound[0]['cliente'])
      // cl("arrFoundCli", arrFoundCli)

      for (j = 0; j < Lanc.length; j++) {
        //cl("compLanc",arrFound[0]['cliente'])
        if (Lanc[j]['cliente'] === arrFound[0]['cliente']) {
          cl("Somou Lanc")
          qtd = qtd + 1;
        }
      }
    }

    if (qtd > 1) {
      //window.alert("Atenção : existem mais de um fechamento em aberto deste cliente! ")
      setLanc(arrFoundCli);
      //cl("params.length", params.length)
      // cl("arrFoundCli.length", arrFoundCli.length)

      if (arrFoundCli.length !== params.length) {

        modalBase("Atenção", "Existem mais de um fechamento em aberto deste cliente!");

      } else {
        qtd = 1;
      }
    }



    if (params.length > 0) {


      let ecardSelCaixa = document.getElementById('cardSelCaixa');

      let ecardSelCaixaBody = document.getElementById('cardSelCaixaBody');
      if (qtd > 1) {
        if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = ''; }
        ecardSelCaixa.classList.remove("collapsed-card");
      } else {
        if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = 'none'; }
        ecardSelCaixa.classList.add("collapsed-card");

      }

      let ecardSelFech = document.getElementById('cardSelFech');
      if (ecardSelFech) { ecardSelFech.style.display = ''; }


    } else {

      let ecardSelFech = document.getElementById('cardSelFech');
      if (ecardSelFech) { ecardSelFech.style.display = 'none'; }

    }


  }

  function keyDetect(params) {

    if (params === "Enter") {

      calcTroco();

      //  cl("key", params)
    }


  }

  function dualChangeLancReset(params) {
    global.gParams = [];
    setLancSelected();
    //cl("params", params)
    setAtuLancamentos({ idagenda: global.gParams })
    setAtuRelComiss({ idagenda: global.gParams })
    setAtuRelComissResumo({ idagenda: global.gParams })
    AtuRefresh();

  }


  if (repCaixaOpen.length < 1) {
    //if (0 == 1) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando Caixa ...</div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {CaixaFechado()}
        {CaixaAberto()}
        {Movresumo()}
        {Movaction()}
        {pageRelComiss()}
        {/* {testCaixa()} */}
      </div>
    )

  }



}

export default Gerador   