/* eslint-disable */
import React, { Component, useState, useEffect, useRef, useContext, Children } from "react";
//import { browserName, browserVersion, isSafari, isChrome, isIOS, isAndroid, isMobile } from "react-device-detect";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Calendar, momentLocalizer } from "react-big-calendar";
import Loading from "./assets/loading4.gif";
//import IcoPdf from "./assets/ico_pdf.png";
//import IcoAgenda from "./assets/agenda.ico";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerCotacoes.css';
import './SearchBar.css'
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import '../node_modules/moment/locale/pt-br';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import copycli from "copy-to-clipboard";
//import PHE from "print-html-element";
//import Countdown from "react-countdown";
//import { date } from "faker";
import dateFormat from "dateformat";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { now } from "moment";
import EventWrapper from "./EventWrapper";
//autocompletar
//import { GoArrowDown, GoSearch } from 'react-icons/go'
//import { AiOutlineClose } from 'react-icons/ai'
//import { IconContext } from 'react-icons'
//import Users from './db.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomEvent from './CustomEvent';
import AgendaCustomEvent from './AgendaCustomEvent';
import RBCToolbar from "./CustomToolbar";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//import DualListBox from 'react-dual-listbox';
//import 'react-dual-listbox/lib/react-dual-listbox.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Hotkeys from 'react-hot-keys';
/* import { v4 as uuidv4 } from 'uuid';
import {v1 as uuidv1 } from 'uuid';
console.log(uuidv4());
console.log(uuidv1());
*/
//var gGlobal = require('./components/globalvar.js');

global.inpAddFrmC = {

  'inputCliente': false,
  'inputCid': false,
  'inputEmail4': false,
  'inputEmail5': false,
  'inputEmail6': false,
  'inputFone': false,
  'inputObs': false,
  'inputAssunto': false,
  'txtEmail': false
}

global.btnCriados = 0;
global.idCliente = 0;
global.idProf = 0;
global.EventoId = 0;
global.StatusAtu = '';
global.newCliente = 0;
global.animatedComponents = makeAnimated();
global.animatedComponentsInsumos = makeAnimated();
global.gidAgenda = 0;
global.gProfissional = '';
global.divLoad = 0;

var TxtSel = "";

const editIcon = function (cell, formatterParams) { //plain text value
  if (cell.getRow().getData().status == 'inicial') {
    return "<i class='fas fa-edit'></i>";
  } else {
    return "-";
  }
};

const editIconInsumo = function (cell, formatterParams) { //plain text value

  let days = (moment.duration(moment(new Date()).diff(cell.getRow().getData().createdAt))).asDays();
  //cl("diferença dias",days)


  if ((cell.getRow().getData().status == 'inicial') && (days < 1)) {
    return "<i class='fas fa-edit'></i>";
  } else {
    return "-";
  }
};
const apagarIconInsumo = function (cell, formatterParams) { //plain text value
  let days = (moment.duration(moment(new Date()).diff(cell.getRow().getData().createdAt))).asDays();
  // cl("cell",cell)
  if ((cell.getRow().getData().tipo == 1) && (days < 1)) {
    return "<i class='fa fa-trash-o'></i>";
  } else {
    return "-";
  }
};

const iconLixeira = function (cell, formatterParams) { //plain text value
  if (cell.getRow().getData().progress < 11) {
    return "<i class='fa fa-trash-o'></i>";
  } else {
    return "-";
  }
}

//const dateFormat = require("dateformat");


global.makeChave = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$@';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function MenuAction() {

  let mmenu = document.getElementById('body_base');
  if (mmenu.classList.contains('sidebar-open')) {

    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');

  }

}

//const deskW = window.innerWidth;

function Gerador() {
  const [rep, setrep] = useState([]);
  const [reprop, setreprop] = useState([]);
  const [Atu, setAtu] = useState([]);
  const [EventosPRE, setEventosPRE] = useState([]);
  const [Eventos, setEventos] = useState([]);
  const [Localizer, setLocalizer] = useState([]);
  const [ReservaAgenda, setReservaAgenda] = useState([]);
  const [AgendaStatus, setAgendaStatus] = useState([]);
  const [AgendaAlt, setAgendaAlt] = useState([]);
  const [NewCli, setNewCli] = useState([]);
  const [EventoCancelar, setEventoCancelar] = useState([]);
  const [searchProdutos, setsearchProdutos] = useState('');
  const [ProdutosOpt, setProdutosOpt] = useState([]);

  const [ProfisPre, setProfisPre] = useState([]);
  const [Profis, setProfis] = useState([]);
  const [ClientesPre, setClientesPre] = useState([]);
  const [Clientes, setClientes] = useState([]);
  const [ServicosPre, setServicosPre] = useState([]);
  const [Servicos, setServicos] = useState([]);
  const [ServicosOpt, setServicosOpt] = useState([]);
  const [ServicosSel, setServicosSel] = useState([]);
  const [ServicosSelected, setServicosSelected] = useState([]);
  const [solicitaChaveAV, setsolicitaChaveAV] = useState([]);
  const [searchString, setsearchString] = useState('');
  const [searchServicos, setsearchServicos] = useState('');
  const [AtuLancamentos, setAtuLancamentos] = useState('');
  const [AtuLancamentosPgto, setAtuLancamentosPgto] = useState('');
  const [AtuLancInsumos, setAtuLancInsumos] = useState('');
  const [Lancamentos, setLancamentos] = useState('');
  const [LancamentosPgto, setLancamentosPgto] = useState('');
  const [LancInsumos, setLancInsumos] = useState('');
  const [EditLancamentos, setEditLancamentos] = useState('');
  const [EditLancInsumos, setEditLancInsumos] = useState('');
  const [IncLog, setIncLog] = useState('');
  const [AddLanc, setAddLanc] = useState('');
  const [Produtos, setProdutos] = useState([]);
  const [ProdutosPre, setProdutosPre] = useState([]);
  const [ApagarLancamentos, setApagarLancamentos] = useState('');

  const [linkQuest, setlinkQuest] = useState('');

  const isFirstRun4 = useRef(true); //solicitaChaveAV

  const isFirstRunLocalizaer = useRef(true);
  const isFirstRunProfis = useRef(true);
  const isFirstRunEventos = useRef(true);
  const isFirstRunEventosPRE = useRef(true);
  const isFirstRunReservaAgenda = useRef(true);
  const isFirstRunAgendaStatus = useRef(true);
  const isFirstRunAgendaAlt = useRef(true);
  const isFirstRunNewCli = useRef(true);
  const isFirstRunAtuLancamentos = useRef(true);
  const isFirstRunAtuLancamentosPgto = useRef(true);
  const isFirstRunAtuLancInsumos = useRef(true);
  const isFirstRunEditLancamentos = useRef(true);
  const isFirstRunEditLancInsumos = useRef(true);
  const isFirstRunEventoCancelar = useRef(true);
  const isFirstRunIncLog = useRef(true);
  const isFirstRunAddLanc = useRef(true);
  const isFirstRunApagarLancamentos = useRef(true);

  const formatData = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(0, 4)
    let result = dia + '/' + mes + '/' + ano

    return result; //return the contents of the cell;  
  }


  global.isQuestionKey = '';

  if (window.innerWidth < 640) {
    let mmenu = document.getElementById('body_base');
    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');
    //alert('tamanho '+window.innerWidth);  
  }
  useEffect(() => {



  }, [Produtos])

  useEffect(() => {





  }, [ProdutosOpt])

  useEffect(() => {
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaProd.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      global.gProdutosSel = response['data'];

      // cl("global.gProdutosSel", global.gProdutosSel);

      setProdutos(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl("sev",redata);


        let eleLoading = document.getElementById('divLoad');
        global.divLoad = global.divLoad - 1;

        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };
      }
      // cl("global.gProdutosSel", global.gProdutosSel);
      var i;
      const result = [];
      if (global.gProdutosSel.length) {
        for (i = 0; i < global.gProdutosSel.length; i++) {
          let valor = [{
            id: global.gProdutosSel[i].id,
            value: global.gProdutosSel[i].id, //necessario para dualist
            label: global.gProdutosSel[i].ean + ' - ' + global.gProdutosSel[i].descricao,
            ean: global.gProdutosSel[i].ean,
            valor: global.gProdutosSel[i].valor
          }];
          result.push(valor[0]);
        };
      }
      //cl('result', result);
      setProdutosOpt(result);
    }
    fetchdata();



    return () => { ignore = true };



  }, [ProdutosPre])

  useEffect(() => {
    if (isFirstRunAddLanc.current) {
      isFirstRunAddLanc.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;
    // cl("AddLan", AddLanc)
    const { idagenda, idproduto, valor, qtd, serv } = AddLanc
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('idagenda', parseInt(idagenda));
      bodyFormData.set('idproduto', idproduto);
      bodyFormData.set('valor', valor);
      bodyFormData.set('qtd', qtd);
      bodyFormData.set('serv', serv);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.espacomarciobagagine.com.br/AddLancProdInsumo.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      //setAtuLancInsumos({ idagenda: global.gParams })
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setAtuLancInsumos({ 'idagenda': parseInt(idagenda) });
          //setLancamentos(redata2);
          //CloseEditContr();
        } else {
          //setLancamentos(redata);
        }


      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddLanc])
  //useEffects

  useEffect(() => {
    if (isFirstRunApagarLancamentos.current) {
      isFirstRunApagarLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { id, valor, qtd, idagenda } = ApagarLancamentos

    // cl("qtd",qtd)

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('id', id);
      bodyFormData.set('valor', valor);
      bodyFormData.set('qtd', qtd);
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.espacomarciobagagine.com.br/ApagarLancInsumo.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        //const redata = [{}];
        //const redata2 = response['data'];
        /*         if (redata2) {
                  setLancamentos(redata2);
                } else {
                  setLancamentos(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };

        //setLancamentos({0:0});
        //setAtuLancamentos({ idagenda: global.gidAgenda })
        //cl('gcontrato');
      }
      setAtuLancInsumos({ 'idagenda': parseInt(idagenda) });
    }
    fetchdata();

    // cl("Edit Lancamentos mudou", Lancamentos)
    return () => { ignore = true };
  }, [ApagarLancamentos])

  useEffect(() => {

    //cl("searchProdutos mudou", searchProdutos)

  }, [searchProdutos])

  useEffect(() => {
    if (isFirstRunAtuLancInsumos.current) {
      isFirstRunAtuLancInsumos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { idagenda } = AtuLancInsumos

    //cl("idagenda", idagenda)

    if (!idagenda) {
      return
    }

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('idagenda', idagenda);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaAgendaInsumo.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [];
        const redata2 = response['data'];
        if (redata2) {
          setLancInsumos(redata2);
        } else {
          setLancInsumos(redata);

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };

        let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
        if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = 'none'; }
      }
    }
    if (idagenda !== 0) {
      fetchdata()
    } else {
      setLancInsumos([])
    }
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuLancInsumos])

  useEffect(() => {
    async function agendachecaAtu(atu) {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      const response = await axios({
        method: 'post',
        url: 'https://api.espacomarciobagagine.com.br/listaAgendaAtu.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      global.AgendaAtu = new Date(response['data'][0]['res']);

      if (atu === 1) {
        global.AgendaAtuComp = new Date(response['data'][0]['res']);
        ////cl("Atu", atu)

      }
      /*     //cl("Ax-AgendaAtu", global.AgendaAtu)
          //cl("AX-AgendaAtuComp", global.AgendaAtuComp) */

    }
    let timeset = 5000;

    agendachecaAtu(1);

    let b1existemesmo1 = document.getElementById('b1');
    let b1ex = 0;
    if (b1existemesmo1) {
      //cl("b1existemesmo1",b1existemesmo1.innerHTML);
      b1ex = 1;
    } else {
      //cl("b1existemesmo1",b1existemesmo1);
      b1ex = 0;
    }
    if ((global.btnCriados === 0) || (b1ex === 0)) {
      bynsNavegs();
    }
    const interval = setInterval(() => {

      /*      //cl("Inter-AgendaAtu", global.AgendaAtu)
          //cl("Inter-AgendaAtuComp", global.AgendaAtuComp)  */
      ////cl("atualizando...linha 126")

      if (global.AgendaAtuComp.getTime() !== global.AgendaAtu.getTime()) {
/*       //cl("-AgendaAtu", global.AgendaAtu)
      //cl("-AgendaAtuComp", global.AgendaAtuComp)
 */        agendachecaAtu(1);
        global.AgendaAtuComp = global.AgendaAtu;
        /*       //cl("-2AgendaAtu", global.AgendaAtu)
              //cl("-2AgendaAtuComp", global.AgendaAtuComp) */
        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { 
          global.divLoad = global.divLoad + 1;
          eleLoading.style.display = '';
         };
        setAtu({ '0': '0' });

      } else {

        if (global.dtInicioPesqAgenda) {

          if ((global.dtInicioPesqAgendaComp !== global.dtInicioPesqAgenda) && (moment().add(-1, 'months') > moment(global.dtInicioPesqAgenda))) {
            let eleLoading = document.getElementById('divLoad');
            if (eleLoading) { 
          global.divLoad = global.divLoad + 1;
          eleLoading.style.display = '';
         };
            global.dtInicioPesqAgendaComp = global.dtInicioPesqAgenda;
            setAtu({ '0': '0' });

          }
        }
        /*       //cl("M-AgendaAtu", global.AgendaAtu)
              //cl("M-AgendaAtuComp", global.AgendaAtuComp) */
        agendachecaAtu();

        let b1existemesmo2 = document.getElementById('b1');
        let b1ex = 0;
        if (b1existemesmo2) {
          //cl("b1existemesmo2",b1existemesmo2.innerHTML);
          b1ex = 1;
        } else {
          //cl("b1existemesmo2",b1existemesmo2);
          b1ex = 0;
        }
        if ((global.btnCriados === 0) || (b1ex === 0)) {
          bynsNavegs();
        }
      }
    }, timeset)


    return () => clearInterval(interval)
  }, [])

  function bynsNavegs(params) {
    const b1existe = document.getElementById('b1')
    if (b1existe) {
      global.btnCriados = 1;
      ////cl(b1existe)
      return
    }

    const addbtns = document.getElementsByClassName('rbc-btn-group')
    const b1 = document.createElement('button');
    const b2 = document.createElement('button');
    const sp1 = document.createElement('span');
    //b1.setAttribute("onClick", "scrollDivagenda()");
    b1.innerHTML = "<";
    b1.id = 'b1';
    b1.onclick = function () {
      const divage = document.getElementsByClassName('rbc-time-content')
      divage[0].scrollLeft -= 500;
    };
    b2.innerHTML = ">";
    b2.onclick = function () {
      const divage = document.getElementsByClassName('rbc-time-content')
      divage[0].scrollLeft += 500;
    };
    sp1.innerHTML = '&nbsp;&nbsp;'
    let b1existemesmo = document.getElementById('b1');
    if ((addbtns[1]) && (!b1existemesmo)) {
      addbtns[1].appendChild(sp1);
      addbtns[1].appendChild(b1);
      addbtns[1].appendChild(b2);
      global.btnCriados = 1;
    } else {
      global.btnCriados = 0;
    }

  }

  useEffect(() => {

    let ignoreProf = false;
    async function fetchclientes() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        //url: global.gLinkBase + '/appyou/rest.php?apikey=398738379696650578637067567569346',
        url: 'https://api.espacomarciobagagine.com.br/listaCli.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })


      if (!ignoreProf) {
        const redata = response['data'];
        //cl('redata', redata);
        //cl('users', Users);

        setClientes(redata);
        //setLocalizer(ll);
        ignoreProf = true;

        //setEventosPRE(redata);

        //let eleLoading = document.getElementById('divLoad');
        //eleLoading.style.display = 'none';
      }
    }
    fetchclientes();


  }, [ClientesPre])

  useEffect(() => {
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaServ.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      global.gServicosSel = response['data'];

      // cl("global.gServicosSel", global.gServicosSel);

      setServicos(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl("sev",redata);


        let eleLoading = document.getElementById('divLoad');
        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };
      }
      // cl("global.gServicosSel", global.gServicosSel);
      var i;
      const result = [];
      if (global.gServicosSel.length) {
        for (i = 0; i < global.gServicosSel.length; i++) {
          let valor = [{
            id: global.gServicosSel[i].id,
            value: global.gServicosSel[i].id, //necessario para dualist
            label: global.gServicosSel[i].descricao + ' - ' + global.gServicosSel[i].tempomedio + 'h',
            porc: global.gServicosSel[i].porc,
            valor: global.gServicosSel[i].valor
          }];
          result.push(valor[0]);
        };
      }
      // cl('result', result);
      setServicosOpt(result);
    }
    fetchdata();



    return () => { ignore = true };



  }, [ServicosPre])


  useEffect(() => {

    let ignoreProf = false;
    async function fetchprofis() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        //url: global.gLinkBase + '/appyou/rest.php?apikey=398738379696650578637067567569346',
        url: 'https://api.espacomarciobagagine.com.br/listaProfis.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })


      if (!ignoreProf) {
        const redata = response['data'];
        //cl('redata', redata);

        setProfis(redata);
        //setLocalizer(ll);
        ignoreProf = true;

        //setEventosPRE(redata);

        //let eleLoading = document.getElementById('divLoad');
        //eleLoading.style.display = 'none';
      }
    }
    fetchprofis();

    /*     const resourceMap = [
          { resourceId: 1, resourceTitle: 'Marcio' },
          { resourceId: 2, resourceTitle: 'Jorge Estrela' },
          { resourceId: 3, resourceTitle: 'Kaka' },
          { resourceId: 4, resourceTitle: 'Manicure1' },
          { resourceId: 5, resourceTitle: 'Manicure2' },
          { resourceId: 6, resourceTitle: 'Manicure3' },
          { resourceId: 7, resourceTitle: 'Manicure4' },
          { resourceId: 8, resourceTitle: 'Manicure5' },
          { resourceId: 9, resourceTitle: 'Manicure6' },
          { resourceId: 10, resourceTitle: 'Manicure7' },
          { resourceId: 11, resourceTitle: 'Manicure8' },
          { resourceId: 12, resourceTitle: 'Manicure9' },
          { resourceId: 13, resourceTitle: 'Manicure10' },
          { resourceId: 12, resourceTitle: 'Manicure11' },
        ] */

  }, [ProfisPre])

  useEffect(() => {



  }, [Profis])

  useEffect(() => {
    //cl("LancInsumos.length",LancInsumos.length)
    //cl("LancInsumos",LancInsumos)


    if (LancInsumos) {
      global.gSubTotal = 0;

      var i;
      for (i = 0; i < LancInsumos.length; i++) {
        //cl("LancInsumosI",LancInsumos[i])
        global.gSubTotal = global.gSubTotal + (realParaNumber(LancInsumos[i].valor) * (realParaNumber(LancInsumos[i].qtd)));
      }

      let einputTotalInsumos = document.getElementById('inputTotalInsumos');
      if (einputTotalInsumos) { einputTotalInsumos.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    }

  }, [LancInsumos])


  const columnsInsumo = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Qtd.', field: 'qtd', hozAlign: 'center', visible: true, responsive: 0, headerSort: false },
    { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 250, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Valor R$', field: 'valor', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Sub R$', field: 'subtotal', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    { title: 'Cod', field: 'idagenda', hozAlign: 'center', width: 50, visible: true, headerSort: false, responsive: 0 },
    { title: 'Editar', formatter: editIconInsumo, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if ((cell.getRow().getData().status == 'inicial') && (((moment.duration(moment(new Date()).diff(cell.getRow().getData().createdAt))).asDays()) < 1)) { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } },
    { title: 'Apagar', formatter: apagarIconInsumo, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if ((cell.getRow().getData().tipo == 1) && (((moment.duration(moment(new Date()).diff(cell.getRow().getData().createdAt))).asDays()) < 1)) { apagaLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }
    /* { title: 'Add', formatter: addIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { addLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), 'Adicionar : ' + (cell.getRow().getData().idagenda) + ' - ' + (cell.getRow().getData().nome) + ' / ' + (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } } */
  ];





  const options = {
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  useEffect(() => {



  }, [Clientes])

  useEffect(() => {



  }, [Servicos])

  useEffect(() => {

    let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
    if (eimgloadingReactTabulator) {
      eimgloadingReactTabulator.style.display = 'none';
    }



  }, [ServicosOpt])

  useEffect(() => {

    //cl("searchString mudou",searchString)

  }, [searchString])

  useEffect(() => {

    // cl("searchServicos mudou", searchServicos)

  }, [searchServicos])

  useEffect(() => {
    if (isFirstRunAtuLancamentos.current) {
      isFirstRunAtuLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { idagenda } = AtuLancamentos


    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('idagenda', idagenda);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaLanc.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setLancamentos(redata2);
        } else {
          setLancamentos(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuLancamentos])

  useEffect(() => {
    if (isFirstRunAtuLancamentosPgto.current) {
      isFirstRunAtuLancamentosPgto.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { caixamov } = AtuLancamentosPgto


    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('caixamov', caixamov);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaPgtoCaixaMov.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setLancamentosPgto(redata2);
        } else {
          setLancamentosPgto(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eimgloadingReactTabulatorPgto = document.getElementById('imgloadingReactTabulatorPgto');
        if (eimgloadingReactTabulatorPgto) {
          eimgloadingReactTabulatorPgto.style.display = 'none';
        }
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuLancamentosPgto])

  useEffect(() => {
    if (isFirstRunIncLog.current) {
      isFirstRunIncLog.current = false;
      //cl("FirstRunDados false")
      return;
    }
    const { idcli, idprof, idagenda, user, loglink } = IncLog

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('idcli', idcli);
      bodyFormData.set('idprof', idprof);
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('user', user);
      bodyFormData.set('loglink', JSON.stringify(loglink));
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/IncLog.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          // setLancamentos(redata2);
        } else {
          // setLancamentos(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        // let eleLoading = document.getElementById('divLoad');
        //if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [IncLog])

  useEffect(() => {
    if (isFirstRunEditLancamentos.current) {
      isFirstRunEditLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { id, valor, qtd } = EditLancamentos

    // cl("qtd",qtd)

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('id', id);
      bodyFormData.set('valor', valor);
      bodyFormData.set('qtd', qtd);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaLancAlt.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        //const redata = [{}];
        //const redata2 = response['data'];
        /*         if (redata2) {
                  setLancamentos(redata2);
                } else {
                  setLancamentos(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };

        //setLancamentos({0:0});
        //setAtuLancamentos({ idagenda: global.gidAgenda })
        //cl('gcontrato');
      }
      setAtuLancamentos({ idagenda: global.gidAgenda })
    }
    fetchdata();

    // cl("Edit Lancamentos mudou", Lancamentos)
    return () => { ignore = true };
  }, [EditLancamentos])

  useEffect(() => {
    if (isFirstRunEditLancInsumos.current) {
      isFirstRunEditLancInsumos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { id, valor, qtd } = EditLancInsumos

    // cl("qtd",qtd)

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('id', id);
      bodyFormData.set('valor', realParaNumber(valor));
      bodyFormData.set('qtd', qtd);
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaLancAltInsumo.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        //const redata = [{}];
        //const redata2 = response['data'];
        /*         if (redata2) {
                  setLancamentos(redata2);
                } else {
                  setLancamentos(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if ((eleLoading) && (global.divLoad === 0)) { eleLoading.style.display = 'none'; };

        //setLancamentos({0:0});
        //setAtuLancamentos({ idagenda: global.gidAgenda })
        //cl('gcontrato');
      }
      setAtuLancInsumos({ 'idagenda': global.gidAgenda });
      //setAtuLancamentos({ idagenda: global.gidAgenda })
    }
    fetchdata();

    // cl("Edit Lancamentos mudou", Lancamentos)
    return () => { ignore = true };
  }, [EditLancInsumos])


  useEffect(() => {

    let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
    if (eimgloadingReactTabulator) {
      eimgloadingReactTabulator.style.display = 'none';
    }

    let edivReactTabulator = document.getElementById('divReactTabulator');
    if (edivReactTabulator) {
      edivReactTabulator.style.display = '';
    }

    //cl("Lancamentos mudou", Lancamentos)

  }, [Lancamentos])

  useEffect(() => {


  }, [LancamentosPgto])

  useEffect(() => {



  }, [ServicosSel])

  useEffect(() => {

    ////cl("ServicosSelected", ServicosSelected)

  }, [ServicosSelected])


  useEffect(() => {



  }, [Localizer])

  useEffect(() => {

    global.linkQuest = linkQuest;
    //cl(linkQuest)


  }, [linkQuest])



  useEffect(() => {

    let ignore = false;
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { 
          global.divLoad = global.divLoad + 1;
          eleLoading.style.display = '';
         };
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('dtInicioPesqAgenda', global.dtInicioPesqAgenda);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        //url: global.gLinkBase + '/appyou/rest.php?apikey=398738379696650578637067567569346',
        url: 'https://api.espacomarciobagagine.com.br/listaAgenda.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })


      if (!ignore) {
        const redata = response['data'];
        //cl('redata', redata);

        setrep(redata);
        setEventosPRE(redata);

        if (eleLoading) { eleLoading.style.display = 'none' };
      }
    }
    fetchdata();
    setClientesPre({ '0': '0' });
    if (global.btnCriados === 0) {
      bynsNavegs();
    }
    return () => { ignore = true };
  }, [Atu])


  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [reprop])

  useEffect(() => {

    if (isFirstRunEventosPRE.current) {
      isFirstRunEventosPRE.current = false;
      return;
    }

    //cl('events', events);
    //cl('eventsPRE', EventosPRE);
    var i;
    const result = [];
    for (i = 0; i < EventosPRE.length; i++) {
      //loopData += `<li>${data[i].name}</li>`
      let startDateJS = EventosPRE[i].start.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
      startDateJS[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

      let endDateJS = EventosPRE[i].end.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
      endDateJS[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

      let tipos = ['aguardando', 'aguardandodmais', 'inicial', 'atrasado', 'faltou', 'finalizado', 'atendimento'];

      let rand = Math.floor(Math.random() * tipos.length);

      let valor = [{
        id: EventosPRE[i].id,
        title: EventosPRE[i].title,
        start: new Date(...startDateJS),
        end: new Date(...endDateJS),
        resourceId: parseInt(EventosPRE[i].resourceId),
        resourceTitle: EventosPRE[i].resourceTitle,
        services: EventosPRE[i].servicos,
        jservices: EventosPRE[i].jservicos,
        obs: EventosPRE[i].obs,
        idcaixamov: EventosPRE[i].idcaixamov,
        type: EventosPRE[i].status,//tipos[rand]
        fone: EventosPRE[i].fone,
        email: EventosPRE[i].email,
        idcli: EventosPRE[i].idcli,
        user: EventosPRE[i].user,
        usercreated: EventosPRE[i].usercreated
      }];
      result.push(valor[0]);
    };
    //cl('result', result);

    setEventos(result);

    for (i = 0; i < result.length; i++) {

      //if((moment(new Date().getTime()).diff(result[i].start, 'days')) === 0){cl("diferença dias", moment(new Date().getTime()).diff(result[i].start, 'days'));}
      //cl("diferença horas", moment(slotInfo.end).diff(slotInfo.start, 'hours'));
      //cl("diferença minutos", moment(slotInfo.end).diff(slotInfo.start, 'minutes'));

      let customId = result[i].id;
      let txtmsg = [{
        faltou: 'não veio !',
        atrasado: 'ainda não chegou ?',
        aguardando: 'já esta aguardando !',
        aguardandodmais: 'esta esperando por muito tempo !'
      }];

      //cl(txtmsg[0].faltou);
      let msg = result[i].title + ' ' + txtmsg[0][result[i].type];


      if (((result[i].type === 'faltou') || (result[i].type === 'atrasado') || (result[i].type === 'aguardando') || (result[i].type === 'aguardandodmais')) && ((moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYY')) === moment(result[i].start, 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYY'))) {

        if ((result[i].type === 'faltou') || (result[i].type === 'aguardandodmais')) {

          let ttime = 60000;
          if (result[i].type === 'faltou') {

            ttime = 2000;

          } else {

            ttime = 90000;

          };

          /*           toast.error(msg, {
                      toastId: customId,
                      position: "bottom-left",
                      autoClose: ttime,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }); */
        }
        if (result[i].type === 'atrasado') {

          toast.warn(msg, {
            toastId: customId,
            position: "bottom-left",
            autoClose: 60000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (result[i].type === 'aguardando') {

          toast.info(msg, {
            toastId: customId,
            position: "bottom-left",
            autoClose: 60000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

    }

  }, [EventosPRE])

  useEffect(() => {

    if (isFirstRunEventos.current) {
      isFirstRunEventos.current = false;
      return;
    }

    //cl("Eventos",Eventos)


  }, [Eventos])

  useEffect(() => {

    if (isFirstRunReservaAgenda.current) {
      isFirstRunReservaAgenda.current = false;
      return;
    }
    //cl("ReservaAgenda",ReservaAgenda)
    const { idcli, idprof, start, end, status, servicos, user, newCliente, nomeCli, foneCli, emailCli, jservicos, obs } = ReservaAgenda


    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('idcli', idcli);
      bodyFormData.set('idprof', idprof);
      bodyFormData.set('start', start);
      bodyFormData.set('servicos', servicos);
      bodyFormData.set('end', end);
      bodyFormData.set('status', status);
      bodyFormData.set('obs', obs);
      bodyFormData.set('user', user);
      bodyFormData.set('usercreated', user);
      bodyFormData.set('jservicos', JSON.stringify(jservicos));
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/AgendaReserva.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("resultagendareserva",response['data'])
        //(nomeCli,foneCli,2,start)
        if (response['data']['id']) {
          //cl("idagenda",response['data']['id'])
          if (status === 'inicial') {
            modalEnviaAgenda(nomeCli, foneCli, 2, start);
          }
        }


        setAtu({ '0': '0' });


      };


      if (!ignore) {
      }
    }

    async function criarcliente() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('nomeCli', nomeCli);
      bodyFormData.set('foneCli', foneCli);
      bodyFormData.set('emailCli', emailCli);
      bodyFormData.set('user', user);
      bodyFormData.set('usercreated', user);
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/Cli.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("criarCli", response['data'])
        global.ServicoDescricao = '';

        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }


    if (newCliente === 1) {

      ////cl("criar novo cliente")
      criarcliente();

    } else {
      fetchdata();
    }
    return () => { ignore = true };

  }, [ReservaAgenda])

  useEffect(() => {

    if (isFirstRunNewCli.current) {
      isFirstRunNewCli.current = false;
      return;
    }
    //cl("ReservaAgenda",ReservaAgenda)
    const { idcli, idprof, start, end, status, servicos, jservicos, user, newCliente, nomeCli, foneCli, emailCli } = NewCli

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('nomeCli', nomeCli);
      bodyFormData.set('foneCli', foneCli);
      bodyFormData.set('emailCli', emailCli);
      bodyFormData.set('user', user);
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/Cli.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        global.idCliente = response['data'][0]['id'];

        ////cl("criarCliOk", global.idCliente)

        global.newCliente = 0;
        ////cl("global.newCliente", global.newCliente);
        // setAtu({ '0': '0' });
        idprof, start, end, status, user, newCliente, nomeCli, foneCli, emailCli
        setReservaAgenda(
          {
            'idcli': global.idCliente,
            'idprof': idprof,
            'start': start,
            'end': end,
            'status': status,
            'servicos': servicos,
            'jservicos': jservicos,
            'user': global.gIdUser,
            'newCliente': global.newCliente,
            'nomeCli': nomeCli,
            'foneCli': foneCli
          });


      };


      if (!ignore) {
      }
    }

    fetchdata();

    return () => { ignore = true };

  }, [NewCli])


  useEffect(() => {

    if (isFirstRunAgendaStatus.current) {
      isFirstRunAgendaStatus.current = false;
      return;
    }

    const { id, cli, prof, status, user } = AgendaStatus

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('id', id);
      bodyFormData.set('idcli', cli);
      bodyFormData.set('idprof', prof);
      bodyFormData.set('status', status);
      bodyFormData.set('user', user);
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/AgendaStatus.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        ////cl("result", response)
        setAtu({ '0': '0' });
        if (status === 'aguardando') {

          confirmAltStatus(global.EventoId, 'atendimento')

        } else {

          CloseadPAction();
        }
      };


      if (!ignore) {
      }
    }
    if (global.gAgendaStatus === 1) {

      fetchdata();
      global.gAgendaStatus = 0;

    } else { return };
    return () => { ignore = true };

  }, [AgendaStatus])

  useEffect(() => {

    if (isFirstRunAgendaAlt.current) {
      isFirstRunAgendaAlt.current = false;
      return;
    }

    const { id, idprof, cli, start, end, status, user, servicos, jservicos, obs } = AgendaAlt

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('id', id);
      bodyFormData.set('idprof', idprof);
      bodyFormData.set('cli', cli);
      bodyFormData.set('start', start);
      bodyFormData.set('end', end);
      bodyFormData.set('status', status);
      bodyFormData.set('user', user);
      bodyFormData.set('servicos', servicos);
      bodyFormData.set('obs', obs);
      bodyFormData.set('jservicos', JSON.stringify(jservicos));
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/AgendaAlt.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        ////cl("result", response)
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }

    setAtuLancamentos({ idagenda: global.gidAgenda })
    if (global.gAgendaAlt === 1) {

      fetchdata();
      global.gAgendaAlt = 0;

    } else { return };
    return () => { ignore = true };

  }, [AgendaAlt])

  useEffect(() => {

    if (isFirstRunEventoCancelar.current) {
      isFirstRunEventoCancelar.current = false;
      return;
    }

    const { id, user } = EventoCancelar

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfcidcli');
      bodyFormData.set('id', id);
      bodyFormData.set('user', user);
      bodyFormData.set('status', 'cancelado');
      const response = await axios({
        method: 'post',

        url: 'https://api.espacomarciobagagine.com.br/EventoCancelar.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        ////cl("result", response)
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }

    ////cl('gEventoCancelar', global.gEventoCancelar)
    if (global.gEventoCancelar === 1) {

      fetchdata();
      global.gEventoCancelar = 0;

    } else { return };
    return () => { ignore = true };

  }, [EventoCancelar])


  //Funções

  global.formAddNewContactReset = function (params) {
    document.getElementById("formAddNewContact").reset();
    const idcampos = [
      'inputNome',
      'inputCid',
      'inputEmail4',
      'inputFone',
      'inputObs'
    ]
    for (var i = 0; i < idcampos.length; i++) {
      let eNnome = document.getElementById(idcampos[i])
      eNnome.classList.remove("is-valid")
      eNnome.classList.remove("is-invalid")
    }
  }

  function editLanc(id, valor, qtd, descricao, idagenda) {


    // cl(id, valor, qtd);


    confirmAltLancInsumo(id, valor, qtd, descricao, idagenda);

    let einputMQtd = document.getElementById('inputMQtd');
    einputMQtd.value = qtd;
    let einputMValor = document.getElementById('inputMValor');
    einputMValor.value = valor;
    /*       setEditLancamentos({
            id: 126,
            valor: "400,00",
            qtd: 1
          })
    
          setAtuLancamentos({ idagenda: global.gidAgenda }) */

  }

  function apagaLanc(id, valor, qtd, descricao, idagenda) {


    // cl(id, valor, qtd);


    confirmApagaLanc(id, valor, qtd, descricao, idagenda);



  }


  function bViewPdf(xcond, xpasta, xfile) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );
    //cl("alterar")
    let eleIapikey = document.getElementById('iapikey');
    eleIapikey.value = "39873837969665057863706756756934"
    let eleIcond = document.getElementById('icond');
    eleIcond.value = xcond
    let eleIpasta = document.getElementById('ipasta');
    eleIpasta.value = xpasta
    let eleIarqv = document.getElementById('iarqv');
    eleIarqv.value = xfile
    let eleFvdocs = document.getElementById('formVdocs');

    //eleFvdocs.submit();

    eleIapikey.value = ""
    eleIcond.value = ""
    eleIpasta.value = ""
    eleIarqv.value = ""


  }

  function modalBase(h1, p, p2, focus, inputquery) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{h1}</h4>
            </div>
            <div className="modal-body">
              <p>{p}</p>
            </div>
            <div className="modal-body">
              <p>{p2}</p>
            </div>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button id="btnModal"
              className="btn bg-gradient-info float-right"
              onClick={() => {
                if (focus) {
                  document.getElementById(focus).focus();
                  // cl("focus")
                }
                if (inputquery) {
                  let inputM = document.querySelectorAll('input');
                  //cl("inputmodal",inputM)
                  inputM[inputquery].focus();
                }

                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div >
        );
      }
    });
  }

  function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  function modalEnviaAgenda(nome, fone, newagenda, start) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirmação do Agendamento</h4>
            </div>
            <div className="modal-body">
              <p>Vamos nos prevenir, quer enviar a confirmação do agendamento via WhatsApp agora ?</p>
            </div>
            <div className="modal-body">
              <p>{nome}, {fone}</p>
              <p>Você também pode enviar depois!</p>
            </div>
            <Hotkeys
              keyName="n"
              onKeyDown={onClose}
            //onKeyUp={novoContato}
            />
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>(N)ão</button>
            <Hotkeys
              keyName="s"
              onKeyDown={() => {
                zapConfirmAgenda(nome, fone, newagenda, start);
                //CloseadPAction();
                onClose();
              }}
            //onKeyUp={novoContato}
            />
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                zapConfirmAgenda(nome, fone, newagenda, start);
                //CloseadPAction();
                onClose();
              }}
            >
              (S)im, enviar!
            </button>
          </div>
        );
      }
    });
  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputCliente')
    const nome = eNnome.value;
    ////cl("inputClientevalue", nome)
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputCliente = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaRange(params) {

    let TabSel = document.getElementById('custom-tabs-five-tab')
    let TabSolActive = TabSel.querySelector('.active').innerHTML

    if (TabSolActive === 'Reservar Horário') {

      const einputDtIni = document.getElementById('inputDtIni')
      const einputDtFim = document.getElementById('inputDtFim')
      let dtini = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss');
      let dtinidia = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss').format('DD');
      let dtfim = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss');
      let dtfimdia = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss').format('DD');
      /*       let dtagora = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
            let dtagoradia = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('DD');
            var duration = moment.duration(dtfim.diff(dtini));
            var calcdia1 = moment.duration(dtini.diff(dtagora));
      
            let duracao = duration.asHours();
            let duracaoday = duration.asDays();
            let dia1 = calcdia1.asMinutes(); */
      //mudança permição data e hora minima
      let dtagoradiabase = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD 04:00');
      let dtagora = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
      let dtagorabase = moment(dtagoradiabase, 'YYYY-MM-DD HH:mm:ss');
      var duration = moment.duration(dtfim.diff(dtini));
      var calcdia1 = moment.duration(dtini.diff(dtagora));
      var calcdia1base = moment.duration(dtini.diff(dtagorabase));
      //cl("dtagoradiabase",dtagoradiabase)
      //cl("dtagora",dtagora)
      //cl("dtagorabase",dtagorabase)
      //cl("calcdia1",calcdia1.asMinutes())
      //cl("calcdia1base",calcdia1base.asMinutes())
      //return
      let duracao = duration.asHours();
      let duracaoday = duration.asDays();
      let dia1 = calcdia1.asMinutes();
      let dia1base = calcdia1base.asMinutes();

      //cl("range", duracao)
      //cl("dia1", dia1)
      //cl("duracaoday", duracaoday)
      //cl("dtinidia", dtinidia)
      //cl("dtfimdia", dtfimdia)

      if (dia1base < 0) {
        einputDtIni.classList.remove("is-valid")
        einputDtIni.classList.add("is-invalid")
        global.inpAddFrmC.inputDtIniReserva = false;
        //modalBase("Atenção", "Data e Hora Inicial não pode ser menor que Agora !!!");
        window.alert("A data e Hora Inicial da reserva não pode ser menor que Hoje apartir das 06hs !!!");
        einputDtIni.focus();
        return;
      } else {
        einputDtIni.classList.remove("is-invalid")
        einputDtIni.classList.add("is-valid")
        global.inpAddFrmC.inputDtIniReserva = true;
      }

      if ((duracao > 0) && (dtinidia === dtfimdia)) {
        einputDtFim.classList.add("is-valid")
        einputDtFim.classList.remove("is-invalid")
        global.inpAddFrmC.inputDtFimReserva = true;
      } else if ((duracao < 0) || (dtinidia !== dtfimdia)) {
        einputDtFim.classList.remove("is-valid")
        einputDtFim.classList.add("is-invalid")
        global.inpAddFrmC.inputDtIniReserva = false;
        einputDtFim.focus();
        window.alert("O Dia ou Horário Final da reserva, não podem ser menor que o Inicial ou para o dia seguinte!!!");
        //modalBase("Atenção", "O Dia ou Horário Final, não podem ser menor que o Inicial ou para dia seguinte!!!");
        return;
      }

    }


    if (TabSolActive === 'Bloquear Agenda') {

      const einputDtIni = document.getElementById('inputDtIniBloquear')
      const einputDtFim = document.getElementById('inputDtFimBloquear')
      let dtini = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss');
      let dtinidia = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss').format('DD');
      let dtfim = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss');
      let dtfimdia = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss').format('DD');
      let dtagora = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
      let dtagoradia = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('DD');
      var duration = moment.duration(dtfim.diff(dtini));
      var calcdia1 = moment.duration(dtini.diff(dtagora));

      let duracao = duration.asHours();
      let duracaoday = duration.asDays();
      let dia1 = calcdia1.asMinutes();


      //cl("range", duracao)
      //cl("dia1", dia1)
      //cl("duracaoday", duracaoday)
      //cl("dtinidia", dtinidia)
      //cl("dtfimdia", dtfimdia)

      if (dia1 < 0) {
        einputDtIni.classList.remove("is-valid")
        einputDtIni.classList.add("is-invalid")
        global.inpAddFrmC.inputDtIniBloquear = false;
        window.alert("Data e Hora Inicial não pode ser menor que Agora !!!");
        einputDtIni.focus();
        return;
      } else {
        einputDtIni.classList.remove("is-invalid")
        einputDtIni.classList.add("is-valid")
      }

      if ((duracao > 0) && (dtinidia === dtfimdia)) {
        einputDtFim.classList.add("is-valid")
        einputDtFim.classList.remove("is-invalid")
        global.inpAddFrmC.inputDtFimBloquear = true;
      } else if ((duracao < 0) || (dtinidia !== dtfimdia)) {
        einputDtFim.classList.remove("is-valid")
        einputDtFim.classList.add("is-invalid")
        global.inpAddFrmC.inputDtIniBloquear = false;
        einputDtFim.focus();
        window.alert("O Dia ou Horário Final, não podem ser menor que o Inicial ou para dia seguinte!!!");
        return;
      }

    }
  }

  function ChangeAddProd(params) {

    //idagenda, idproduto, cliente, valor, qtd } = AddLanc
    let eSelectServ = document.getElementById('SelectServ');
    let eiQtdInsumo = document.getElementById('iQtdInsumo');

    //cl(params)
    //cl("eSelectServ.value", eSelectServ.value)


    if (eSelectServ.value === '0') {
      //cl('entrou')
      //window.alert('Selecione para qual serviço deseja Lançar o produto');

      modalBase("Atenção", "Primeiro, selecione para qual serviço deseja Lançar o produto para não descontar o insumo do serviço errado!", "", "SelectServ");
      eSelectServ.focus();
      return

    }

    if ((eiQtdInsumo.value === '0') || (eiQtdInsumo.value === '') || (eiQtdInsumo.value === 0)) {
      //cl('entrou')
      //window.alert('Selecione para qual serviço deseja Lançar o produto');

      modalBase("Atenção", "Qual a quantidade do produto ?", "", "iQtdInsumo");
      eiQtdInsumo.focus();
      return

    }

    //cl("global.gidAgenda",AtuLancInsumos['idagenda'])

    let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
    if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = ''; }

    setAddLanc({
      idagenda: AtuLancInsumos['idagenda'],
      qtd: eiQtdInsumo.value,
      idproduto: params[0].value,
      valor: realParaNumber(params[0].valor),
      serv: eSelectServ.value
    })

  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function gerContratoLink(params) {

    window.location.href = "#/contratos-gerenciar";


  }
  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }
  function validaAssunto(params) {
    const eNnome = document.getElementById('inputAssunto')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputAssunto = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);


  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }
  function validaEmail6(params, idinput) {
    const eNemail = document.getElementById('inputEmail6')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail6 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email && params !== 1) { eNemail.value = params.toLowerCase() };

  }


  function formataCnpj(Cnpj) {
    //retira os caracteres indesejados...
    Cnpj = Cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
    return Cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }


  function clicopied(params) {
    let ele = document.getElementById("txtLinkQuest")
    ele.textContent = "Link Copiado, basta colar..."

  }

  function calculaDias(date1, date2) {
    //formato do brasil 'pt-br'
    moment.locale('pt-br');
    //setando data1
    var data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    var data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    var diff = data2.diff(data1, 'days');

    return diff;
  }

  const ref = null;

  // function testeModal(params) {

  function confirmCancelamento(id, user) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Alerta - Tente Reagendar</h4>
            </div>
            <div className="modal-body">
              <p>Tem certeza que deseja realizar o Cancelamento ?</p>
            </div>
            <div className="modal-body">
              <p>Tente reagendar com o cliente antes de Cancelar, caso continue será irreversível!</p>
            </div>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                global.gEventoCancelar = 1;
                setEventoCancelar(
                  {
                    'id': id,
                    'user': global.gIdUser
                  }

                );

                CloseadPAction();
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function confirmAltStatus(id, status) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let st = '';
    let ti = '';
    if (status === 'aguardando') {
      st = "O cliente Chegou ?";
      ti = "Alteração de Status";
    }
    if (status === 'atendimento') {
      st = "Já inicio o Atendimento ?";
      ti = "Alteração de Status";
    }
    if (status === 'finalizado') {
      st = "Deseja finalizar este atendimento ?";
      ti = "Alteração de Status";
    }
    if (status === 'inicial') {
      st = "Deseja Resetar o Status para o inicial ?";
      ti = "Atenção";
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{ti}</h4>
            </div>
            <div className="modal-body">
              <p>{st}</p>
            </div>
            <div className="modal-body">
              <p></p>
            </div>
            <button className="btn bg-gradient-info btn-sm" onClick={() => {
              onClose();
              CloseadPAction();
            }}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                ACliStatus(id, status);
                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }

  function confirmAltLanc(id, valor, qtd, descricao) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;


    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{descricao}</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Qtd.</label>
                <div className="input-group-prepend">
                  <input type="number" className="form-control" autoComplete="off" id="inputMQtd" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autoComplete="off" id="inputMValor" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');


                setEditLancamentos({
                  id: id,
                  valor: einputMValor.value,
                  qtd: einputMQtd.value
                })

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }
  function confirmApagaLanc(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Excluirr ?</h4>
            </div>
            <div className="form-row">
              <p>{descricao}</p>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');
                let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
                if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = ''; }


                setApagarLancamentos({
                  id: id,
                  idagenda: idagenda
                })

                onClose();
              }}
            >
              Sim, apague!
            </button>
          </div>
        );
      }
    });
  }

  function confirmAltLancInsumo(id, valor, qtd, descricao) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{descricao}</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Qtd.</label>
                <div className="input-group-prepend">
                  <input type="number" className="form-control" autocomplete="off" id="inputMQtd" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValor" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');
                let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
                if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = ''; }

                setEditLancInsumos({
                  id: id,
                  valor: einputMValor.value,
                  qtd: einputMQtd.value
                })

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }


  function NumberProp(params) {

    let idprop = dateFormat(Date.now(), "ddmmHHMMss/yy");
    return idprop;

  }

  function GeraLinkAV(id_av, cond, bl, unid, av_desc, av_datainit, av_datav, av_dataf, reenviar, nomecond, id_btn) {
    let key = global.makeChave(20);
    //cl("id_btn gera",id_btn);
    setsolicitaChaveAV(
      {
        'key': key,
        'cond': cond,
        'bl': bl,
        'unid': unid,
        'email': global.gEmail,
        'cpf': global.gDoc,
        'id_av': id_av,
        'av_desc': av_desc,
        'av_data': av_datainit,
        'av_datav': av_datav,
        'av_dataf': av_dataf,
        'reenviar': reenviar,
        'nomecond': nomecond,
        'id_btn': id_btn
      }
    );
    //cl("key",key);

  }

  // function numberParaReal(numero) {
  //   var formatado = "R$ " + numero.toFixed(2).replace('.', ',');
  //   return formatado;
  // }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  // function TeclaEnter(params) {

  // }


  function CalcSimule(params) {

    let eleDtIni = document.getElementById('inputDtIni');
    let eleDtFim = document.getElementById('inputDtFim');

    let eleDifDias = document.getElementById('inputDifDias');
    let difDias = calculaDias(dateFormat(eleDtIni.value, "dd/mm/yyyy"), dateFormat(eleDtFim.value, "dd/mm/yyyy"));
    eleDifDias.value = difDias;
    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let M30 = Math.floor(difDias / 30);
    eleMeses30.value = M30;

    let eleTUnids = document.getElementById('inputTUnids');
    let TUnids = eleTUnids.value;

    let eleTDiasPasta = document.getElementById('inputTDiasPasta');
    let TDiasPasta = eleTDiasPasta.value;
    let elePrazo = document.getElementById('inputPrazo');
    let Prazo = (TDiasPasta * M30);
    Prazo = Math.round(Prazo);
    elePrazo.value = Prazo;

    let eleVUnit = document.getElementById('inputVUnit');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleVTotal = document.getElementById('inputVTotal');
    let VTotal = (M30 * Vunit);
    eleVTotal.value = VTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let elePorUnids = document.getElementById('inputPorUnids');
    let PorUnids = (VTotal / TUnids);
    elePorUnids.value = PorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleQParc = document.getElementById('inputQParc');
    let QParc = eleQParc.value;
    let eleVParc = document.getElementById('inputVParc');
    let VParc = (VTotal / QParc);
    eleVParc.value = VParc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPorUnids = document.getElementById('inputParcPorUnids');
    let ParcPorUnids = (VParc / TUnids);
    eleParcPorUnids.value = ParcPorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleTDas = document.getElementById('inputTDas');
    let TDas = (VTotal * 0.06);
    eleTDas.value = TDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcDas = document.getElementById('inputParcDas');
    let ParcDas = (TDas / QParc);
    eleParcDas.value = ParcDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleTPrc = document.getElementById('inputTPrc');
    let TPrc = (VTotal * 0.20);
    eleTPrc.value = TPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPrc = document.getElementById('inputParcPrc');
    let ParcPrc = (TPrc / QParc);
    eleParcPrc.value = ParcPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleLucro = document.getElementById('inputLucro');
    let Lucro = (VTotal - (TDas + TPrc));
    eleLucro.value = Lucro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });


  }

  function CalcDias(params) {

    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let elePrazo = document.getElementById('inputPrazo');
    let eleTDias = document.getElementById('inputTDiasPasta');
    eleTDias.value = (elePrazo.value / eleMeses30.value);



  }

  function rowClick(e, row) {
    ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
    //cl("rowClick id: " + row.getData().id, row, e);
    ////cl(rep);

  };

  function novoContato(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

  }

  function enviarEmail(assunto, email, txtmsg) {
    validaEmail6(document.getElementById('inputEmail6').value, 'inputEmail6')
    if (!global.inpAddFrmC.inputEmail6) { return validaEmail6(1, 'inputEmail6') }
    //cl("enviar email global.inpAddFrmC.inputEmail6 > ", global.inpAddFrmC.inputEmail6)
    //cl("enviar email global.inpAddFrmC > ", global.inpAddFrmC)
    if (global.inpAddFrmC.inputEmail6) {
      setsendMsgEmail(
        {
          'assunto': document.getElementById('inputAssunto').value,
          'email': document.getElementById('inputEmail6').value,
          'txtmsg': document.getElementById('txtEmail').value,
        }

      );
      let ele = document.getElementById('panelAgendar');
      ele.style.display = 'none';
      let eleCard = document.getElementById('cardGridBgg');
      eleCard.classList.remove("collapsed-card");
      let eleCardI = document.getElementById('cardGridBggI');
      eleCardI.classList.remove("fa-plus");
      eleCardI.classList.add("fa-minus");
      let eleCardbody = document.getElementById('cardGridBggBody');
      eleCardbody.style.display = '';

      //cl("enviou email")
    }
  }

  function ViewAgenda(params) {
    moment.updateLocale('pt-br', {
      week: {
        dow: 1,
        //doy: 1,
      },
    });
    const localizer = momentLocalizer(moment);

    let horaAtual = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('HH');
    var dataAtual = moment(new Date());
    if (horaAtual >= 21) {

      dataAtual = moment().add(1, 'days');

    }



    const views = ['day', 'week', 'agenda'];

    const formats = {
      dayFormat: (date, culture, localizer) =>
        localizer.format(date, 'ddd DD/MM', culture),
      /*   dateFormat: (date, culture, localizer) =>
      localizer.format(date, 'D', culture), */
      dayHeaderFormat: (date, culture, localizer) =>
        localizer.format(date, 'dddd, DD MMMM YYYY', culture),
      agendaDateFormat: (date, culture, localizer) =>
        localizer.format(date, 'dddd, DD MMMM YYYY', culture),

    };

    function onDoubleClickEvent(params) {

      window.alert('ok');

    }

    function scrollDivagenda() {


      const divage = document.getElementsByClassName('rbc-time-content')
      divage[0].scrollLeft += 200;

    }




    function onSelectSlot(slotInfo) {
      const input = document.querySelectorAll("input");
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';

      //cl("slot", slotInfo)

      global.idCliente = 0;
      global.ServicoDescricao = '';

      setsearchString('a') //forçar troca sempre
      setsearchString('')
      setServicosSelected();
      //setsearchServicos('a');
      setsearchServicos(null);
      input[0].value = '';

      let ecardAgenda = document.getElementById('cardAgenda');
      ecardAgenda.style.display = 'none';




      const pp = document.getElementById('panelAction');
      if (pp) { pp.style.display = 'none'; };

      const eNnome = document.getElementById('panelAgendar')
      eNnome.style.display = '';

      const eTitle = document.getElementById('headCEmail')
      global.idProf = slotInfo['resourceId'];

      var arrFound = Profis.filter(function (item, num) {
        return item.resourceId === slotInfo['resourceId'];
      });

      //cl('arrFound',arrFound[0]);

      global.gProfissional = arrFound[0]['resourceTitle'];

      eTitle.innerHTML = "Agendandar com Profissional " + global.gProfissional

      //cl("diferença dias", moment(slotInfo.end).diff(slotInfo.start, 'days'));
      //cl("diferença horas", moment(slotInfo.end).diff(slotInfo.start, 'hours'));
      //cl("diferença minutos", moment(slotInfo.end).diff(slotInfo.start, 'minutes'));

      let eleDtIni = document.getElementById('inputDtIni');
      eleDtIni.value = moment(slotInfo.start).format("YYYY-MM-DDTHH:mm");
      let eleDtFim = document.getElementById('inputDtFim');
      eleDtFim.value = moment(slotInfo.end).format("YYYY-MM-DDTHH:mm");
      let eleDtEspera = document.getElementById('inputDtEspera');
      eleDtEspera.value = moment(slotInfo.start).format("YYYY-MM-DD");
      let eleDtIniBloquear = document.getElementById('inputDtIniBloquear');
      eleDtIniBloquear.value = moment(slotInfo.start).format("YYYY-MM-DDTHH:mm");
      let eleDtFimBloquear = document.getElementById('inputDtFimBloquear');
      eleDtFimBloquear.value = moment(slotInfo.end).format("YYYY-MM-DDTHH:mm");

      let tabnameR = document.getElementById('tab-agenda-reserva')
      let tabnameL = document.getElementById('tab-agenda-lista')
      let tabnameRc = document.getElementById('tab-agenda-reserva-conteudo')
      let tabnameLc = document.getElementById('tab-agenda-lista-conteudo')

      if (moment(slotInfo.end).diff(slotInfo.start, 'days') > 0) {
        tabnameR.classList.remove('active');
        tabnameL.classList.add('active');
        tabnameRc.classList.remove('active');
        tabnameRc.classList.remove('show');
        tabnameLc.classList.add('active');
        tabnameLc.classList.add('show');
      } else {
        tabnameL.classList.remove('active');
        tabnameR.classList.add('active');
        tabnameLc.classList.remove('active');
        tabnameLc.classList.remove('show');
        tabnameRc.classList.add('active');
        tabnameRc.classList.add('show');
      }
      let inpfone = document.getElementById('inputFone');
      if (inpfone.value) {

        inpfone.value = '';
        validaFone('inputFone')
      }

      /*       let inpemail = document.getElementById('inputEmail6');
            if (inpemail.value) {
              inpemail.value = '';
              validaEmail6(inpemail.value)
      
            } */

      //cl(input)

      input[0].focus();

      scroll.scrollToTop();



      //window.alert(JSON.stringify(slotInfo));



    }

    function checkMultArray(array, key, value) {
      if (array.some(object => object[key] === value)) {
        return true
      } else {
        return false
      }
    }

    function onSelectEvent(calEvent) {
      ////cl('calEvent',calEvent);

      let ehorarioReserva = document.getElementById('horarioReserva');
      ehorarioReserva.style.display = '';

      let ealterarEbotoes = document.getElementById('alterarEbotoes');
      ealterarEbotoes.style.display = '';

      let edivReactTabulator = document.getElementById('divReactTabulator');
      edivReactTabulator.style.display = 'none';

      let eSelectservAlt = document.getElementById('SelectservAlt');
      eSelectservAlt.style.display = '';

      global.EventoId = calEvent['id'];
      global.StatusAtu = calEvent['type'];
      global.idCliente = calEvent['idcli'];
      global.idProf = calEvent['resourceId'];
      global.ServicoDescricao = calEvent['services'];
      //global.idCaixaMov = calEvent['idcaixamov'];
      //cl('global.idCaixaMov', global.idCaixaMov);
      let ecardAgenda = document.getElementById('cardAgenda');
      ecardAgenda.style.display = 'none';

      var arrFound2 = Profis.filter(function (item, num) {
        return item.resourceId === calEvent['resourceId'];
      });

      //cl('arrFound',arrFound[0]);

      global.gProfissional = arrFound2[0]['resourceTitle'];

      //let profisional = Profis[(calEvent['resourceId'] - 1)]['resourceTitle'];

      let eheadCAcoes = document.getElementById('headCAcoes');
      eheadCAcoes.innerHTML = 'Agenda - Ações - Profissional ' + global.gProfissional + ' - Cliente ' + calEvent['title'];

      let ebyuser = document.getElementById('byuser');
      ebyuser.innerHTML = ' (<b> ' + calEvent['id'] + '</b> )' + ' criado por <b>' + calEvent['usercreated'] + '</b><br> ultima ateração feita por   <b>' + calEvent['user'] + '</b>';


      let agendamento = "Início em " + (dateFormat(Date.parse(calEvent['start'].toString()), "dd/mm/yyyy hh:MM")) + "h" + " às  " + (dateFormat(Date.parse(calEvent['end'].toString()), "hh:MM")) + "h";
      let serv = "Serviços : " + calEvent['services'];
      /*     //cl(isSafari)
          //cl(isChrome) */



      //window.alert(JSON.stringify(calEvent));

      let elabelEventoEdit = document.getElementById('labelEventoEdit');


      if (calEvent['type'] === 'espera') {
        //modalBase("Esta na lista de espera =>  " + profisional, agendamento, serv);
        //elabelEventoEdit.innerHTML = "Esta na lista de espera =>  " + profisional + " - " + agendamento;

      } else {
        //modalBase("Horário Reservado  " + profisional, agendamento, serv);
        //elabelEventoEdit.innerHTML = "Horário Reservado  " + profisional + " - " + agendamento;

      }
      const pppp = document.getElementById('panelAgendar')
      if (pppp) { pppp.style.display = 'none'; }

      const eNnome = document.getElementById('panelAction')
      eNnome.style.display = '';

      const einputNameEdit = document.getElementById('inputNameEdit');
      einputNameEdit.value = calEvent['title'];
      const einputTelEdit = document.getElementById('inputTelEdit');
      einputTelEdit.value = calEvent['fone'];
      const einputObsEdit = document.getElementById('inputObsEdit');
      einputObsEdit.value = calEvent['obs'];

      let eleDtIni = document.getElementById('inputDtIniEdit');
      eleDtIni.value = moment(calEvent.start).format("YYYY-MM-DDTHH:mm");
      let eleDtFim = document.getElementById('inputDtFimEdit');
      eleDtFim.value = moment(calEvent.end).format("YYYY-MM-DDTHH:mm");

      var eleSelect2 = document.getElementById('selectProf');
      eleSelect2.innerHTML = '';

      for (var i = 0; i < Profis.length; i++) {

        let opt3 = document.createElement('option');
        opt3.value = Profis[i].resourceId;
        opt3.innerHTML = Profis[i].resourceTitle;
        eleSelect2.appendChild(opt3);

      }
      eleSelect2.value = calEvent.resourceId;
      //cl("calEventservicesj", calEvent.jservices)

      if ((calEvent['type'] !== 'bloqueado') && (calEvent.jservices)) {
        let eSelectservAlt = document.getElementById('SelectservAlt');
        eSelectservAlt.style.display = '';
        let ebtnsFinalizar = document.getElementById('btnsFinalizar');
        ebtnsFinalizar.style.display = '';

        setServicosSelected(JSON.parse(calEvent.jservices));
        setsearchServicos(JSON.parse(calEvent.jservices));
        const list = (JSON.parse(calEvent.jservices));
        global.ServicoDescricao = '';

        if (global.gServicosSel) {
          // cl("global.gServicosSel",global.gServicosSel);
          // cl('list', list);
          for (i = 0; i < global.gServicosSel.length; i++) {
            //cl("FOR ___________________", i)

            //cl("global.gServicosSel.id", global.gServicosSel[i].id)

            if (checkMultArray(list, "id", global.gServicosSel[i].id)) {
              // cl("econtrouuuuucheckMultArray", global.gServicosSel[i].descricao)

              if (global.ServicoDescricao.length === 0) {
                global.ServicoDescricao = global.gServicosSel[i].descricao;
              } else {
                global.ServicoDescricao = global.ServicoDescricao + ', ' + global.gServicosSel[i].descricao;
              }
              //cl("global.ServicoDescricao", global.ServicoDescricao)
            }

          };
          //cl('global.ServicoDescricao', global.ServicoDescricao);
        }


        /* const descServices = calEvent.services.split(', ');
      //cl("descServices", descServices) */


        /*       for (i = 0; i < ServicosOpt.length; i++) {
                //cl("FOR ___________________", i)
                //cl("global.gServicosSel.id", global.gServicosSel[i].id)
        
        
              } */

      } else {
        let eSelectservAlt = document.getElementById('SelectservAlt');
        eSelectservAlt.style.display = '';
      }

      let bbtnCliChegou = document.getElementById('btnCliChegou')
      let bbtnCliInitAte = document.getElementById('btnCliInitAte')
      let bbtnCliFinalizar = document.getElementById('btnCliFinalizar')
      let bbtnAlterar = document.getElementById('btnAlterar')
      let bbtnCancelamento = document.getElementById('btnCancelamento')
      let bbtnWhatsApp = document.getElementById('btnWhatsApp')
      let bbtnWhatsConf = document.getElementById('btnWhatsConf')
      let ebtnCliNaoFaltou = document.getElementById('btnCliNaoFaltou');
      let bbtnReset = document.getElementById('btnReset');


      if (calEvent['type'] === 'inicial' || calEvent['type'] === 'atrasado') {
        eleDtIni.disabled = false;
        eleDtFim.disabled = false;
        einputObsEdit.disabled = false;
        eleSelect2.disabled = false;
        bbtnCliChegou.style.display = '';
        bbtnReset.style.display = 'none';
        bbtnCliInitAte.style.display = 'none';
        bbtnCliFinalizar.style.display = 'none';
        bbtnAlterar.style.display = '';
        bbtnCancelamento.style.display = '';
        bbtnWhatsApp.style.display = '';
        bbtnWhatsConf.style.display = '';
        ebtnCliNaoFaltou.style.display = 'none';

      } else if (calEvent['type'] === 'aguardando' || calEvent['type'] === 'aguardandodmais') {
        eleDtIni.disabled = true;
        eleDtFim.disabled = true;
        einputObsEdit.disabled = false;
        eleSelect2.disabled = false;
        bbtnCliChegou.style.display = 'none';
        bbtnReset.style.display = '';
        bbtnCliInitAte.style.display = '';
        bbtnCliFinalizar.style.display = 'none';
        bbtnAlterar.style.display = '';
        bbtnCancelamento.style.display = '';
        bbtnWhatsApp.style.display = '';
        bbtnWhatsConf.style.display = 'none';
        ebtnCliNaoFaltou.style.display = 'none';
      } else if (calEvent['type'] === 'atendimento') {
        eleDtIni.disabled = true;
        eleDtFim.disabled = true;
        einputObsEdit.disabled = false;
        eleSelect2.disabled = false;
        bbtnCliChegou.style.display = 'none';
        bbtnReset.style.display = '';
        bbtnCliInitAte.style.display = 'none';
        bbtnCliFinalizar.style.display = '';
        bbtnAlterar.style.display = '';
        bbtnCancelamento.style.display = 'none';
        bbtnWhatsApp.style.display = '';
        bbtnWhatsConf.style.display = 'none';
        ebtnCliNaoFaltou.style.display = 'none';

      } else if (calEvent['type'] === 'finalizado' || calEvent['type'] === 'bloqueado' || calEvent['type'] === 'faltou') {
        eleDtIni.disabled = true;
        eleDtFim.disabled = true;
        if (calEvent['type'] === 'finalizado') {
          einputObsEdit.disabled = true;
        } else {
          einputObsEdit.disabled = false;
        }
        eleSelect2.disabled = true;
        bbtnCliChegou.style.display = 'none';
        bbtnCliInitAte.style.display = 'none';
        bbtnCliFinalizar.style.display = 'none';
        bbtnAlterar.style.display = 'none';
        bbtnWhatsApp.style.display = '';
        bbtnWhatsConf.style.display = 'none';
        if (calEvent['type'] === 'bloqueado') {
          bbtnCancelamento.style.display = '';

        } else {
          bbtnCancelamento.style.display = 'none';
        }
        if (calEvent['type'] === 'faltou') {
          ebtnCliNaoFaltou.style.display = '';

        } else {
          ebtnCliNaoFaltou.style.display = 'none';
          bbtnReset.style.display = 'none';
        }

        let eSelectservAlt = document.getElementById('SelectservAlt');
        eSelectservAlt.style.display = 'none';
        let ebtnsFinalizar = document.getElementById('btnsFinalizar');
        ebtnsFinalizar.style.display = 'none';
      } else if (calEvent['type'] === 'espera') {
        eleDtIni.disabled = false;
        eleDtFim.disabled = false;
        einputObsEdit.disabled = false;
        eleSelect2.disabled = false;
        bbtnCliChegou.style.display = 'none';
        bbtnCliInitAte.style.display = 'none';
        bbtnCliFinalizar.style.display = 'none';
        bbtnAlterar.style.display = '';
        bbtnCancelamento.style.display = '';
        bbtnWhatsApp.style.display = '';
        bbtnWhatsConf.style.display = '';
        ebtnCliNaoFaltou.style.display = 'none';

      }


      /* 
      
            var url =  window.location.origin + '/login';
            window.history.replaceState({}, null, url); */

      //window.history.pushState("", "", '/login');


      if (calEvent['type'] === 'finalizado') {
        /*         let edivReactTabulator = document.getElementById('divReactTabulator');
                edivReactTabulator.style.display = ''; */
        bbtnReset.style.display = 'none';
        let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
        if (eimgloadingReactTabulator) {
          eimgloadingReactTabulator.style.display = '';
        }

        let eauto4 = document.getElementById('auto4');
        eauto4.style.display = 'none';

        let eSelectservAlt = document.getElementById('SelectservAlt');
        eSelectservAlt.style.display = '';

        global.gidAgenda = global.EventoId;

        setAtuLancamentos({ idagenda: global.gidAgenda })
        let eimgloadingReactTabulatorPgto = document.getElementById('imgloadingReactTabulatorPgto');
        if (eimgloadingReactTabulatorPgto) {
          eimgloadingReactTabulatorPgto.style.display = '';
        }
        setAtuLancamentosPgto({ caixamov: calEvent['idcaixamov'] })
        let pagamento = document.getElementById('pagamento');
        if (calEvent['idcaixamov']) {
          if (pagamento) { pagamento.style.display = ''; }
        } else {
          if (pagamento) { pagamento.style.display = 'none'; }
        }
        ////cl("calEvent",calEvent);
      } else {
        let eauto4 = document.getElementById('auto4');
        eauto4.style.display = '';
      }

      scroll.scrollToTop();

      setAtu({ '0': '0' });


    }

    const CURRENT_DATE = moment().toDate();

    const ColoredDateCellWrapper = ({ children, value }) =>
      React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: value < CURRENT_DATE ? 'lightblue' : 'lightgreen',
        },
      });

    function abreviar(str) {
      const [nome, ...sobrenomes] = str.split(' ');

      const abreviaturas = sobrenomes.reduce((arr, str) => {
        const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
        if (!letraGrande) return arr;
        return arr.concat(`${letraGrande[0]}.`);
      }, []);

      return [nome, ...abreviaturas].join(' ');
    }

    function printToPdf(params) {

      html2canvas(document.getElementsByClassName("rbc-time-view-resources")[0]).then(canvas => {
        var data = canvas.toDataURL();
        var pdfExportSetting = {
          pageOrientation: 'landscape',
          pageSize: 'A4',
          content: [
            {
              image: data,
              width: 780,
              //margin: [ 0, 0, 0, 0 ]
              //fit: [100, 100],
              //pageBreak: 'after'
            }
          ]
        };
        let einputDtCurrent = document.getElementById('inputDtCurrent');
        let name = moment(einputDtCurrent.value).format('DDMMYYYY') + ".pdf"
        pdfMake.createPdf(pdfExportSetting).download(name);
      });


    }

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardAgenda" >
          <ToastContainer
            style={{ width: "220px" }}
            position="bottom-right"
            autoClose={60000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-superpowers fa-spin fa-4x fa-fw"></i></div>
          <div className="card-body locCont" id="cardGridBggBody">
            <section className="content-header">
              {/* <h1 id="TxtSeltxt2" className="box-title-bgg"></h1> */}
              <section className="content" id="divpage6">
                <div id="agenda" >
                  <img id="imgloadingCalendar" style={{ display: 'none' }} width="50px" alt="Loading.." src={Loading}></img>
                  {/* <button className="btn bg-gradient-info btn-sm" onClick={printToPdf}>Ok</button> */}
                  <Calendar
                    components={{
                      eventWrapper: EventWrapper,
                      dateCellWrapper: ColoredDateCellWrapper,
                      toolbar: RBCToolbar,
                      event: CustomEvent,
                      agenda: {
                        event: AgendaCustomEvent,
                      }
                    }}
                    tooltipAccessor={(e) => e.title + '  com: ' + e.resourceTitle + '(' + e.resourceId + ')' + ' >>Serviço : ' + e.services + '   ...por ' + e.user + ' (' + e.id + ')'}
                    localizer={localizer}
                    defaultDate={dataAtual}
                    defaultView="day"
                    events={Eventos}
                    resourceIdAccessor="resourceId"
                    resources={Profis}
                    resourceTitleAccessor="resourceTitle"
                    step={30}
                    timeslots={1}
                    style={{ height: "900px", width: "auto" }}
                    views={views}
                    formats={formats}
                    messages={{
                      next: "Próximo",
                      previous: "Anterior",
                      today: "Hoje",
                      month: "Mês",
                      week: "Semana",
                      day: "Dia",
                      date: "Data",
                      time: "Horário",
                      event: "Agendamento",
                      noEventsInRange: 'Não existe agendamento para o período selecionado!'
                    }}
                    min={new Date(2022, 1, 1, 9, 0, 0, 0)}
                    max={new Date(2024, 1, 1, 21, 0, 0, 0)}
                    /* onDoubleClickEvent={onDoubleClickEvent} Problema no Iphone*/
                    selectable
                    onSelectSlot={onSelectSlot}
                    onSelectEvent={onSelectEvent}
                    popup
                  //toolbar={true}

                  />
                </div>
              </section>
            </section>
            {/* </form> */}

          </div>

        </div>
      </>

    )

  }





  function nomeAta(nome) {
    nome = nome.replace('.pdf', '');
    nome = nome.replace(/_/g, ' ');

    return nome;
  }


  function txtTxtSel(a) {
    let txtC = document.getElementById('TxtSeltxt');
    txtC.textContent = a;
  }

  function geraWordProp(chave, prop) {
    const linkurl = "http://fghfghfghfg/docx.php?apikey=398738497834758934759834758934&chave=" + chave + "&prop=" + prop;
    window.open(linkurl, '_blank');

  }

  function whatsappweb(nome, fone) {


    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    //cl(link);
    window.open(link, '_blank');

  }

  function zapConfirmAgenda(nome, fone, newagenda, dtini) {
    var hashInt = require("hash-int")
    let salto = "\r\n";
    salto = window.encodeURIComponent(salto);
    let chave = global.makeChave(20);
    let start = '';
    if (newagenda === 1) {
      start = window.encodeURIComponent(moment(document.getElementById('inputDtIni').value).format("DD/MM/YYYY HH:mm"));
    } else if (newagenda === 2) {
      start = window.encodeURIComponent(moment(dtini).format("DD/MM/YYYY HH:mm"));
    } else {
      start = window.encodeURIComponent(moment(document.getElementById('inputDtIniEdit').value).format("DD/MM/YYYY HH:mm"));
    }
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=*Espaço Marcio Bagagine*" + salto + salto + '*Confirmação de Agendamento*' + salto + 'Cliente: ' + nome + salto + 'Agendamento: *' + start + 'h*' + salto + 'Profissional: ' + global.gProfissional + salto + 'Descrição: ' + global.ServicoDescricao + salto + salto + '_autenticação: ' + chave + '_';
    window.open(link, '_blank');

    setIncLog({
      idprof: global.idProf,
      idcli: global.idCliente,
      idagenda: global.EventoId,
      user: global.gIdUser,
      loglink: link
    })



    //cl(document.getElementById('inputNameEdit').value, moment(document.getElementById('inputDtIniEdit').value).format("DD/MM/YYYY HH:mm"));

  }


  function copiarlinha(linha) {
    copycli(linha, {
      debug: true,
      message: 'Press #{key} to copy',
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Linha digitável copiada !!</h1><br></br><br></br>
            <p id='pmodal'><b>{linha}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }

  function confirmDel(nome, chave) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este registro ?</h1><br></br><br></br>
            <p id='pmodal'><b>{nome}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                setDelNCtc(
                  {
                    'chavedel': chave
                  });
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }


  const printIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-print'></i>";
  };
  const iconwhatsappweb = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-whatsapp'></i>";
  }
  const iconcopy = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-copy'></i>";
  }
  const iconLixeira = function (cell, formatterParams) { //plain text value
    if (cell.getRow().getData().progress < 11) {
      return "<i class='fa fa-trash-o'></i>";
    } else {
      return "-";
    }
  }
  const emailIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-envelope-o'></i>";
  };
  const infoIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-info-circle'></i> " + cell.getRow().getData().msginfo;
  };
  const wordIcon = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-file-word'></i>";
  };
  const iconContrato = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-plus-square'></i>";
  };
  const iconBarcode = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-eye'></i>";
  };

  const formatPrazo = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getValue(), "dd/mm/yyyy"));
    if (cell.getValue() == null) {
      return "-"; //return the contents of the cell;  
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias < 3)) {
      return "<font color='red'><b>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</b></font>";
    } else if ((cell.getRow().getData().qtdProp > 0)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias > 3)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    }
  }

  const formatTdifdias = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getRow().getData().prazo, "dd/mm/yyyy"));
    if ((cell.getRow().getData().qtdProp < 1) && (cell.getRow().getData().prazo !== null)) {
      if (tdifdias >= 4) {
        return "<font color='green'>" + tdifdias + "</font>";
      } else if ((tdifdias > 0) && (tdifdias <= 3)) {
        return "<font color='red'>" + tdifdias + "</font>";
      } else if ((tdifdias <= 0)) {
        return "<font color='red'><b>" + tdifdias + "</b></font>";
      }
    } else {
      return "";
    }

  }

  function GravarAgenda(params) {

    let ebtnEnviar = document.getElementById('btnEnviar');
    ebtnEnviar.style.display = 'none';
    let TabSel = document.getElementById('custom-tabs-five-tab')
    let TabSolActive = TabSel.querySelector('.active').innerHTML
    const input = document.querySelectorAll("input");
    //const enreactselect2input = document.getElementById('react-select-2-input');
    //cl("leng",erdliihliU7uL5ppyMa7N4gTZselected.length)
    /*   //cl(input)
      //cl(TabSolActive)
      //cl(global.ServicoDescricao)
      //cl(global.newCliente) */
    if (TabSolActive !== 'Bloquear Agenda') { validaRange(); }

    if ((global.idCliente === 0) && (TabSolActive !== 'Bloquear Agenda') && (global.newCliente === 0)) {
      window.alert("ERRO - Revise o nome do cliente até que a Lista de pesquisa esteja em branco !");
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return
    }

    if (TabSolActive === 'Bloquear Agenda') {
      setReservaAgenda(
        {
          'idcli': 0,
          'idprof': global.idProf,
          'start': dateFormat(Date.parse(document.getElementById('inputDtIniBloquear').value), "yyyy-mm-dd HH:MM:ss"),
          'end': dateFormat(Date.parse(document.getElementById('inputDtFimBloquear').value), "yyyy-mm-dd HH:MM:ss"),
          'status': "bloqueado",
          'user': global.gIdUser,
          'newCliente': 0,
          'nomeCli': '',
          'foneCli': '',
          'emailCli': ''
        }

      );


      CloseadEnviarEmail();
      //cl("foi")
      return
    }
    //cl(input)
    if (!input[0].value) {
      modalBase("Atenção", "Selecione o cliente", "", "", "0");
      //cl("nome input",)
      input[0].focus();
      //ReactSearchAutocomplete.setFocus().
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return
    }
    if ((!global.ServicoDescricao) || (!global.ServicoDescricao.length)) {
      //enreactselect2input.focus();
      modalBase("Atenção", "Selecione pelo menos um serviço por favor", "", "", "5");
      let ebtnModal = document.getElementById('btnModal');
      ebtnModal.focus();
      //modalBase('Atenção','Selecione o principal serviço por favor');
      //window.alert('Selecione pelo menos um serviço por favor');
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return
    }
    if (!global.inpAddFrmC.inputFone) {
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return validaFone(1, 'inputFone')
    }
    //if (!global.inpAddFrmC.inputEmail6) { return validaEmail6(1, 'inputEmail6') }
    if (global.inpAddFrmC.inputDtIniReserva === false) {
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return
    }
    if (global.inpAddFrmC.inputDtFimReserva === false) {
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return
    }
    if (!global.idProf) {
      window.alert('Selecione o profissional novamente');
      let ebtnEnviar = document.getElementById('btnEnviar');
      ebtnEnviar.style.display = '';
      return
    }
    if (input[0].value) {

      //input[0].value = '';
      setsearchString('a') //forçar troca sempre
      setsearchString('')
      //cl("ReactSearchAutocomplete.apply")
    }



    if (global.newCliente === 1) {
      if (TabSolActive === 'Incluir na Lista de Espera') {

        setNewCli(
          {
            'idcli': global.idCliente,
            'idprof': global.idProf,
            'start': document.getElementById('inputDtEspera').value,
            'end': document.getElementById('inputDtEspera').value,
            'status': "espera",
            'servicos': global.ServicoDescricao,
            'jservicos': searchServicos,
            'user': global.gIdUser,
            'newCliente': global.newCliente,
            'nomeCli': input[0].value,
            'foneCli': document.getElementById('inputFone').value,
            'emailCli': document.getElementById('inputEmail6').value
          }

        );

      } else {
        setNewCli(
          {
            'idcli': global.idCliente,
            'idprof': global.idProf,
            'start': dateFormat(Date.parse(document.getElementById('inputDtIni').value), "yyyy-mm-dd HH:MM:ss"),
            'end': dateFormat(Date.parse(document.getElementById('inputDtFim').value), "yyyy-mm-dd HH:MM:ss"),
            'status': "inicial",
            'servicos': global.ServicoDescricao,
            'jservicos': searchServicos,
            'user': global.gIdUser,
            'newCliente': global.newCliente,
            'nomeCli': input[0].value,
            'foneCli': document.getElementById('inputFone').value,
            'emailCli': document.getElementById('inputEmail6').value
          }

        );

        //modalEnviaAgenda(input[0].value, document.getElementById('inputFone').value, 2, document.getElementById('inputDtIni').value);

      }
      global.inpAddFrmC.inputFone = false;
      global.inpAddFrmC.inputEmail6 = false;

    } else {
      if (TabSolActive === 'Incluir na Lista de Espera') {

        setReservaAgenda(
          {
            'idcli': global.idCliente,
            'idprof': global.idProf,
            'start': document.getElementById('inputDtEspera').value,
            'end': document.getElementById('inputDtEspera').value,
            'status': "espera",
            'servicos': global.ServicoDescricao,
            'jservicos': searchServicos,
            'user': global.gIdUser,
            'newCliente': global.newCliente,
            'nomeCli': input[0].value,
            'foneCli': document.getElementById('inputFone').value,
            'emailCli': document.getElementById('inputEmail6').value
          }

        );

      } else {

        // //cl("setReservaAgenda")
        setReservaAgenda(
          {
            'idcli': global.idCliente,
            'idprof': global.idProf,
            'start': dateFormat(Date.parse(document.getElementById('inputDtIni').value), "yyyy-mm-dd HH:MM:ss"),
            'end': dateFormat(Date.parse(document.getElementById('inputDtFim').value), "yyyy-mm-dd HH:MM:ss"),
            'status': "inicial",
            'servicos': global.ServicoDescricao,
            'user': global.gIdUser,
            'newCliente': global.newCliente,
            'nomeCli': input[0].value,
            'foneCli': document.getElementById('inputFone').value,
            'emailCli': document.getElementById('inputEmail6').value,
            'jservicos': searchServicos
          }

        );

        //modalEnviaAgenda(input[0].value, document.getElementById('inputFone').value, 2, document.getElementById('inputDtIni').value);
        //zapConfirmAgenda(input[0].value,document.getElementById('inputFone').value,1);

        //cl("searchServicos",searchServicos)

      }
    }


    ////cl(input)

    if (input[2].value) {
      input[2].value = '';
    }
    if (input[0].value) {
      input[0].value = '';
    }

    global.inpAddFrmC.inputFone = false;
    global.inpAddFrmC.inputEmail6 = false;

    CloseadEnviarEmail();

  }

  function CloseadEnviarEmail(params) {

    setsearchString('a') //forçar troca sempre
    setsearchString('')
    setServicosSelected();
    //setsearchServicos('a');
    setsearchServicos(null);


    const eNnome = document.getElementById('panelAgendar')
    eNnome.style.display = 'none';

    let etabagendabloquearconteudo = document.getElementById('tab-agenda-bloquear-conteudo');

    etabagendabloquearconteudo.classList.remove('active');
    etabagendabloquearconteudo.classList.remove('show');

    let etabagendabloquear = document.getElementById('tab-agenda-bloquear');

    etabagendabloquear.classList.remove('active');

    let ecardAgenda = document.getElementById('cardAgenda');
    ecardAgenda.style.display = '';




    scroll.scrollToTop();
  }
  function CloseadPAction(params) {

    setsearchString('a') //forçar troca sempre
    setsearchString('')
    setServicosSelected();
    //setsearchServicos('a');
    setsearchServicos(null);



    let ecardAgenda = document.getElementById('cardAgenda');
    ecardAgenda.style.display = '';

    const eNnome = document.getElementById('panelAction')
    eNnome.style.display = 'none';
    scroll.scrollToTop();
  }

  function CalcSimule(params) {

  }

  function CloseInsumo(params) {
    let edivPanelAction = document.getElementById('divPanelAction');
    edivPanelAction.classList.remove("collapsed-card");


    let edivPanelActionBody = document.getElementById('divPanelActionBody');
    if (edivPanelActionBody) { edivPanelActionBody.style.display = ''; }

    let epanelInsumos = document.getElementById('panelInsumos');
    if (epanelInsumos) { epanelInsumos.style.display = 'none'; }

    setAtuLancInsumos({ 'idagenda': 0 });

  }

  function btnLancInsumos(params) {
    //cl("params",params)

    let k;
    let eSelectServ = document.getElementById('SelectServ');
    removeOptions(document.getElementById('SelectServ'));
    //cl(searchServicos)
    if (searchServicos.length > 1) {
      let opt = document.createElement('option');
      opt.value = 0;
      opt.innerHTML = 'Selecione...';
      eSelectServ.appendChild(opt);

    }
    for (k = 0; k < searchServicos.length; k++) {
      let opt = document.createElement('option');
      opt.value = searchServicos[k].id;
      opt.innerHTML = searchServicos[k].label;
      eSelectServ.appendChild(opt);
    }
    let edivPanelAction = document.getElementById('divPanelAction');
    edivPanelAction.classList.add("collapsed-card");


    let edivPanelActionBody = document.getElementById('divPanelActionBody');
    if (edivPanelActionBody) { edivPanelActionBody.style.display = 'none'; }

    let epanelInsumos = document.getElementById('panelInsumos');
    if (epanelInsumos) { epanelInsumos.style.display = ''; }

    let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
    if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = ''; }

    setAtuLancInsumos({ 'idagenda': params });
    global.gidAgenda = params;
    let eiQtdInsumo = document.getElementById('iQtdInsumo');
    eiQtdInsumo.value = 1;

    if (searchServicos.length > 1) {
      if (eSelectServ) { eSelectServ.focus(); }
    } else {
      //let eSelectProduto = document.getElementById('react-select-5-input');
      //if(eSelectProduto){eSelectProduto.focus();}
      if (eiQtdInsumo) { eiQtdInsumo.focus(); }
    }

  }

  function startFinalizar(idev, status) {

    let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
    if (eimgloadingReactTabulator) {
      eimgloadingReactTabulator.style.display = '';
    }

    let ehorarioReserva = document.getElementById('horarioReserva');
    ehorarioReserva.style.display = 'none';

    let ealterarEbotoes = document.getElementById('alterarEbotoes');
    ealterarEbotoes.style.display = 'none';

    /*     let edivReactTabulator = document.getElementById('divReactTabulator');
        edivReactTabulator.style.display = ''; */

    global.gidAgenda = idev;

    setAtuLancamentos({ idagenda: global.gidAgenda })


  }

  function ACliStatus(idev, status) {

    global.gAgendaStatus = 1;

    setAgendaStatus(
      {
        'id': idev,
        'cli': global.idCliente,
        'prof': global.idProf,
        'status': status,
        'user': global.gIdUser
      }

    );


  }

  function ACliAlt(idev, statusAtu) {
    let status = '';
    if (statusAtu === 'espera') {
      status = 'inicial'
    } else {
      status = statusAtu;
    }
    //cl("cli", global.idCliente)
    global.gAgendaAlt = 1;

    const einputDtIni = document.getElementById('inputDtIniEdit')
    const einputDtFim = document.getElementById('inputDtFimEdit')
    let dtini = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss');
    let dtinidia = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss').format('DD');
    let dtfim = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss');
    let dtfimdia = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss').format('DD');
    //mudança permição data e hora minima
    let dtagoradiabase = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD 04:00');
    let dtagora = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
    let dtagorabase = moment(dtagoradiabase, 'YYYY-MM-DD HH:mm:ss');
    var duration = moment.duration(dtfim.diff(dtini));
    var calcdia1 = moment.duration(dtini.diff(dtagora));
    var calcdia1base = moment.duration(dtini.diff(dtagorabase));
    //cl("dtagoradiabase",dtagoradiabase)
    //cl("dtagora",dtagora)
    //cl("dtagorabase",dtagorabase)
    //cl("calcdia1",calcdia1.asMinutes())
    //cl("calcdia1base",calcdia1base.asMinutes())
    //return
    let duracao = duration.asHours();
    let duracaoday = duration.asDays();
    let dia1 = calcdia1.asMinutes();
    let dia1base = calcdia1base.asMinutes();

    //cl("range", duracao)
    //cl("dia1", dia1)
    //cl("duracaoday", duracaoday)
    //cl("dtinidia", dtinidia)
    //cl("dtfimdia", dtfimdia)

    if (dia1base < 0) {
      einputDtIni.classList.remove("is-valid")
      einputDtIni.classList.add("is-invalid")
      global.inpAddFrmC.inputDtIniReserva = false;
      //modalBase("Atenção", "Data e Hora Inicial não pode ser menor que Agora !!!");
      window.alert("A data e Hora Inicial não pode ser menor que Hoje apartir das 06hs !!!");
      einputDtIni.focus();
      return;
    } else {
      einputDtIni.classList.remove("is-invalid")
      einputDtIni.classList.add("is-valid")
      global.inpAddFrmC.inputDtIniReserva = true;
    }

    if ((duracao > 0) && (dtinidia === dtfimdia)) {
      einputDtFim.classList.add("is-valid")
      einputDtFim.classList.remove("is-invalid")
      global.inpAddFrmC.inputDtFimReserva = true;
    } else if ((duracao < 0) || (dtinidia !== dtfimdia)) {
      einputDtFim.classList.remove("is-valid")
      einputDtFim.classList.add("is-invalid")
      global.inpAddFrmC.inputDtIniReserva = false;
      einputDtFim.focus();
      window.alert("O Dia ou Horário Final, não podem ser menor que o Inicial ou para o dia seguinte!!!");
      //modalBase("Atenção", "O Dia ou Horário Final, não podem ser menor que o Inicial ou para dia seguinte!!!");
      return;
    }

    //cl('global.idCliente alterar',global.idCliente)

    setAgendaAlt(
      {
        'id': idev,
        'idprof': document.getElementById('selectProf').value,
        'cli': global.idCliente,
        'start': dateFormat(Date.parse(document.getElementById('inputDtIniEdit').value), "yyyy-mm-dd HH:MM:ss"),
        'end': dateFormat(Date.parse(document.getElementById('inputDtFimEdit').value), "yyyy-mm-dd HH:MM:ss"),
        'status': status,
        'user': global.gIdUser,
        'servicos': global.ServicoDescricao,
        'obs': document.getElementById('inputObsEdit').value,
        'jservicos': searchServicos
      }

    );

    CloseadPAction();


  }

  function AltServFinalizar(idev, statusAtu) {
    let edivReactTabulator = document.getElementById('divReactTabulator');
    if (edivReactTabulator) {
      edivReactTabulator.style.display = 'none';
    }

    let status = '';
    if (statusAtu === 'espera') {
      status = 'inicial'
    } else {
      status = statusAtu;
    }
    //cl("cli", global.idCliente)
    global.gAgendaAlt = 1;

    //cl('global.idCliente alterar',global.idCliente)
    //cl("searchServicos",searchServicos);
    let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
    eimgloadingReactTabulator.style.display = '';

    setAgendaAlt(
      {
        'id': idev,
        'idprof': document.getElementById('selectProf').value,
        'cli': global.idCliente,
        'start': dateFormat(Date.parse(document.getElementById('inputDtIniEdit').value), "yyyy-mm-dd HH:MM:ss"),
        'end': dateFormat(Date.parse(document.getElementById('inputDtFimEdit').value), "yyyy-mm-dd HH:MM:ss"),
        'status': status,
        'user': global.gIdUser,
        'servicos': global.ServicoDescricao,
        'obs': document.getElementById('inputObsEdit').value,
        'jservicos': searchServicos
      }

    );

    let ebtnAlterarFinalizar = document.getElementById('btnAlterarFinalizar');
    if (ebtnAlterarFinalizar) { ebtnAlterarFinalizar.style.display = 'none'; }



  }

  function EventoCancel(idev) {

    global.gEventoCancelar = 1;

    setEventoCancelar(
      {
        'id': idev,
        'user': global.gIdUser
      }

    );

    CloseadPAction();


  }

  function page2(params) {
    const vassuntoMsg = "Auditoria Condominial - Solicitação de informações - " + dateFormat(Date.now(), "dd/mm/yy");

    const items = [
      {
        id: 0,
        name: "Cobol",
      },
      {
        id: 1,
        name: "JavaScript",
      },
      {
        id: 2,
        name: "Basic",
      },
      {
        id: 3,
        name: "PHP",
      },
      {
        id: 4,
        name: "Java",
      },
    ];
    /* 
      //cl("items", items);
      //cl("Users", Users);
      //cl("Clientes", Clientes); */


    const manyItems = [...new Array(10000)].map((_, i) => ({
      id: i,
      name: `something${i}`,
      description:
        "Some description text, where the search will be performed too.",
    }));

    const movieItems = [
      {
        id: 0,
        title: "Titanic",
        description: "A movie about love",
      },
      {
        id: 1,
        title: "Dead Poets Society",
        description: "A movie about poetry and the meaning of life",
      },
      {
        id: 2,
        title: "Terminator 2",
        description: "A robot from the future is sent back in time",
      },
      {
        id: 3,
        title: "Alien 2",
        description: "Ripley is back for a new adventure",
      },
    ];

    const handleOnSearch = (string, results) => {
      console.log(results.length);
      if (results.length === 0) {
        //global.idCliente = 0;
        global.newCliente = 1;
      } else {

        global.newCliente = 1;
      }
    };

    const handleOnHover = (result) => {
      //console.log(result);
    };

    const handleOnSelect = (item) => {
      //console.log("selecionado", item);
      global.newCliente = 0;
      global.idCliente = item.id;
      setsearchString(item.name);

      let inpfone = document.getElementById('inputFone');
      inpfone.value = item['fone'];
      validaFone('inputFone')

      let inpemail = document.getElementById('inputEmail6');
      inpemail.value = item['email'];
      //validaEmail6(inpemail.value)

      let TabSel = document.getElementById('custom-tabs-five-tab')
      let TabSolActive = TabSel.querySelector('.active').innerHTML

      if (TabSolActive === 'Reservar Horário') {
        //let enreactselect2input = document.getElementById('react-select-2-input');
        const input = document.querySelectorAll("input");
        return input[5].focus();
      }

    };

    const handleOnFocus = () => {
      //console.log("Focused");
    };

    const handleOnClear = () => {
      //console.log("Cleared");
      let inpfone = document.getElementById('inputFone');
      inpfone.value = '';
      validaFone('inputFone')

      let inpemail = document.getElementById('inputEmail6');
      inpemail.value = '';
      //validaEmail6(inpemail.value)
    };



    //Serviços

    const handleOnSearchServicos = (string, results) => {
      //console.log(results.length);
      //console.log(Servicos);
      /*       if (results.length === 0) {
              global.idCliente = 0;
              global.newCliente = 1;
            } else {
      
              global.newCliente = 0;
            } */
    };

    const handleOnHoverServicos = (result) => {
      //console.log(result);
    };

    const handleOnSelectServicos = (item) => {
      global.ServicoDescricao = item.descricao;

      const einputDtIni = document.getElementById('inputDtIni')
      const einputDtFim = document.getElementById('inputDtFim')
      let dtini = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss');
      let dtfim = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss');
      let duration = moment.duration(dtfim.diff(dtini));

      let tm = item.tempomedio;
      let parts = tm.split(':');
      let tempomedio = Number(parts[0]) * 60 + Number(parts[1]);

      let duracao = duration.asMinutes();

      // cl(duracao, tempomedio);

      if (duracao < tempomedio) {
        //window.alert('Atenção - O serviço selecionado tem um tempo médio maior que o intervalo que você esta reservando!')
        modalBase("Atenção", "O serviço selecionado tem um tempo médio maior que o intervalo que você esta reservando!", "Ajuste se necesário")
        let ebtnModal = document.getElementById('btnModal');
        ebtnModal.focus();
      }




      //console.log("selecionado", item);
    };

    function checkMultArray(array, key, value) {
      //cl("checkMultArray array", array)
      //cl("checkMultArray key", key)
      //cl("checkMultArray value", value)
      if (array.some(object => object[key] === value)) {
        return true
      } else {
        return false
      }
    }

    function changeSelectList(params) {
      /*       if (params.length === 0) {
              setServicosSelected();
      
              return
            } */
      //    //cl('params',params)
      var i;
      const result = [];
      const list = params;
      setServicosSelected();

      //list.push(params); 
      //cl("list com params", list)

      //cl("list com params lenght",list.length)

      global.ServicoDescricao = '';
      global.tm = '';


      if (global.gServicosSel) {
        // cl("global.gServicosSel",global.gServicosSel);
        // cl('list', list);
        for (i = 0; i < global.gServicosSel.length; i++) {
          //cl("FOR ___________________", i)

          //cl("global.gServicosSel.id", global.gServicosSel[i].id)

          if (checkMultArray(list, "id", global.gServicosSel[i].id)) {
            // cl("econtrouuuuucheckMultArray", global.gServicosSel[i].descricao)

            if (global.ServicoDescricao.length === 0) {
              global.ServicoDescricao = global.gServicosSel[i].descricao;
            } else {
              global.ServicoDescricao = global.ServicoDescricao + ', ' + global.gServicosSel[i].descricao;
            }
            //cl("global.ServicoDescricao", global.ServicoDescricao)
            if (!global.tm) {
              global.tm = global.gServicosSel[i].tempomedio;
            } else if (global.tm < global.gServicosSel[i].tempomedio) {
              global.tm = global.gServicosSel[i].tempomedio;
            }
          }



          /*  //dualist
                     if (list.includes(global.gServicosSel[i].id)) {
                    //cl('list encontrado',list);
                      if (global.ServicoDescricao.length === 0) {
                        global.ServicoDescricao = global.gServicosSel[i].descricao;
                      } else {
                        global.ServicoDescricao = global.ServicoDescricao + '/' + global.gServicosSel[i].descricao;
                      }
                      if (!global.tm) {
                        global.tm = global.gServicosSel[i].tempomedio;
                      } else if (global.tm < global.gServicosSel[i].tempomedio) {
                        global.tm = global.gServicosSel[i].tempomedio;
                      }
                    } */
          /*         let valor = [{
                    value: global.gServicosSel[i].id,
                    label: global.gServicosSel[i].descricao + ' - ' + global.gServicosSel[i].tempomedio + 'h'
                  }];
                  result.push(valor[0]); */
        };
        // cl('global.ServicoDescricao', global.ServicoDescricao);
      }
      //cl('result', result);

      //global.gServicosSel

      //global.ServicoDescricao = item.descricao;

      const einputDtIni = document.getElementById('inputDtIni')
      const einputDtFim = document.getElementById('inputDtFim')
      let dtini = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss');
      let dtfim = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss');
      let duration = moment.duration(dtfim.diff(dtini));

      //cl("global.tm", global.tm)
      let parts = global.tm.split(':');
      let tempomedio = Number(parts[0]) * 60 + Number(parts[1]);

      let duracao = duration.asMinutes();

      //cl(duracao, tempomedio);

      if (duracao < tempomedio) {
        //window.alert('Atenção - O serviço selecionado tem um tempo médio maior que o intervalo que você esta reservando!')
        modalBase("Atenção", "O serviço selecionado tem um tempo médio maior que o intervalo que você esta reservando!", "Ajuste se necesário", "", "5")
        let ebtnModal = document.getElementById('btnModal');
        ebtnModal.focus();
      }

      //cl("params", params)

      setServicosSelected(params);
      setsearchServicos(params);


    }

    const handleOnFocusServicos = () => {
      //console.log("Focused");
    };

    const handleOnClearServicos = () => {
      //console.log("Cleared");
      /*       let inpfone = document.getElementById('inputFone');
            inpfone.value = '';
            validaFone('inputFone')
      
            let inpemail = document.getElementById('inputEmail6');
            inpemail.value = '';
            validaEmail6(inpemail.value) */
    };

    const formatResult = (item) => {
      //console.log(item);
      function mtel(v) {
        v = v.replace(/\D/g, "");             //Remove tudo o que não é dígito
        v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        v = v.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
        return v;
      }

      let fonf = mtel(item.fone)

      return (
        <div className="result-wrapper">
          <img src="dist/img/user3-160x160.jpg" className="user-image-bgg" alt="User Image1"></img>
          <span className="result-span">{item.name}</span>
          <span className="result-span"> - </span>
          <span className="result-span">{fonf}</span>
        </div>
      );
    };

    const formatResultServico = (item) => {
      //console.log(item);
      return (
        <div className="result-wrapper">
          <span className="result-span">{item.descricao}</span>
          <span className="result-span"> - </span>
          <span className="result-span">Tempo Médio {item.tempomedio}h</span>
        </div>
      );
    };



    return (

      <div id="panelAgendar" name="panelAgendar" style={{ position: 'relative', display: 'none' }}>

        <div className="modal" tabIndex={-1} role="dialog" id="modal01">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enviar Email em desenvolvimento</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Status do envio de email : em desenvolvimeto</p>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="headCEmail">Agenda</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact" autoComplete="off">
                    <div className="form-row">
                      {/*<div className="form-group col-md-6">
                        <label htmlFor="labelSelect">Selecione o Modelo do email : </label>
                         <select className="form-control select2 select2-hidden-accessible" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                          <option selected="selected" data-select2-id={3}>Link para questionário Incial</option>
                          {/* <option data-select2-id={29}>Cotação Inicial</option> 
                        </select> 
                      </div>*/}

                      <div className="row col-md-12">
                        <label id="labelTCalc" name="labelTCalc">Dados do Cliente</label>
                      </div>
                      <div className="form-group col-md-6" autoComplete="off">
                        <label htmlFor="labelEmail5">Pesquisar Cliente por parte do Nome ou Fone</label>
                        <ReactSearchAutocomplete
                          items={Clientes}
                          fuseOptions={{ threshold: 0.1, caseSensitive: false, keys: ["name", "fone"] }}
                          resultStringKeyName="name"
                          onSearch={handleOnSearch}
                          onHover={handleOnHover}
                          onSelect={handleOnSelect}
                          onFocus={handleOnFocus}
                          onClear={handleOnClear}
                          inputSearchString={searchString}
                          styling={{
                            height: '36px',
                            borderRadius: '0.25rem',
                            zIndex: 4
                          }} // To display it on top of the search box below
                          autoFocus
                          placeholder={'Nome...'}
                          formatResult={formatResult}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="labelEmail5">Celular</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">📲</span>
                          <input type="text" className="form-control" onChange={e => validaFone(e.target.value)} placeholder="Ex: 11 99901-XXXX" id="inputFone" />
                        </div>

                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="labelEmail5">Email</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">📧</span>
                          <input type="email" className="form-control" onChange={e => validaEmail6(e.target.value, 'inputEmail6')} id="inputEmail6" placeholder="Email" />
                        </div>

                      </div>

                      <div className="col-md-12">
                        <div className="card card-primary card-tabs">
                          <div className="card-header p-0 pt-1">
                            <ul className="nav nav-tabs" id="custom-tabs-five-tab" role="tablist">
                              <li className="nav-item">
                                <a className="nav-link " id="tab-agenda-reserva" data-toggle="pill" href="#tab-agenda-reserva-conteudo" role="tab" aria-controls="tab-agenda-reserva-conteudo" aria-selected="true">Reservar Horário</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="tab-agenda-lista" data-toggle="pill" href="#tab-agenda-lista-conteudo" role="tab" aria-controls="tab-agenda-lista-conteudo" aria-selected="false">Incluir na Lista de Espera</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="tab-agenda-bloquear" data-toggle="pill" href="#tab-agenda-bloquear-conteudo" role="tab" aria-controls="tab-agenda-bloquear-conteudo" aria-selected="false">Bloquear Agenda</a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body" id="tabreserva">
                            <div className="tab-content" id="custom-tabs-five-tabContent">
                              <div className="tab-pane fade" id="tab-agenda-reserva-conteudo" role="tabpanel" aria-labelledby="tab-agenda-reserva">
                                <div className="overlay-wrapper">

                                  <div className="row col-md-12">
                                    <label id="labelTCalc" name="labelTCalc">Reservar Horário</label>
                                  </div>

                                  <div className="row col-md-12">
                                    <div className="col-sm">
                                      <div className="form-group ">
                                        <label >Início</label>

                                        <input type="datetime-local" className="form-control" id="inputDtIni" placeholder="Inicial" onBlur={e => CalcSimule()} />
                                      </div>
                                    </div>
                                    <div className="col-sm">
                                      <div className="form-group ">
                                        <label >Final</label>
                                        <input type="datetime-local" className="form-control" id="inputDtFim" placeholder="Final" onBlur={e => CalcSimule()} />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group col-md-12">

                                    <div className="row col-md-12">
                                      <label id="labelTCalc" name="labelTCalc">Selecione o(s) Serviço(s) abaixo</label>

                                    </div>
                                    {/* <label id="labelSelecionados">Selecionados</label> */}
                                    <div id="auto1">
                                      {/*                                       <ReactSearchAutocomplete
                                        items={Servicos['data']}
                                        fuseOptions={{ threshold: 0.2, caseSensitive: false, keys: ["descricao"] }}
                                        resultStringKeyName="descricao"
                                        onSearch={handleOnSearchServicos}
                                        onHover={handleOnHoverServicos}
                                        onSelect={handleOnSelectServicos}
                                        onFocus={handleOnFocusServicos}
                                        onClear={handleOnClearServicos}
                                        styling={{
                                          height: '36px',
                                          borderRadius: '0.25rem',
                                          zIndex: 5
                                        }} // To display it on top of the search box below
                                        //autoFocus
                                        placeholder={'Serviço...'}
                                        formatResult={formatResultServico}
                                      /> */}

                                      {/*                                     muito legal, mas no celular fica estranho  
                                        <DualListBox
                                        options={ServicosOpt}
                                        selected={ServicosSelected}
                                        onChange={changeDualList}
                                      /> */}
                                      <Select
                                        closeMenuOnSelect={true}
                                        components={global.animatedComponents}
                                        onChange={changeSelectList}
                                        value={searchServicos}
                                        //defaultValue={}
                                        isMulti
                                        options={ServicosOpt}
                                        placeholder='Selecione...'
                                      />


                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="tab-agenda-lista-conteudo" role="tabpanel" aria-labelledby="tab-agenda-lista">
                                <div className="overlay-wrapper">
                                  <div className="row col-md-12">
                                    <label id="labelTCalc" name="labelTCalc">Incluir na Lista de Espera do dia...</label>
                                  </div>

                                  <div className="row col-md-6">
                                    <div className="col-sm">
                                      <div className="form-group ">
                                        <label >Dia</label>

                                        <input type="date" className="form-control" id="inputDtEspera" placeholder="Dia" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-12">

                                    <div className="row col-md-12">
                                      <label id="labelTCalc" name="labelTCalc">Selecione o(s) Serviço(s) abaixo</label>

                                    </div>
                                    {/* <label id="labelSelecionados">Selecionados</label> */}
                                    <div id="auto2">

                                      <Select
                                        closeMenuOnSelect={true}
                                        components={global.animatedComponents}
                                        onChange={changeSelectList}
                                        value={searchServicos}
                                        //defaultValue={}
                                        isMulti
                                        options={ServicosOpt}
                                        placeholder='Selecione...'
                                      />


                                    </div>
                                  </div>


                                </div>
                              </div>
                              <div className="tab-pane fade" id="tab-agenda-bloquear-conteudo" role="tabpanel" aria-labelledby="tab-agenda-bloquear">
                                <div className="overlay-wrapper">

                                  <div className="row col-md-12">
                                    <label id="labelTCalc" name="labelTCalcbl">Bloquear Horário</label>
                                  </div>

                                  <div className="row col-md-12">
                                    <div className="col-sm">
                                      <div className="form-group ">
                                        <label >Início</label>

                                        <input type="datetime-local" className="form-control" id="inputDtIniBloquear" placeholder="Inicial" />
                                      </div>
                                    </div>
                                    <div className="col-sm">
                                      <div className="form-group ">
                                        <label >Final</label>
                                        <input type="datetime-local" className="form-control" id="inputDtFimBloquear" placeholder="Final" />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>


                      {/*                       <div className="form-group col-md-12">
                        <label htmlFor="labelAssunto">Assunto</label>
                        <input type="text" className="form-control" value={vassuntoMsg} onChange={e => validaAssunto(e.target.value, 'labelEmail5')} id="inputAssunto" placeholder="Assunto" />
                      </div> */}
                    </div>
                    {/* <button type="button" id="btnAcessarLink" name="btnAcessarLink" formtarget="_blank" target={linkQuest} className="btn btn-info btn-sm float-right" > Acessar</button> */}
                    {/*                     <a href={linkQuest} target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-right"><i className='fa fa-external-link'></i></a>
                    <CopyToClipboard id="" text={linkQuest} onCopy={() => clicopied()} >
                      <button type="button" id="copyclip" name="copyclip" className="btn btn-info btn-sm float-right" > Copy Link</button>
                    </CopyToClipboard>
                    <span id="txtLinkQuest" className="input-group-text float-right" style={{ fontSize: 12 }}>
                      {linkQuest}</span>
                    <div className="form-group">
                      <label htmlFor="inputObs">Mensagem</label>
                      {/* <input type="text" className="form-control" onChange={e => validaObs(e.target.value)} id="inputObs" placeholder="Obs" /> */}
                    {/*                       <textarea className="form-control" id="txtEmail" rows={3} placeholder="Enter ..." defaultValue={""} /> */}
                    {/* 
                    </div> * */}


                    <button type="button" className="btn btn-info " id="btnEnviar" onClick={GravarAgenda}>Gravar</button>
                    {/* <button type="button" className="btn btn-info " id="btnteste" onClick={validaRange}>valida</button> */}
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={CloseadEnviarEmail} >Cancelar</button>
                  </form>




                </div>

              </div>

            </section>

          </div>

        </section>
      </div>

    )

  }

  function InsumoMat(params) {
    return (
      <div id="panelInsumos" name="panelInsumos" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-info cardAddBgg" id="cardSelInsumos" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Insumos / Materias Utilizados</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelInsumosBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        {/*         <label id="labelDualList" htmlFor="lNome">Relação cadastrada</label> */}
                        <div id="divReactTabulatorCxMov" >
                          <ReactTabulator
                            name="rcardSelInsumosBody"
                            id="rcardSelInsumosBody"
                            // ref={(ref)}
                            columns={columnsInsumo}
                            data={LancInsumos}
                            /*               rowClick={rowClick}
                            cellClick={rowClick} */
                            options={options}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                          <label id="labelTotal" htmlFor="lCep">Total</label>
                          <div className="input-group-prepend">
                            <input type="text" className="form-control" disabled id="inputTotalInsumos" />
                          </div>
                          <img id="imgloadingReactTabulatorCxMov" style={{ display: '' }} className="row col-md-1" alt="Loading.." src={Loading}></img>
                        </div>
                        <div id="SelectServDiv" className="form-row">
                          <div className="form-group col-md-2">
                            <label htmlFor="labelSelectI">Serviço</label>
                            <select className="form-control select2 select2-hidden-accessible" id="SelectServ" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                              {/*                             <option selected="selected" data-select2-id={3}>Link para questionário Incial</option>
                            <option data-select2-id={29}>Cotação Inicial</option> */}
                            </select>
                          </div>
                          <div className="form-group col-md-1">
                            <label htmlFor="labelSelectI">Qtd</label>
                            <input type="number" className="form-control" autocomplete="off" id="iQtdInsumo" />
                          </div>
                          <div className="form-group col-md-5" >
                            <label htmlFor="labelSelect">Adicionar Produto (Insumo)</label>
                            <Select
                              id="SelectProduto"
                              closeMenuOnSelect={true}
                              components={global.animatedComponentsInsumos}
                              onChange={ChangeAddProd}
                              value={searchProdutos}
                              //defaultValue={}
                              isMulti
                              options={ProdutosOpt}
                              placeholder='Adicionar Produto utilizado...'
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={() => CloseInsumo()} >Fechar</button>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function page3(params) {

    function checkMultArrayAlt(array, key, value) {
      if (array.some(object => object[key] === value)) {
        return true
      } else {
        return false
      }
    }

    function editLanc(id, valor, qtd, descricao) {


      // cl(id, valor, qtd);


      confirmAltLanc(id, valor, qtd, descricao);

      let einputMQtd = document.getElementById('inputMQtd');
      einputMQtd.value = qtd;
      let einputMValor = document.getElementById('inputMValor');
      einputMValor.value = valor;
      /*       setEditLancamentos({
              id: 126,
              valor: "400,00",
              qtd: 1
            })
      
            setAtuLancamentos({ idagenda: global.gidAgenda }) */

    }



    function changeSelectListAlt(params) {
      /*       if (params.length === 0) {
              setServicosSelected();
      
              return
            } */
      //    //cl('params',params)
      var i;
      const result = [];
      const list = params;
      let ebtnAlterarFinalizar = document.getElementById('btnAlterarFinalizar');
      if (ebtnAlterarFinalizar) { ebtnAlterarFinalizar.style.display = '' };
      setServicosSelected();

      //list.push(params); 
      //cl("list com params", list)

      //cl("list com params lenght",list.length)

      global.ServicoDescricao = '';
      global.tm = '';


      if (global.gServicosSel) {
        // cl("global.gServicosSel",global.gServicosSel);
        // cl('list', list);
        for (i = 0; i < global.gServicosSel.length; i++) {
          //cl("FOR ___________________", i)

          //cl("global.gServicosSel.id", global.gServicosSel[i].id)

          if (checkMultArrayAlt(list, "id", global.gServicosSel[i].id)) {
            // cl("econtrouuuuucheckMultArray", global.gServicosSel[i].descricao)

            if (global.ServicoDescricao.length === 0) {
              global.ServicoDescricao = global.gServicosSel[i].descricao;
            } else {
              global.ServicoDescricao = global.ServicoDescricao + ', ' + global.gServicosSel[i].descricao;
            }
            //cl("global.ServicoDescricao", global.ServicoDescricao)
            if (!global.tm) {
              global.tm = global.gServicosSel[i].tempomedio;
            } else if (global.tm < global.gServicosSel[i].tempomedio) {
              global.tm = global.gServicosSel[i].tempomedio;
            }
          }

        };
        //cl('global.ServicoDescricao', global.ServicoDescricao);
      }
      //cl('result', result);


      const einputDtIni = document.getElementById('inputDtIni')
      const einputDtFim = document.getElementById('inputDtFim')
      let dtini = moment(einputDtIni.value, 'YYYY-MM-DD HH:mm:ss');
      let dtfim = moment(einputDtFim.value, 'YYYY-MM-DD HH:mm:ss');
      let duration = moment.duration(dtfim.diff(dtini));

      //cl("global.tm", global.tm)
      let parts = global.tm.split(':');
      let tempomedio = Number(parts[0]) * 60 + Number(parts[1]);

      let duracao = duration.asMinutes();

      //cl(duracao, tempomedio);

      if (duracao < tempomedio) {
        modalBase("Atenção", "O serviço selecionado tem um tempo médio maior que o intervalo que você esta reservando!", "Ajuste se necesário", "", "5")
        let ebtnModal = document.getElementById('btnModal');
        ebtnModal.focus();
      }

      //cl("params", params)

      setServicosSelected(params);
      setsearchServicos(params);


    }

    const columns = [
      //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
      { title: 'Id', field: 'id', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
      // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
      //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
      { title: 'Qtd.', field: 'qtd', hozAlign: 'center', visible: true, responsive: 0, headerSort: false },
      { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 250, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
      { title: 'Valor R$', field: 'valor', hozAlign: "center", width: 150, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      /*       {
              title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
      
                let textoAjustado = cell.getValue();
      
                return textoAjustado + " %";
              }
            }, */
      { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'inicial') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao)) } } }
    ];

    const columnspgto = [
      //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
      { title: 'ID', field: 'id', hozAlign: 'center', width: 50, responsive: 0 },
      { title: 'Data', field: 'start', align: 'center', width: 90, responsive: 0, formatter: formatData },
      //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
      //{ title: 'Qtd.', field: 'qtd', hozAlign: 'center', visible: true, responsive: 0, headerSort: false },
      { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 250, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
      { title: 'Valor', field: 'valor', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Dinheiro', field: 'cash', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Troco', field: 'troco', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Débito', field: 'debito', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Credito', field: 'creditoavista', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Credito Parc', field: 'creditoparc', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Pix', field: 'pix', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Ficha', field: 'cc', hozAlign: "center", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
      /*       {
              title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
      
                let textoAjustado = cell.getValue();
      
                return textoAjustado + " %";
              }
            }, */
      //{ title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'inicial') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao)) } } }
    ];



    const options = {
      //autoResize:false,
      //height: 125,
      // movableRows: true,
      clipboard: true,
      //resizableRows:true,
      //invalidOptionWarnings: false,
      responsiveLayoutCollapseStartOpen: false,
      //groupBy:"origem",
      //groupValues:["APP_Teste"],
      //selectable:1,
      //selectablePersistence:false,
      responsiveLayout: "collapse",
      initialSort: [
        { column: "descricao", dir: "asc" }, //sort by this first
      ]
      // pagination:"local",
      // paginationSize:12,
      // paginationSizeSelector:[10, 25, 50, 100],

    };

    const optionspgto = {
      clipboard: true,
      responsiveLayoutCollapseStartOpen: false,
      responsiveLayout: "collapse",
      initialSort: [
        { column: "descricao", dir: "asc" }, //sort by this first
      ]

    };




    return (

      <div id="panelAction" name="panelAction" style={{ position: 'relative', display: 'none' }}>

        <div className="modal" tabIndex={-1} role="dialog" id="modal01">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enviar Email em desenvolvimento</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Status do envio de email : em desenvolvimeto</p>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div id="divPanelAction" className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="headCAcoes">Agenda - Ações</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div id="divPanelActionBody" className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="row col-md-12">
                        <label id="labelTCalc" name="labelTCalc">Dados do Cliente</label>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="labelEmail5">Cliente</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" disabled id="inputNameEdit" />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="labelEmail5">Celular</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">📲</span>
                          <input type="text" className="form-control" disabled id="inputTelEdit" />
                        </div>

                      </div>
                      <div className="form-group col-md-12">
                        <label id="labelEventoEdit" name="labelEventoEdit"></label>
                      </div>
                      <div className="row col-md-12" id="horarioReserva">
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Horário Reservado</label>
                        </div>

                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Início</label>

                              <input type="datetime-local" className="form-control" id="inputDtIniEdit" placeholder="Inicial" onBlur={e => CalcSimule()} />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Final</label>
                              <input type="datetime-local" className="form-control" id="inputDtFimEdit" placeholder="Final" onBlur={e => CalcSimule()} />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group">
                              <label>Profissional</label>
                              <select className="form-control" id="selectProf">

                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Observação :</label>
                              <input type="text" className="form-control" id="inputObsEdit" placeholder="observações..." />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12" id="SelectservAlt">

                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Selecione o(s) Serviço(s) abaixo</label>

                        </div>
                        <div id="auto4">
                          <Select
                            closeMenuOnSelect={true}
                            components={global.animatedComponents}
                            onChange={changeSelectListAlt}
                            value={searchServicos}
                            //defaultValue={}
                            isMulti
                            options={ServicosOpt}
                            placeholder='Selecione...'
                          />
                        </div>
                        <img id="imgloadingReactTabulator" style={{ display: 'none' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulator" style={{ display: 'none' }} className="row col-md-12">
                          <ReactTabulator
                            name="r1"
                            id="r1"
                            // ref={(ref)}
                            columns={columns}
                            data={Lancamentos}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={options}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                          <div className="row col-md-12" id="btnsFinalizar">
                            <div className="col-sm">
                              <div className="form-group float-right ">

                                <button type="button" className="btn btn-info " style={{ display: 'none' }} id="btnAlterarFinalizar" onClick={() => AltServFinalizar(global.EventoId, global.StatusAtu)}>Alterar Serviços </button>

                                <button type="button" className="btn btn-info " id="btnFinalizarAgenda" onClick={() => confirmAltStatus(global.EventoId, 'finalizado')} >Finalizar</button>

                                <button type="button" className="btn btn-info " id="btnFecharFinalizar" onClick={CloseadPAction} >Voltar</button>
                              </div></div>
                          </div>
                          <div className="row col-md-12" id="pagamento" style={{ display: 'none' }}>
                            <div className="row col-md-12">
                              <label id="labelTCalcPgto" name="labelTCalcPgto">Dados do Pagamento</label>

                            </div>
                            <img id="imgloadingReactTabulatorPgto" style={{ display: 'none' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                            <div className="form-group " id="ReactTabulatorPgto">
                              <ReactTabulator
                                name="r1pgto"
                                id="r1pgto"
                                // ref={(ref)}
                                columns={columnspgto}
                                data={LancamentosPgto}
                                /*               rowClick={rowClick}
                                              cellClick={rowClick} */
                                options={optionspgto}
                                events={{}}
                              //data-custom-attr="test-custom-attribute"
                              //className="#example-table-theme"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row col-md-12" id="alterarEbotoes">
                        <div className="row col-md-5">
                          <label id="labelTCalc" name="labelTCalc">Alterar Status do Atendimento</label>
                        </div>
                        <div className="row col-md-2">
                          <label id="labelTCalc" name="labelTCalc">Materiais / Insumos</label>
                        </div>
                        <div className="row col-md-5 float-right">
                          <label id="labelTCalc" name="labelTCalc">Ações possíveis</label>
                        </div>
                        <div className="row col-md-12 float-right">
                          &nbsp;
                        </div>
                        <div className="row col-md-5">
                          <div className="col-sm">
                            <div className="form-group">
                              <button type="button" style={{ display: 'none' }} className="btn btn-info action" id="btnReset" onClick={() => confirmAltStatus(global.EventoId, 'inicial')}>Reset</button>
                              &nbsp;
                              <button type="button" className="btn btn-info action" id="btnCliChegou" onClick={() => confirmAltStatus(global.EventoId, 'aguardando')}>Chegou</button>
                              &nbsp;
                              <button type="button" className="btn btn-info " id="btnCliInitAte" onClick={() => confirmAltStatus(global.EventoId, 'atendimento')}>Iniciou</button>
                              &nbsp;
                              <button type="button" className="btn btn-info " id="btnCliFinalizar" onClick={() => startFinalizar(global.EventoId, 'finalizado')}>Revisar e Finalizar</button>
                              &nbsp;
                              <button type="button" className="btn btn-info " id="btnCliNaoFaltou" onClick={() => confirmAltStatus(global.EventoId, 'aguardando')}>Não Faltou</button>
                              &nbsp;
                            </div></div>
                        </div>
                        <div className="row col-md-2">
                          <div className="col-sm">
                            <div className="form-group ">
                              <button type="button" className="btn btn-info action" id="btnMatInsumo" onClick={() => btnLancInsumos(global.EventoId)}>Lançar Insumos</button>
                            </div></div>
                        </div>
                        <div className="row col-md-5" id="btngp01">
                          <div className="col-sm">
                            <div className="form-group float-right ">
                              <button type="button" className="btn btn-info " id="btnWhatsApp" onClick={() => whatsappweb(document.getElementById('inputNameEdit').value, document.getElementById('inputTelEdit').value)} ><i className="fa fa-whatsapp"></i> </button>

                              <button type="button" className="btn btn-info " title="Enviar Confirmação do Agendamento por Whatsapp" id="btnWhatsConf" onClick={() => zapConfirmAgenda(document.getElementById('inputNameEdit').value, document.getElementById('inputTelEdit').value, 0)} ><i className="fa fa-calendar-check-o"></i> </button>

                              <button type="button" className="btn btn-info " id="btnAlterar" onClick={() => ACliAlt(global.EventoId, global.StatusAtu)}>Gravar</button>

                              <button type="button" className="btn btn-info " id="btnCancelamento" onClick={() => confirmCancelamento(global.EventoId)} >Cancelamento</button>

                              <button type="button" className="btn btn-info " id="btnFechar" onClick={CloseadPAction} >Voltar</button>
                            </div></div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
                <footer className=""><div className="float-right d-none d-sm-inline-block" id="byuser"></div></footer>

              </div>

            </section>

          </div>

        </section>
      </div>

    )

  }


  if (rep.length < 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando dados...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {page2()}
        {page3()}
        {ViewAgenda()}
        {InsumoMat()}
        {/* {page3()} */}
        {/* {page4()} */}
        {/* {page5()} */}
      </div>
    )

  }
}

export default Gerador