import React, { Component, useState, useEffect, useRef, useContext, Children } from "react";

function abreviar(str) {

  if (str) {

    const [nome, ...sobrenomes] = str.split(' ');

    const abreviaturas = sobrenomes.reduce((arr, str) => {
      const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
      if (!letraGrande) return arr;
      return arr.concat(`${letraGrande[0]}.`);
    }, []);

    return [nome, ...abreviaturas].join(' ');
  } else {
    return str;
  }
}

function abrevia(str) {
  const nome = str.replace(/\s+/gi, ' ').trim();
  var array_nome = nome.split(' ');
  if (array_nome.length > 2) {
    return array_nome.map((parte, index, nomes) => (index != 1) ? parte : `${parte[0]}.`).join(' ');
  } else {
    return str;
  }
}

function checkOBS(str,obs) {

//console.log("obs", obs)

  if ((obs === null) || (obs === '')) {
    return ;
  } else {
    return ' *obs';
  }
}

function checkPago(idcaixamov) {

//console.log("idcaixamov", idcaixamov)

  if ((idcaixamov === null) || (idcaixamov === '')) {
    return ;
  } else {
    return '💰';
  }
}


function serv(params, type) {
  //console.log("params", params,type)
  let result = '';
  if ((params) && (params !== 'undefined') && (params !== 'Undefined')) {

    result = params;
  } else if (type === 'bloqueado') {

    result = 'Bloqueado'

  } else {

    return 'serviço ?';
  }

  return result
}

export default class CustomEvent extends React.Component {
  render() {
    return (
      <div id="eventlabel">
        <p id="eventlabelTitle">
          {checkPago(this.props.event.idcaixamov)}{abreviar(this.props.title)}&nbsp;<b style={{color: 'navy'}}>{checkOBS(abreviar(this.props.title),this.props.event.obs)}</b>
        </p>
        
        {(serv(this.props.event.services, this.props.event.type)) !== 'serviço ?' &&
          <p id="eventlabelServices">
            {serv(this.props.event.services, this.props.event.type)}
          </p>
        }
        {(serv(this.props.event.services, this.props.event.type)) === 'serviço ?' &&
          <p id="eventlabelNoServices">
            {serv(this.props.event.services, this.props.event.type)}
          </p>
        }
        { <p id="eventlabelID">
            ( {serv(this.props.event.id)} )
          </p>
        }
        {/* <button onClick={() => console.log(this.props)}>ok</button> */}
      </div>
    );
  }
}