import React, { Component } from 'react'
import { Link } from "react-router-dom";
import './menu.css'


function Relatorios() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-file-pdf-o" />
                <p>
                    PDF - Documentos
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/" className="nav-link">
                        <i className="fa fa-pencil-square-o nav-icon" />
                        <p>Estoque
                    </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/" className="nav-link">
                        <i className="fas fa fa-bullhorn nav-icon" />
                        <p>Vendas
                    </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/" className="nav-link">
                        <i className="fas fa fa-book nav-icon" />
                        <p>Clientes
                    </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/" className="nav-link">
                        <i className="fa fa-file-text-o nav-icon" />
                        <p>Comissões
                    </p>
                    </Link>
                </li>
            </ul>

        </li>
    );
}

function MenuGestao() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-handshake-o" />
                <p>
                    Gestão
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                <Link to="/cotacoes-consulta" className="nav-link">
                    <i className="fa fa-inbox nav-icon" />
                    <p>Novas</p>
                </Link>
            </li> */}
                <li className="nav-item">
                    <Link to="/agenda-geral" className="nav-link">
                        <i className="fa fa-calendar nav-icon" />
                        <p>Agenda Geral
                    </p>
                    </Link>
                </li>

            </ul>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/clientes" className="nav-link">
                        <i className="fa fa-users nav-icon" />
                        <p>Clientes
                        {/* <span className="right badge badge-danger">Novo</span> */}
                    </p>
                    </Link>
                </li>

            </ul>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/servicos" className="nav-link">
                        <i className="fa fa-table nav-icon" />
                        <p>Serviços
                        {/* <span className="right badge badge-danger">Novo</span> */}
                    </p>
                    </Link>
                </li>

            </ul>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/produtos" className="nav-link">
                        <i className="fa fa-barcode nav-icon" />
                        <p>Produtos
                        {/* <span className="right badge badge-danger">Novo</span> */}
                    </p>
                    </Link>
                </li>

            </ul>

        </li>
    );
}
function MenuOperacional() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-rocket" />
                <p>
                    Caixa
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/caixa" className="nav-link">
                        <i className="fa fa-money nav-icon" />
                        <p>Receber
                        {/* <span className="right badge badge-danger">Novo</span> */}
                    </p>
                    </Link>
                    <Link to="/caixa-mov" className="nav-link">
                        <i className="fa fa-money nav-icon" />
                        <p>Movimentação
                        {/* <span className="right badge badge-danger">Novo</span> */}
                    </p>
                    </Link>
                    <Link to="/caixa-consulta" className="nav-link">
                        <i className="fa fa-money nav-icon" />
                        <p>Consulta
                        <span className="right badge badge-danger">Novo</span>
                    </p>
                    </Link>
                </li>

            </ul>
        </li>
    );
}

function MenuTarefas() {

    let salto = "1)-Melhore o Cadastro de Clientes\r\n2)-Cadastre mais Produtos\r\n";
    //salto = window.encodeURIComponent(salto);

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-thumb-tack" />
                <p title='Anotação Temporária, não grava !!!'>
                    Anotação Temp.
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
               <li className="nav-item">
               <textarea id="w3review" name="w3review" rows={7} cols={23} style={{resize: 'none', fontSize: '15px'}} defaultValue={salto} />

                </li>
            </ul>
        </li>
    );
}


class Menu extends Component {
    componentDidMount() {
        //window.addEventListener('load', this.handleSignOut);

        // const TOKEN_KEY = "KEY_APP_BGG";
        // const token = localStorage.getItem(TOKEN_KEY);
        // var base64Url = token.split('.')[1];
        // var decodedValue = JSON.parse(window.atob(base64Url));
        // console.log(decodedValue);
        // var novoObj = decodedValue;
        // let nome = novoObj.nome;
        // console.log(nome);

        // let buff = new Buffer(token, 'base64'); 
        // if (typeof Buffer.from === "function") {
        //     // Node 5.10+
        //     buff = Buffer.from(buff, 'base64'); // Ta-da
        // } else {
        //     // older Node versions, now deprecated
        //     buff = new Buffer(buff, 'base64'); // Ta-da
        // } 
        // let text = buff.toString('ascii');
        
    }
    render() {
        //const TOKEN_KEY = "KEY_APP_BGG";
        //const token = localStorage.getItem(TOKEN_KEY);
        //var base64Url = token.split('.')[1];
        //var decodedValue = JSON.parse(window.atob(base64Url));
        //console.log(decodedValue);
        //var novoObj = decodedValue;
        //let nome = novoObj.nome;
        //console.log(nome);
        let itemmenu1;
        let itemmenu2;
        let itemmenu3;
        let itemmenu4;
        let itemmenu5;
        
        if (global.gTipoUser !== 'G') {
            itemmenu1 = <li className="nav-item"><Link to="/" className="nav-link"><i className="fa fa-bar-chart nav-icon" /><p>Vendas</p></Link></li>;
            itemmenu2 = MenuGestao();
            itemmenu3 = Relatorios();
            itemmenu4 = MenuOperacional();
            //itemmenu5 = MenuTarefas();
        } else {
            itemmenu1 = '';
            itemmenu2 = '';
            itemmenu3 = '';
            itemmenu4 = '';
            itemmenu5 = '';
        }


        
        return (
            <div >
                <aside className="main-sidebar sidebar-dark-primary elevation-4"  >
                    {/* Brand Logo */}
                    <Link to="/" className="brand-link">
                        <img src="dist/img/MB_LoGo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">MARCIO BAGAGINE</span>
                    </Link>
                    {/* Sidebar */}


                    <div className="sidebar">
                        <div className="divMenuBgg" style={{ backgroundImage: 'url("/images/bg-1.jpg")' }}></div>


                        {/* Sidebar user panel (optional) */}
                        {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                            </div>
                            <div className="info">
                                <Link to="/" className="d-block">{nome}</Link>
                            </div>
                            {/* <form className="form-inline ml-3">
                                <div className=" input-group input-group-sm">
                                    <input className="form-control form-control-navbar" type="search" placeholder="Pesquisar" aria-label="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-navbar" type="submit">
                                            <i className="fas fa-search" />
                                        </button>
                                    </div>
                                </div>
                            </form> 
                        </div> */}

                        {/* Sidebar Menu */}
                        <nav className="mt-2" >
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                                { /*       <li className="nav-item has-treeview menu-close">
                                    <a href="fake_url" className="nav-link active">
                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p>
                                            Dashboard
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="./index.html" className="nav-link active">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v1</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="./index2.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v2</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="./index3.html" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>Dashboard v3</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                        */}
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Painel Inicial
                                        </p>
                                    </Link>
                                </li>
                                {itemmenu2}
                                {itemmenu4}
                                {itemmenu5}
                                <li className="nav-item has-treeview menu-open" >
                                    <ul className="nav nav-treeview">
                                    </ul>
                                </li>
                                <li className="nav-header"></li>
                                <li className="nav-item">
                                    {/* <Link to="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </Link> */}
                                </li>
                                <li className="nav-header"></li>
                                <li className="nav-item">
                                    {/* <Link to="/logout" className="nav-link">
                                        <i className="nav-icon fas fa-close" />
                                        <p>Sair</p>
                                    </Link> */}
                                </li>
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>


            </div>
        )
    }
}
export default Menu