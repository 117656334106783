
/* eslint-disable */
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet, Redirect, useHistory, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth";
import Header from './Header';
import HeaderProf from './HeaderProf';
import Menu from './Menu';
import MenuProf from './MenuProf';
import Footer from './Footer';
import AgendaGeralView from './visViewAgendaGeral';
import Login from './login';
import ResetSenha from './reset-senha';
import ServicosView from './servicos';
import ProdutosView from './produtos';
import CaixaView from './caixa';
import CaixaMovView from './caixamov';
import CaixaMovConsView from './caixamovcons';
import CaixaMovConsViewProf from './caixamovconsprof';
import ClientesView from './clientes';
import AgendaProfView from './visViewAgendaProf';
import { cl } from "./components/functions";


const TOKEN_KEY = "KEY_APP_BGG";
global.gIdUser = 0;

function b64DecodeUnicode(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

//tamanho da tela do browser
//const deskH = window.innerHeight;        
//const deskW = window.innerWidth;

// function b64EncodeUnicode(str) {
//     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
//         return String.fromCharCode(parseInt(p1, 16))
//     }))
// }

const token = sessionStorage.getItem(TOKEN_KEY);
//cl("Tokennn2",token)
if (sessionStorage.getItem(TOKEN_KEY) == "null") {
  sessionStorage.removeItem(TOKEN_KEY);
  //this.props.history.push("/");
} else if (sessionStorage.getItem(TOKEN_KEY)) {
  const base64Url = token.split('.')[1];
  //cl("base",base64Url);
  const decodedValue = JSON.parse(b64DecodeUnicode(base64Url));
  //console.log("decode",decodedValue);
  const novoObj = decodedValue;
  //cl("novoObj",novoObj);
  ///global.gNome = novoObj[0].nome;
  global.gTipoUser = novoObj[0].tipo;
  global.gIDProf = novoObj[0].idprof;
  //cl("global.gIDProf",global.gIDProf);
  /*   global.gDoc = novoObj[0].doc;
    global.gCond = novoObj[0].cod;
    global.gNomecond = novoObj[0].nomecond;
    global.gEmail = novoObj[0].email;
    global.gIdUser = novoObj[0].id; */
  //global.gLinkBase = "http://adminrl.dyndns.org:8085";
  //cl("gnome",global.gNome);
  //cl("gTipoUser header",global.gTipoUser);
  //cl("gDoc",global.gDoc);
  //cl("gCond",global.gCond);
  //cl("gEmail",global.gEmail);
  /*   global.gListCond = '';
    global.gaListCond = [];
    for (var i in novoObj) {
      if (i > 0) {
          global.gListCond = global.gListCond + ',';
      }
      global.gListCond = global.gListCond + novoObj[i].cod;
    
      global.gaListCond[novoObj[i].cod] =
      {
          "nome": novoObj[i].nomecond,
          "bl": novoObj[i].bloco,
          "unid": novoObj[i].unidade
      };
    
    } */

}


//okkkkkkkkkkkkk
const Headerprops = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    //<Component {...props} />
    <>
      <Header />
    </>
  )} />
)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => isAuthenticated() ? (
    //<Component {...props} />
    <div>
      <Headerprops />
      <Menu />
      <Component />
      <Footer />
    </div>
  ) : (
    <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
  )} />
)

const Private = (Component) => {


  const auth = isAuthenticated();
  console.log(auth);

  return auth ? <Component /> : <Navigate to="/login" />
}

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    //<Component {...props} />
    <div>
      <Login />
    </div>
  )} />
)

const TestHome = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    //<Component {...props} />
    <div>
      <Home />
    </div>
  )} />
)

const AgendaGeral = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    //<Component {...props} />
    <div>
      <AgendaGeralView />
    </div>
  )} />
)


const klinkbase = sessionStorage.getItem('linkbase');
global.gLinkBase = klinkbase;

function Home() {
  return (
    <>
      <Header />
      <Menu />
      <AgendaGeralView />
      <Footer />
    </>
  );
}

function HomeProf() {
  return (
    <>
      <HeaderProf />
      <MenuProf />
      <AgendaProfView />
      <Footer />
    </>
  );
}

function HomeServicos() {
  return (
    <>
      <Header />
      <Menu />
      <ServicosView />
      <Footer />
    </>
  );
}

function HomeBasica() {
  return (
    <>
      <Header />
      <Menu />
      <Footer />
    </>
  );
}

function HomeProdutos() {
  return (
    <>
      <Header />
      <Menu />
      <ProdutosView />
      <Footer />
    </>
  );
}

function HomeCaixa() {
  return (
    <>
      <Header />
      <Menu />
      <CaixaView />
      <Footer />
    </>
  );
}
function HomeCaixaMov() {
  return (
    <>
      <Header />
      <Menu />
      <CaixaMovView />
      <Footer />
    </>
  );
}
function HomeCaixaMovCons() {
  return (
    <>
      <Header />
      <Menu />
      <CaixaMovConsView />
      <Footer />
    </>
  );
}
function HomeCaixaMovConsProf() {
  return (
    <>
      <HeaderProf />
      <MenuProf />
      <CaixaMovConsViewProf />
      <Footer />
    </>
  );
}


function HomeClientes() {
  return (
    <>
      <Header />
      <Menu />
      <ClientesView />
      <Footer />
    </>
  );
}


const RoutesExp = () => (


  <BrowserRouter>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/reset-senha/:idchave" element={<ResetSenha />} />
      <Route element={<RequireAuth />}>
        <Route element={<TipoUser />}>
          <Route path="" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="agenda-geral" element={<Home />} />
          <Route path="produtos" element={<HomeProdutos />} />
          <Route path="clientes" element={<HomeClientes />} />
          <Route path="caixa" element={<HomeCaixa />} />
          <Route path="caixa-mov" element={<HomeCaixaMov />} />
          <Route path="caixa-consulta" element={<HomeCaixaMovCons />} />
          <Route path="servicos" element={<HomeServicos />} />
        </Route>
        <Route path="home" element={<HomeProf />} />
        <Route path="prof-consulta" element={<HomeCaixaMovConsProf />} />
      </Route>
    </Routes>
  </BrowserRouter>

);

function RequireAuth() {
  let auth = isAuthenticated();


  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

function TipoUser() {
  //console.log("global.gTipoUser ", global.gTipoUser)

  if (global.gTipoUser === 'A') {

    return <Outlet />;
  } else
    if (global.gTipoUser === 'O') {

      return <Outlet />;
    } else
      if (global.gTipoUser === 'P') {

        return <Navigate to="/home" />;
      } else
        if (global.gTipoUser === '') {

          return <Navigate to="/home" />;
        } else
          if (!global.gTipoUser) {

            return <Navigate to="/home" />;
          }

  return <Outlet />;
}


function AuthLogin() {
  let auth = isAuthenticated();


  if (auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" />;
  }

  return <Outlet />;
}


export default RoutesExp;