import React, { useState, useEffect, useRef, useContext } from "react";
//import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/MB_Logo.png";
import './caixamovconsprof.css';
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment, { now } from "moment";
import dateFormat from "dateformat";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
import date from 'date-and-time';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { DateTime } from 'luxon'
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}


global.gLog = [];
global.gOpencaixa = 1;
global.gSubTotal = 0;
global.gAlertaCaixaMov = 0;

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};


function Gerador() {

  const formatData = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(0, 4)
    let result = dia + '/' + mes + '/' + ano

    return result; //return the contents of the cell;  
  }

  const formatDataCurta = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(0, 4)
    let result = dia + '/' + mes

    return result; //return the contents of the cell;  
  }

  const adultCalc = function (values, data, calcParams) {
    //values - array of column values
    //data - all table data
    //calcParams - params passed from the column definition object


    var calc = 0;

    values.forEach(function (value) {
      calc = calc + realParaNumber(value)
    });
    let result = calc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    return result;
  }

  const [FinalizaPag, setFinalizaPag] = useState([]);
  const [Lanc, setLanc] = useState([]);
  const [LancSelected, setLancSelected] = useState([]);
  const [AtuRelComiss, setAtuRelComiss] = useState('');
  const [RelComiss, setRelComiss] = useState('');
  const [AtuRelComiss2, setAtuRelComiss2] = useState('');
  const [RelComiss2, setRelComiss2] = useState('');
  const [AtuRelComiss3, setAtuRelComiss3] = useState('');
  const [RelComiss3, setRelComiss3] = useState('');


  const isFirstRunAtuRelAtuComiss = useRef(true);
  const isFirstRunAtuRelAtuComiss2 = useRef(true);
  const isFirstRunAtuRelAtuComiss3 = useRef(true);
  const isFirstRunFinalizaPag = useRef(true);

  moment.updateLocale('pt-br', {
    week: {
      dow: 1,
      //doy: 1,
    },
  });



  useEffect(() => {


  }, [Lanc])

  useEffect(() => {

  }, [RelComiss])

  useEffect(() => {

  }, [RelComiss2])

  useEffect(() => {

  }, [RelComiss3])


  useEffect(() => {
/*     if (isFirstRunAtuRelAtuComiss.current) {
      isFirstRunAtuRelAtuComiss.current = false;
      //cl("FirstRunDados false")

      return;
    } */
    let ignore = false;

    //const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('dtinit', moment().day(1).format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('dtfim', moment().day(7).format('YYYY-MM-DD 23:59:59'));
      bodyFormData.set('prof', global.gIDProf);
      bodyFormData.set('pdeb', 1.79);
      bodyFormData.set('pcred', 2.55);
      bodyFormData.set('pcredp', 4.9);
      bodyFormData.set('antecip', 3.6);
      bodyFormData.set('caixa', global.gCaixaId);
      
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaRelComissProf.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setRelComiss(redata2);
        } else {
          setRelComiss(redata);

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('imgloadingRelComissResumo');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata()
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuRelComiss])

  useEffect(() => {
/*     if (isFirstRunAtuRelAtuComiss2.current) {
      isFirstRunAtuRelAtuComiss2.current = false;
      //cl("FirstRunDados false")

      return;
    } */
    let ignore = false;

    //const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('dtinit', moment().subtract(6, "days").day(1).format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('dtfim', moment().subtract(6, "days").day(7).format('YYYY-MM-DD 23:59:59'));
      bodyFormData.set('prof', global.gIDProf);
      bodyFormData.set('pdeb', 1.79);
      bodyFormData.set('pcred', 2.55);
      bodyFormData.set('pcredp', 4.9);
      bodyFormData.set('antecip', 3.6);
      bodyFormData.set('caixa', global.gCaixaId);
      
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaRelComissProf.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setRelComiss2(redata2);
        } else {
          setRelComiss2(redata);

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('imgloadingRelComissResumo2');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata()
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuRelComiss2])

  useEffect(() => {
/*     if (isFirstRunAtuRelAtuComiss3.current) {
      isFirstRunAtuRelAtuComiss3.current = false;
      //cl("FirstRunDados false")

      return;
    } */
    let ignore = false;

    //const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('dtinit', moment().subtract(13, "days").day(1).format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('dtfim', moment().subtract(13, "days").day(7).format('YYYY-MM-DD 23:59:59'));
      bodyFormData.set('prof', global.gIDProf);
      bodyFormData.set('pdeb', 1.79);
      bodyFormData.set('pcred', 2.55);
      bodyFormData.set('pcredp', 4.9);
      bodyFormData.set('antecip', 3.6);
      bodyFormData.set('caixa', global.gCaixaId);
      
      const urlfull = 'https://api.espacomarciobagagine.com.br/listaRelComissProf.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setRelComiss3(redata2);
        } else {
          setRelComiss3(redata);

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('imgloadingRelComissResumo3');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata()
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuRelComiss3])




  useEffect(() => {
    if (isFirstRunFinalizaPag.current) {
      isFirstRunFinalizaPag.current = false;
      //cl("FirstRunDados false")
      return;
    }

  }, [FinalizaPag])



  function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }



  const ref = null;
  function paramLookup() {
    //do some processing and return the param object
    return { param1: "green" };
  }

  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 46, visible: true, headerSort: false, responsive: 0, headerSortStartingDir: "asc" },
    /* { title: 'Caixa', field: 'open', hozAlign: 'center', width: 120, visible: true, responsive: 0, headerSort: false }, */
    { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 250, responsive: 0, headerSort: false, headerFilter: "input" },
    { title: 'Op', field: 'tipo', hozAlign: "center", width: 10, responsive: 0, headerSort: false, },
    { title: 'Total R$', field: 'valor', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Dinheiro', field: 'cash', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Troco', field: 'troco', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Débito', field: 'debito', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Credito', field: 'creditoavista', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'CredParc.', field: 'creditoparc', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'PIX', field: 'pix', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'CC', field: 'cc', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    /*  { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }, */
  ];
  const columnsRel = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    //{ title: 'Data', field: 'agenda', align: 'center', width: 52, responsive: 0, resizable: false,formatter: formatDataCurta },
    { title: 'Receber', field: 'receber', hozAlign: "right", width: 88, responsive: 0, resizable: false,headerSort: false, bottomCalc: adultCalc },
    { title: 'Cliente', field: 'cliente', hozAlign: "left", width: 160, responsive: 0, resizable: false,headerSort: false },
    { title: 'Serviço', field: 'serv', hozAlign: "left", width: 150, responsive: 0, resizable: false,headerSort: false },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 46, visible: true, headerSort: false, responsive: 0, headerSortStartingDir: "asc" },
    /* { title: 'Caixa', field: 'open', hozAlign: 'center', width: 120, visible: true, responsive: 0, headerSort: false }, */
    /* { title: 'Profissional', field: 'prof', hozAlign: "left", width: 125, responsive: 0, headerSort: false }, */
    { title: 'Total R$', field: 'valor', hozAlign: "right", width: 70, responsive: 0, resizable: false,headerSort: false },
    { title: 'Insumos R$', field: 'insumos', hozAlign: "right", width: 80, responsive: 0, resizable: false,headerSort: false },
    { title: 'Getnet %', field: 'getnet', hozAlign: "center", width: 80, responsive: 0, resizable: false,headerSort: false },
    { title: 'Antecip %', field: 'antecip', hozAlign: "center", width: 80, responsive: 0, resizable: false,headerSort: false },
    { title: '%', field: 'porc', hozAlign: "right", width: 40, responsive: 0, resizable: false,headerSort: false },
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    /*  { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }, */
  ];

  const columnsRelResumo = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    //    { title: 'Id', field: 'id', hozAlign: 'center', width: 10, visible: true, headerSort: false, responsive: 0, headerSortStartingDir: "asc" },
    //    { title: 'Agenda', field: 'agenda' ,align: 'center', width: 90, responsive:0, formatter:formatData},
    /* { title: 'Caixa', field: 'open', hozAlign: 'center', width: 120, visible: true, responsive: 0, headerSort: false }, */
    //    { title: 'Descrição', field: 'cliente', hozAlign: "left", width: 250, responsive: 0, headerSort: false },
    { title: 'Profissional', field: 'prof', hozAlign: "left", width: 125, responsive: 0, headerSort: false },
    //    { title: 'Serviço', field: 'serv', hozAlign: "left", width: 150, responsive: 0, headerSort: false },
    //    { title: 'Total R$', field: 'valor', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    //    { title: 'Insumos R$', field: 'insumos', hozAlign: "right", width: 80, responsive: 0, headerSort: false },
    //    { title: 'Getnet %', field: 'getnet', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //    { title: 'Antecip %', field: 'antecip', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //    { title: '%', field: 'porc', hozAlign: "right", width: 40, responsive: 0, headerSort: false },
    { title: 'Total R$', field: 'vendas', hozAlign: "right", width: 100, responsive: 0, headerSort: false },
    { title: 'Débitos R$', field: 'debitos', hozAlign: "right", width: 100, responsive: 0, headerSort: false },
    {
      title: 'Receber R$', field: 'receber', hozAlign: "right", width: 100, responsive: 0, headerSort: false, formatter: function (cell, formatterParams) {
        var value = cell.getValue();
        return "<span style='color:#2b622e; font-weight:bold;'>" + value + "</span>";

      }
    },
    //{ title: 'Classificação', field: 'star', hozAlign: "right", width: 90, responsive: 0, headerSort: false, formatter:'star', formatterParams:{stars:5} },
    { title: 'Vendas', field: 'progress', hozAlign: "left", width: 90, responsive: 0, headerSort: false, formatter: 'progress', formatterParams: { color: ["rgb(255,0,0)", "#38b544f5", "#00fd00"] } },
    { title: 'Rentabilidade', field: 'rentprogress', hozAlign: "left", width: 90, responsive: 0, headerSort: false, formatter: 'progress', formatterParams: { color: ["rgb(255,0,0)", "#38b544f5", "#00fd00"] } }
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    /*  { title: 'Editar', formatter: editIcon, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }, */
  ];




  const options = {
    autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    //resizableRows:true,
    //invalidOptionWarnings: false,
    //responsiveLayoutCollapseUseFormatters:true,
    //responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    //responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  const optionsRel = {
    layout:"fitDataFill",
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    //groupBy: "agenda",
    groupBy: function(data){
      //data - the data object for the row being grouped
      var semana = ["Dom", "Seg", "Ter", "Quar", "Qui", "Sex", "Sáb"];
      let dtstr = dateFormat(data.agenda, "dd/mm/yyyy");
      let dt = new Date(data.agenda);
      let dtsem = dt.getDay();
      let result = semana[dtsem] + " - "+ dtstr;
      return result; //groups by data and age
  },
  groupStartOpen:true,
    groupHeader: function (value, count, data, group) {
      //value - the value all members of this group share
      //count - the number of rows in this group
      //data - an array of all the row data objects in this group
      //group - the group component for the group

      if (count <= 1) {

        return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>";
      } else {

        return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " items)</span>";
      }
    },
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:"prof",
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  const optionsRelResumo = {
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    /*     printAsHtml:true,
        printHeader:"<h1>Resumo nesta Semana<h1>",
        printFooter:"<h2>--<h2>",  */
    clipboard: true,
    /*     groupBy:"prof",
        groupHeader:function(value, count, data, group){
          //value - the value all members of this group share
          //count - the number of rows in this group
          //data - an array of all the row data objects in this group
          //group - the group component for the group
      
          if (count <= 1){
    
            return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>";
          } else {
            
            return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " items)</span>";
          }
      }, */
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:"prof",
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    /*     initialSort: [
          { column: "id", dir: "asc" }, //sort by this first
        ] */
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {


    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = '';

    let einputCodBarras = document.getElementById('inputCodBarras');
    einputCodBarras.value = '';
    einputCodBarras.focus();

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';


    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = '';

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = '';

    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = '';


    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }



  function btnFinalizarPG(params) {

    let einputVCash = document.getElementById('inputVCash');
    let rVCash = realParaNumber(einputVCash.value);
    let einputVTroco = document.getElementById('inputVTroco');
    let rVTroco = realParaNumber(einputVTroco.value);
    let einputVCD = document.getElementById('inputVCD');
    let rVCD = realParaNumber(einputVCD.value);
    let einputVCC = document.getElementById('inputVCC');
    let rVCC = realParaNumber(einputVCC.value);
    let einputVCCx = document.getElementById('inputVCCx');
    let rVCCx = realParaNumber(einputVCCx.value);
    let einputVPix = document.getElementById('inputVPix');
    let rVPIX = realParaNumber(einputVPix.value);
    let einputVConta = document.getElementById('inputVConta');
    let rConta = realParaNumber(einputVConta.value);


    let somaFPG = rConta;
    somaFPG = somaFPG + rVCash;
    somaFPG = somaFPG + rVCD;
    somaFPG = somaFPG + rVCC;
    somaFPG = somaFPG + rVCCx;
    somaFPG = somaFPG + rVPIX;

    //cl("somaFPG",somaFPG);
    //cl("global.gSubTotal",somaFPG);

    let descricao = 'Pagamento - Agenda (' + LancSelected.toString() + ')';
    if ((global.gSubTotal === somaFPG) || (global.gSubTotal < somaFPG)) {



      confirmFinalizarPG(descricao, global.gSubTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta);
    } else {
      //window.alert("A Soma das formas de Pagamento não confere, revise ou preencha!")
      modalBase("Atenção", "A Soma das formas de Pagamento não confere, revise ou preencha!");
    }





  }

  function confirmFinalizarPG(desc, rVTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta) {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirmação para Finalizar Pagamento</h4>
            </div>
            <div className="modal-body">
              <p>Após finalizar o Pagamento não será mais possível alterar!</p>
            </div>
            <div className="modal-body">
              <p>Tem Certeza ?</p>
            </div>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

                setFinalizaPag({
                  cliente: '',
                  caixa: global.gCaixaId,
                  idagenda: LancSelected.toString(),
                  tipo: 'C',
                  desc: desc,
                  valor: rVTotal,
                  cash: rVCash,
                  troco: rVTroco,
                  debito: rVCD,
                  creditoavista: rVCC,
                  creditoparc: rVCCx,
                  pix: rVPIX,
                  cc: rConta
                })

                onClose();
              }}
            >
              Sim, finalizar!
            </button>
          </div>
        );
      }
    });
  }

  function confirmAltLanc(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{descricao}</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Qtd.</label>
                <div className="input-group-prepend">
                  <input type="number" className="form-control" autocomplete="off" id="inputMQtd" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor R$</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValor" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');


/*                 setEditLancamentos({
                  id: id,
                  valor: realParaNumber(einputMValor.value),
                  qtd: einputMQtd.value,
                  idagenda: idagenda
                }) */

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }

  function btnconfirmFechaCx(params) {

    confirmFechaCx();
    let einputCxTValor = document.getElementById('inputCxTValor');
    einputCxTValor.value = global.gCash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  }

  function calcProxCx(vtotal, sangria) {

    let sang = realParaNumber(document.getElementById(sangria).value);
    let result = global.gCash - sang;
    if (result >= 0) {

      document.getElementById(vtotal).value = result.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    } else {
      window.alert("O Valor da Sangria não pode ser maior que o Saldo Atual !");
      document.getElementById(sangria).value = global.gCash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      result = 0;
      document.getElementById(vtotal).value = result.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    //cl(vtotal, sangria);

  }

  function confirmFechaCx(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;


    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Fechamento de Caixa - Sangria</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="lEmail3">Saldo Atual</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" disabled autocomplete="off" id="inputCxTValor" />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lEmail3">Sangria</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" onChange={() => calcProxCx('inputCxProx', 'inputCxSangria')} autocomplete="off" id="inputCxSangria" />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lEmail3">Próximo Caixa</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" disabled autocomplete="off" id="inputCxProx" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputCxSangria = document.getElementById('inputCxSangria');
                let einputCxProx = document.getElementById('inputCxProx');

                if (!einputCxSangria.value) {
                  einputCxSangria.focus();
                  return
                }
                if (!einputCxProx.value) {
                  einputCxSangria.focus();
                  return
                }

                // return


/*                 setFechaCaixa({
                  sangria: realParaNumber(einputCxSangria.value),
                  vfinal: realParaNumber(einputCxProx.value),
                }) */

                onClose();
              }}
            >
              Fechar Caixa
            </button>
          </div>
        );
      }
    });
  }

  function selectChange(params) {

    let einputMIdProf = document.getElementById('inputMIdProf');
    let einputMDtVenc = document.getElementById('inputMDtVenc');
    let eSelectTipo = document.getElementById('SelectTipo');
    let eSelectFpg = document.getElementById('SelectFpg');

    if (eSelectTipo.value === "V") {
      einputMIdProf.disabled = false;
      einputMDtVenc.disabled = false;
      einputMIdProf.focus();
      eSelectFpg.value = "cash";
      eSelectFpg.disabled = true;
    } else {

      einputMIdProf.disabled = true;
      einputMDtVenc.disabled = true;
      eSelectFpg.disabled = false;
    }

  }

  function selectProf(params) {
    let einputMDesc = document.getElementById('inputMDesc');
    let einputMIdProf = document.getElementById('inputMIdProf');

    einputMDesc.value = '(' + einputMIdProf.children[einputMIdProf.selectedIndex].textContent + ') VALE : ' + einputMDesc.textContent;

  }

  function confirmMovCx(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Lançamento manual/avulso no Caixa</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Tipo Movimentção</label>
                <div className="input-group-prepend">
                  <select className="form-control select2 select2-hidden-accessible" onChange={() => selectChange()} id="SelectTipo" tabIndex={-1} aria-hidden="true">
                    <option >selecione</option>
                    <option value="E">Entrada</option>
                    <option value="S">Saída</option>
                    <option value="V">Vale Profissional</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">ID Prof.</label>
                <div className="input-group-prepend">
                  <select className="form-control select2 select2-hidden-accessible" onChange={() => selectProf()} disabled id="inputMIdProf" tabIndex={-1} aria-hidden="true">
                    <option >selecione</option>
                    <option value="6">César</option>
                    <option value="8">Gal</option>
                    <option value="2">Jorge</option>
                    <option value="9">Josy</option>
                    <option value="5">Júlio</option>
                    <option value="3">Kaká</option>
                    <option value="17">Lucas</option>
                    <option value="4">Rey</option>
                    <option value="10">Val</option>
                    <option value="1">Márcio Bagagine</option>
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Vencimento</label>
                <div className="input-group-prepend ">
                  <input type="date" className="form-control" disabled autocomplete="off" id="inputMDtVenc" />
                </div>
              </div>

            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="lEmail3">Descrição</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMDesc" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor R$</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValor" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Forma de Pagamento</label>
                <div className="input-group-prepend">
                  <select className="form-control select2 select2-hidden-accessible" id="SelectFpg" tabIndex={-1} aria-hidden="true">
                    <option >selecione</option>
                    <option value="cash">Dinheiro</option>
                    <option value="debito">Débito</option>
                    <option value="creditoavista">Credito à Vista</option>
                    <option value="creditoparc">Crédito Parc.</option>
                    <option value="pix">PIX</option>
                    <option value="cc">CC</option>
                  </select>
                </div>
              </div>

            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let eSelectTipo = document.getElementById('SelectTipo');
                let eSelectFpg = document.getElementById('SelectFpg');
                let einputMValor = document.getElementById('inputMValor');
                let einputMDesc = document.getElementById('inputMDesc');
                let einputMIdProf = document.getElementById('inputMIdProf');
                let einputMDtVenc = document.getElementById('inputMDtVenc');

                if (eSelectTipo.value === 'selecione') {
                  eSelectTipo.focus();
                  return
                }
                if ((eSelectTipo.value === 'V') && (!einputMIdProf.value)) {
                  einputMIdProf.focus();
                  return
                }
                if ((eSelectTipo.value === 'V') && (!einputMDtVenc.value)) {
                  einputMDtVenc.focus();
                  return
                }

                if (!einputMDesc.value) {
                  einputMDesc.focus();
                  return
                }

                if (!einputMValor.value) {
                  einputMValor.focus();
                  return
                }

                if (eSelectFpg.value === 'selecione') {
                  eSelectFpg.focus();
                  return
                }

                //return


/*                 setAddLancManual({
                  tipo: eSelectTipo.value,
                  desc: einputMDesc.value,
                  valor: realParaNumber(einputMValor.value),
                  idprof: einputMIdProf.value,
                  Fpg: eSelectFpg.value,
                  dtvenc: einputMDtVenc.value
                }) */

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }


  function realParaNumber(texto) {
    //cl("realParaNumber",texto)
    if (texto === '') {
      return 0
    }
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    //cl("valor",valor);

    return valor;
  }

  function ajustaInputMoney(params) {

    let eleVUnit = document.getElementById('inputValorTroco');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  }

  function CalcSimule(params) {

    /*     var iNf_valor = document.getElementById("inputNf_valor");
        let V_Nf_valor = realParaNumber(iNf_valor.value);
        if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    var iVlr_pago = document.getElementById("inputValor");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    /* 
        var iDif = document.getElementById("inputDif");
        let V_dif = realParaNumber(iDif.value);
        if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     var iPcc = document.getElementById("inputPcc");
        let V_Pcc = realParaNumber(iPcc.value);
        if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
          V_dif = V_pago - V_Nf_valor;
          if (V_dif > 0) {
            iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */
    /* 
        if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
          V_Pcc = V_Nf_valor * 0.045;
          if (V_Pcc > 0) {
            iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */




  }

  function validaOpenCaixa(params) {

    let einputDtCx = document.getElementById('inputDtCx');
    if (!einputDtCx.value) {
      window.alert('Selecione a Data de Abertura !!!')
      einputDtCx.focus();
      return
    };





    modalCaixaOpen(document.getElementById('inputDtCx').value, document.getElementById('inputValorTroco').value);

  }

  function modalBase(h1, p, p2, focus, inputquery) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{h1}</h4>
            </div>
            <div className="modal-body">
              <p>{p}</p>
            </div>
            <div className="modal-body">
              <p>{p2}</p>
            </div>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button id="btnModal"
              className="btn bg-gradient-info float-right"
              onClick={() => {
                if (focus) {
                  document.getElementById(focus).focus();
                  // cl("focus")
                }
                if (inputquery) {
                  let inputM = document.querySelectorAll('input');
                  //cl("inputmodal",inputM)
                  inputM[inputquery].focus();
                }

                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div >
        );
      }
    });
  }

  function modalCaixaOpen(data, troco) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Abertura de Caixa</h4>
            </div>
            <div className="modal-body">
              <p>Tem certeza que deseja realizar a Abertura para o</p>
              <p>Dia {moment(data).format("DD/MM/YYYY")} com Troco de {troco} ?</p>
            </div>
            <button className="btn bg-gradient-danger btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

/*                 setopenCaixa({
                  open: data,
                  troco: troco,
                  user: global.gIdUser
                }) */
                onClose();

              }}
            >
              Sim, abrir agora!
            </button>
          </div>
        );
      }
    });
  }

  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }


  function testeImp(params) {
    var conteudo = document.getElementById('cardSelCaixa').innerHTML,
      tela_impressao = window.open('about:blank');

    tela_impressao.document.write(conteudo);
    tela_impressao.window.print();
    //tela_impressao.window.close();

  }

  function printReactTable(params) {


    window.print()

  }

  function pageRelComiss(params) {
    window.onload = function () {
      //cl("carregou");
    };
    return (
      <div id="panelRelComiss" name="panelRelComiss" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-primary cardAddBgg " id="cardRelComiss" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloRelComiss">Relatório de Conferência Simples<br></br>*pode sofrer alteração sem aviso prévio</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse" >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelCaixaBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        {/* <button className="btn bg-gradient-info btn-sm no-print" onClick={printReactTable}>Imprimir</button> */}

                        <label id="labelReactTabulatorCxMov" htmlFor="lNome">Apontamentos Localizados nesta Semana ({moment().day(1).format('DD/MM')} à {moment().day(7).format('DD/MM/YYYY')})</label>

                        <img id="imgloadingRelComissResumo" style={{ display: '' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulatorCxMovProf" >
                          <ReactTabulator
                            name="rcardSelCaixaBody"
                            id="rcardSelCaixaBody"
                            // ref={(ref)}
                            columns={columnsRel}
                            data={RelComiss}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={optionsRel}
                            events={{/* renderComplete: function (data) {
                              console.log('renderComplete');
                            },
                            tableBuilt: function (data) {
                              console.log('tableBuilt');
                            } */}
                            }
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                        </div>
                        <div><br></br></div>
                        <label id="labelReactTabulatorCxMov" htmlFor="lNome">Semana de ({moment().subtract(6, "days").day(1).format('DD/MM')} à {moment().subtract(6, "days").day(7).format('DD/MM/YYYY')})</label>

                        <img id="imgloadingRelComissResumo2" style={{ display: '' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulatorCxMovProf" >
                          <ReactTabulator
                            name="rcardSelCaixaBody"
                            id="rcardSelCaixaBody"
                            // ref={(ref)}
                            columns={columnsRel}
                            data={RelComiss2}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={optionsRel}
                            events={{/* renderComplete: function (data) {
                              console.log('renderComplete');
                            },
                            tableBuilt: function (data) {
                              console.log('tableBuilt');
                            } */}
                            }
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                        </div>
                        <div><br></br></div>
                        <label id="labelReactTabulatorCxMov" htmlFor="lNome">Semana de ({moment().subtract(13, "days").day(1).format('DD/MM')} à {moment().subtract(13, "days").day(7).format('DD/MM/YYYY')})</label>

                        <img id="imgloadingRelComissResumo3" style={{ display: '' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulatorCxMovProf" >
                          <ReactTabulator
                            name="rcardSelCaixaBody"
                            id="rcardSelCaixaBody"
                            // ref={(ref)}
                            columns={columnsRel}
                            data={RelComiss3}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={optionsRel}
                            events={{/* renderComplete: function (data) {
                              console.log('renderComplete');
                            },
                            tableBuilt: function (data) {
                              console.log('tableBuilt');
                            } */}
                            }
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }



  function testCaixa(params) {




  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      //global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    btnFPGresetAll();
    dualChangeLancReset();
    AtuRefresh();
    let ecardSelCaixa = document.getElementById('cardSelCaixa');
    ecardSelCaixa.classList.remove("collapsed-card");

    let ecardSelCaixaBody = document.getElementById('cardSelCaixaBody');
    if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = ''; }

    let ecardSelFech = document.getElementById('cardSelFech');
    if (ecardSelFech) { ecardSelFech.style.display = 'none'; }

  }

  function AtuRefresh(params) {

    //setAtuLanc({ '0': '0' });

  }

  function btnFPGresetAll(params) {
    let einputVCash = document.getElementById('inputVCash');
    if (einputVCash) { einputVCash.value = ''; }
    let einputVTroco = document.getElementById('inputVTroco');
    if (einputVTroco) { einputVTroco.value = ''; }
    let einputVCD = document.getElementById('inputVCD');
    if (einputVCD) { einputVCD.value = ''; }
    let einputVCC = document.getElementById('inputVCC');
    if (einputVCC) { einputVCC.value = ''; }
    let einputVCCx = document.getElementById('inputVCCx');
    if (einputVCCx) { einputVCCx.value = ''; }
    let einputVPix = document.getElementById('inputVPix');
    if (einputVPix) { einputVPix.value = ''; }
    let einputVConta = document.getElementById('inputVConta');
    if (einputVConta) { einputVConta.value = ''; }

    /*     let einputVCash = document.getElementById('inputVCash');
        if (einputVCash) { einputVCash.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCD = document.getElementById('inputVCD');
        if (einputVCD) { einputVCD.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCC = document.getElementById('inputVCC');
        if (einputVCC) { einputVCC.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCCx = document.getElementById('inputVCCx');
        if (einputVCCx) { einputVCCx.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVPix = document.getElementById('inputVPix');
        if (einputVPix) { einputVPix.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVConta = document.getElementById('inputVConta');
        if (einputVConta) { einputVConta.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */
  }

  function btnFPG(params) {
    btnFPGresetAll();
    let eparams = document.getElementById(params);
    if (eparams) {
      eparams.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      eparams.focus();
    }

  }

  function calcTroco(params) {
    let einputVCash = document.getElementById('inputVCash');
    let einputVTroco = document.getElementById('inputVTroco');
    let cash = realParaNumber(einputVCash.value);
    let troco = 0;
    //cl(LancSelected);
    troco = cash - global.gSubTotal
    if (troco > 0) {
      let einputVCashFormat = cash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      einputVCash.value = einputVCashFormat;
      //cl(einputVCashFormat);
      einputVTroco.value = troco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    } else {
      let trocozero = 0;
      let einputVCashFormat = einputVCash.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      einputVCash.value = einputVCash.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      //cl(einputVCashFormat);
      einputVTroco.value = trocozero.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    //cl("troco",troco);

  }

  function dualChangeLanc(params) {
    let eimgloadingReactTabulatorCxMov = document.getElementById('imgloadingReactTabulatorCxMov');
    if (eimgloadingReactTabulatorCxMov) { eimgloadingReactTabulatorCxMov.style.display = ''; }
    global.gParams = params;
    setLancSelected(params)
    //cl("params", params)
    //setAtuLancamentos({ idagenda: global.gParams })
    setAtuRelComiss({ idagenda: global.gParams })
    //setAtuRelComissResumo({ idagenda: global.gParams })
    let qtd = 0;
    var i;
    var j;

    for (i = 0; i < params.length; i++) {
      // cl("params_i", params[i])
      let idfilter = params[i];
      var arrFound = Lanc.filter(function (item, num) {
        return item.value === idfilter;
      });
      let idcliente = arrFound[0]['cliente'];
      var arrFoundCli = Lanc.filter(function (item, num) {
        return item.cliente === idcliente;
      });

      // cl("arrFound", arrFound[0]['cliente'])
      // cl("arrFoundCli", arrFoundCli)

      for (j = 0; j < Lanc.length; j++) {
        //cl("compLanc",arrFound[0]['cliente'])
        if (Lanc[j]['cliente'] === arrFound[0]['cliente']) {
          //cl("Somou Lanc")
          qtd = qtd + 1;
        }
      }
    }

    if (qtd > 1) {
      //window.alert("Atenção : existem mais de um fechamento em aberto deste cliente! ")
      setLanc(arrFoundCli);
      //cl("params.length", params.length)
      // cl("arrFoundCli.length", arrFoundCli.length)

      if (arrFoundCli.length !== params.length) {

        modalBase("Atenção", "Existem mais de um fechamento em aberto deste cliente!");

      } else {
        qtd = 1;
      }
    }



    if (params.length > 0) {


      let ecardSelCaixa = document.getElementById('cardSelCaixa');

      let ecardSelCaixaBody = document.getElementById('cardSelCaixaBody');
      if (qtd > 1) {
        if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = ''; }
        ecardSelCaixa.classList.remove("collapsed-card");
      } else {
        if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = 'none'; }
        ecardSelCaixa.classList.add("collapsed-card");

      }

      let ecardSelFech = document.getElementById('cardSelFech');
      if (ecardSelFech) { ecardSelFech.style.display = ''; }


    } else {

      let ecardSelFech = document.getElementById('cardSelFech');
      if (ecardSelFech) { ecardSelFech.style.display = 'none'; }

    }


  }

  function keyDetect(params) {

    if (params === "Enter") {

      calcTroco();

      //  cl("key", params)
    }


  }

  function dualChangeLancReset(params) {
    global.gParams = [];
    setLancSelected();
    //cl("params", params)
    //setAtuLancamentos({ idagenda: global.gParams })
    setAtuRelComiss({ idagenda: global.gParams })
    //setAtuRelComissResumo({ idagenda: global.gParams })
    AtuRefresh();

  }



  return (
    <div className="content-wrapper">
      {pageRelComiss()}
      {/* {testCaixa()} */}
    </div>
  )


}

export default Gerador   