import moment from "moment";
import React, { useState, useEffect } from "react";
import Loading from "./assets/loading4.gif";

function RBCToolbar(props) {
  ////console.log("props", props)
  const [viewState, setViewState] = useState("day");

  useEffect(() => {

    let inputDtCurrent = document.getElementById('inputDtCurrent');
    if (inputDtCurrent) { inputDtCurrent.value = moment(props.date).format("YYYY-MM-DD"); }

    let ebtnDia = document.getElementById('btnDia');
    let ebtnSemana = document.getElementById('btnSemana');
    let ebtnAgenda = document.getElementById('btnAgenda');

    if (viewState === "agenda") {
      ebtnDia.classList.remove('btnselected');
      ebtnSemana.classList.remove('btnselected');
      ebtnAgenda.classList.add('btnselected');
      
    } else if (viewState === "week") {
      ebtnDia.classList.remove('btnselected');
      ebtnSemana.classList.add('btnselected');
      ebtnAgenda.classList.remove('btnselected');
      
    } else {
      ebtnDia.classList.add('btnselected');
      ebtnSemana.classList.remove('btnselected');
      ebtnAgenda.classList.remove('btnselected');
      
    }


  }, [viewState])

  const getCustomToolbar = () => {
    // const toolbarDate = props.date;
    const goToDayView = () => {
      props.onView("day");
      setViewState("day");
    };
    const goToWeekView = () => {
      props.onView("week");
      setViewState("week");
    };
    const goToMonthView = () => {
      props.onView("month");
      setViewState("month");
    };
    const goToAgendaView = () => {
      props.onView("agenda");
      setViewState("agenda");
    };
    const goToBack = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "agenda") {
        newDate = moment(mDate).add('month', -1);
        props.onNavigate("prev", newDate);
        if(moment().add(-1, 'months') >= moment(newDate)){
          global.dtInicioPesqAgenda = newDate.format("YYYY-MM-DD");
          //console.log("dtInicioPesqAgenda1",global.dtInicioPesqAgenda);
        }
       // //console.log("prev", newDate)
       // //console.log("props.date", props.date)
      } else if (view === "week") {
        newDate = moment(mDate, 'YYYY-MM-DD HH:mm:ss').add('day', -7);
        props.onNavigate("prev", newDate);
        if(moment().add(-1, 'months') >= moment(newDate)){
          global.dtInicioPesqAgenda = newDate.format("YYYY-MM-DD");
          //console.log("dtInicioPesqAgenda2",global.dtInicioPesqAgenda);
        }
      } else {
        newDate = moment(mDate, 'YYYY-MM-DD HH:mm:ss').add('day', -1);
        props.onNavigate("prev", newDate);
        if(moment().add(-1, 'months') >= moment(newDate)){
          global.dtInicioPesqAgenda = newDate.format("YYYY-MM-DD");
          let eleLoading = document.getElementById('divLoad');
          if (eleLoading) { eleLoading.style.display = '' };
          //console.log("dtInicioPesqAgenda3",global.dtInicioPesqAgenda);
        }
      }

    };
    const goToNext = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "agenda") {
        newDate = moment(mDate).add('month', 1);
        props.onNavigate("prev", newDate);
        if(moment().add(-1, 'months') >= moment(newDate)){
          global.dtInicioPesqAgenda = newDate.format("YYYY-MM-DD");
          //console.log("dtInicioPesqAgenda",global.dtInicioPesqAgenda);
        }
        ////console.log("prev", newDate)
      } else if (view === "week") {
        newDate = moment(mDate, 'YYYY-MM-DD HH:mm:ss').add('day', 7);
        props.onNavigate("next", newDate);
        if(moment().add(-1, 'months') >= moment(newDate)){
          global.dtInicioPesqAgenda = newDate.format("YYYY-MM-DD");
          //console.log("dtInicioPesqAgenda",global.dtInicioPesqAgenda);
        }
      } else {
        newDate = moment(mDate, 'YYYY-MM-DD HH:mm:ss').add('day', 1);
        props.onNavigate("next", newDate);
        if(moment().add(-1, 'months') >= moment(newDate)){
          global.dtInicioPesqAgenda = newDate.format("YYYY-MM-DD");
          //console.log("dtInicioPesqAgenda",global.dtInicioPesqAgenda);
        }
      }
    };

    const goToToday = () => {
      const newDate = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
      if(global.dtInicioPesqAgenda){
        delete global.dtInicioPesqAgenda;
        ////console.log("global.dtInicioPesqAgenda",global.dtInicioPesqAgenda)
      }
      props.onNavigate("current", newDate);
    };

    const goDt = () => {
      let inputDtCurrent = document.getElementById('inputDtCurrent');
      //const newDate = moment(new Date(inputDtCurrent.value), 'YYYY-MM-DD HH:mm:ss');
      if(moment().add(-1, 'months') >= moment(inputDtCurrent.value)){
        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = '' };
        global.dtInicioPesqAgenda = inputDtCurrent.value;
        ////console.log("dtInicioPesqAgenda",global.dtInicioPesqAgenda);
      }
      props.onNavigate("current", inputDtCurrent.value);
    };

    const goToBackYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() - 1, 1);
      props.onNavigate("prev", newDate);
    };

    const goToNextYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() + 1, 1);
      props.onNavigate("next", newDate);
    };

    const month = () => {
      const date = moment(props.date);
      let month = date.format("MMMM");
      let day = date.format("dddd");
      let inputDtCurrent = document.getElementById('inputDtCurrent');
      if (inputDtCurrent) { inputDtCurrent.value = moment(date).format("YYYY-MM-DD"); }
      let result = '';

      if (viewState === "day") {
        result = day;
      } else if (viewState === "week") {
        let seg = (moment(props.date).startOf('week').weekday(0).format("DD/MM"))
        let dom = (moment(props.date).startOf('week').weekday(6).format("DD/MM"))
        let ano = (moment(props.date).startOf('week').weekday(6).format("YYYY"))
        result = seg + " à " + dom + ' de ' + ano;

      } else if (viewState === "agenda") {
        result = props.label;

      } else {
        result = '';

      }

      return (
        <>
          {/* <i className="far fa-calendar"></i>  */}<span>{`${result}`}&nbsp;&nbsp;</span>
        </>
      );
    };
    const year = () => {
      const date = moment(props.date);
      let year = date.format("YYYY");

      return (
        <span className="rbc-btn-group">
          {viewState === "month" && (
            <button type="button" onClick={goToBackYear}>
              <span className="prev-icon">&#8249;&#8249;</span>
            </button>
          )}
          <span className="rbc-toolbar-label">{year}</span>
          {viewState === "month" && (
            <button type="button" onClick={goToNextYear}>
              <span className="prev-icon">&#8250;&#8250;</span>
            </button>
          )}
        </span>
      );
    };

    const day = () => {
      let view = viewState;
      const date = moment(props.date);
      let day;
      if (view === "day") {
        day = date.format("ddd") + " " + date.format("Do");
      }
      return <span className="rbc-toolbar-label">{day}</span>;
    };

    return (
      <div className="rbc-toolbar">
        {/*         <div className="rbc-toolbar-item-1">
          <div className="flex-row"> */}
        {/*  <SwitchCheckbox checked={StudyTimechecked} onChange={setStudyTimechecked} id="study-time-checkbox" /> */}
        {/*             <p className="text-muted" style={{ margin: "0" }}>
              Study Time
            </p>
          </div>
        </div> */}

        <span className="rbc-btn-group">
          <span className="rbc-toolbar-label">
            <button className="btn-rbc-toolbar" id="btnHoje" onClick={goToToday}>
              <span className="label-filter-off">Hoje</span>
            </button>
            <button className="btn-rbc-toolbar" id="btnAnt" onClick={goToBack}>
              <span className="label-filter-off">Anterior</span>
            </button>
            <button className="btn-rbc-toolbar" id="btnProx" onClick={goToNext}>
              <span className="label-filter-off">Próximo</span>
            </button>
          </span>
        </span>
        <span className="rbc-toolbar-label rbc-date">
          {month()}
          {viewState === "day" && <input type="date" className="label-filter-off" id="inputDtCurrent" onChange={() => goDt()} placeholder="Dia" />}
        </span>
        <span className="rbc-btn-group">
          <button className="btn-rbc-toolbar btnselected" id="btnDia" onClick={goToDayView}>
            <span className="label-filter-off">Dia</span>
          </button>
          <button className="btn-rbc-toolbar" id="btnSemana" onClick={goToWeekView}>
            <span className="label-filter-off">Semana</span>
          </button>
          <button className="btn-rbc-toolbar" id="btnAgenda" onClick={goToAgendaView}>
            <span className="label-filter-off">Agenda</span>
          </button>
        </span>

      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}

export default RBCToolbar;