import React from "react";
//import moment from "moment-mini";

const EventWrapper = ({ event, children }) => {
  const { title, className, style, onClick, onDoubleClick, onKeyPress } = children.props;
  //console.log(children.props);
  const customClass = `${className} rbc-event--${event.type}`;
  //const hourStart = moment(event.start).hour();
  //const hourStop = moment(event.end).hour();
  //const gridRowStart = hourStart + 1;

  return (
    <div
      title={title}
      className={customClass}
      //style={{ gridRow: `${gridRowStart} / span ${hourStop - hourStart}` }}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onKeyPress={onKeyPress}
    >
      {children.props.children}
    </div>
  );
};

export default EventWrapper;