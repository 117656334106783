import React, { Component } from 'react'
//import publicIp from  'public-ip';

export default class Footer extends Component {
    
    render() {

        // (async () => {
        //     global.gIp = await publicIp.v4(); 
        //     console.log(global.gIp);
        //     // let p_ip = document.getElementById('p_ip');
        //     // p_ip.innerHTML = "Ip: "+global.gIp;
        //     //=> '46.5.21.123'
         
        //     //console.log(await publicIp.v6());
        //     //=> 'fe80::200:f8ff:fe21:67cf'
        // })();

        if ((global.gVersion) && (global.gNewVersion) && (global.gVersion !== global.gNewVersion)){
            let msg = "Nova Atualização disponível do sistema, versão " + global.gNewVersion + " , saia do sistema e atualize seu navegador agora!";
            window.alert(msg);
            //window.location.href = "/login";
        }

        return (
            <div>
                <footer className="main-footer">
                    Copyright ©2022 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/desenvolvimento">   You Evolution </a>.
                    Todos os direitos reservados. 
  <div className="float-right d-sm-inline-block">
                        <b>Versão</b> {global.gVersion}
  </div>
                </footer>


            </div>
        )
    }
}
